export const sideBarMenu = [
  {
    menuTitle: 'Endorsements',
    path: '/',
    icon: require('../assets/images/SideNavIcons/endoresment-logo.svg').default,
    menus: [
      { title: 'Brands', icon: '', path: '/brands' },
      { title: 'My Endorsements', icon: '', path: '/endorsements' },
    ],
  },
  {
    menuTitle: 'Network',
    path: '/network',
    icon: require('../assets/images/SideNavIcons/network-chain-icon.svg')
      .default,
    menus: [
      {
        title: 'Chain',
        path: '/chainview',
      },
      { title: 'CRM', path: '/crm' },
      {
        title: 'Legacy',
        path: '/controller',
        mainMenus: ['Main', 'Training', 'Licence', 'OTC'],
      },
    ],
  },
  {
    menuTitle: 'Compensation',
    path: '/compensation',
    icon: require('../assets/images/SideNavIcons/earn-icon.png').default,
    menus: [{ title: 'Industries', path: '/industries' }],
  },
  {
    menuTitle: 'AffiliateBank',
    path: '/affiliatebank',
    icon: require('../assets/images/SideNavIcons/affiliate-bank-icon.svg')
      .default,
    menus: [
      { title: 'Vaults', path: '/vaults' },
      { title: 'InfluenceCoin', path: '/gx-tokens' },
      { title: 'Bank', path: '/bank' },
    ],
  },
  {
    menuTitle: 'Classrooms',
    path: '/classrooms',
    icon: require('../assets/images/SideNavIcons/learn-icon.svg').default,
    menus: [
      { title: 'Library', path: '#' },
      { title: 'Campus', path: '#' },
    ],
  },
  {
    menuTitle: 'Influence Chat',
    icon: require('../assets/images/SideNavIcons/influence-icon.svg').default,
    isChatAction: true,
    menus: [],
  },
];

export const GX_API_ENDPOINT = 'https://comms.globalxchange.io';

export const GX_AUTH_URL = 'https://gxauth.apimachine.com';

export const APP_CODE = 'broker_app';
export const EMPOWERED_APP_CODE = 'EmpoweredApp';
export const AFFILIATE_BANK_APP_CODE = 'snappay';
export const ENGAGEMENT_APP_CODE = 'farm';

export const searchSuggestionListCRM = [
  { title: 'Total Users', count: 200 },
  { title: 'Direct Users', count: 200 },
  { title: 'Indirect Users', count: 200 },
  { title: 'Total Customers', count: 3021 },
  { title: 'Direct Customers', count: 3021 },
  { title: 'Indirect Customers', count: 3021 },
  { title: 'Total Brokers', count: 3021 },
  { title: 'Direct Brokers', count: 3021 },
  { title: 'Indirect Brokers', count: 3021 },
  { title: 'Total Token Transactional Volume', count: 3021 },
  { title: 'Direct Token Transactional Revenue', count: 3021 },
  { title: 'Indirect Token Transactional Revenue', count: 3021 },
  { title: 'Broker Dealer Transactional Revenue', count: 3021 },
  { title: 'Total OTC Transactional Volume', count: 3021 },
  { title: 'Total OTC Transactional Revenue', count: 3021 },
  { title: 'Direct OTC Transactional Revenue', count: 3021 },
  { title: 'Indirect OTC Transactional Revenue', count: 3021 },
  { title: 'Brokerage Money Market Earnings', count: 3021 },
  { title: 'Total Digital Transactional Revenue', count: 3021 },
  { title: 'Direct Digital Transactional Volume', count: 3021 },
  { title: 'Indirect Digital Transactional Volume', count: 3021 },
  { title: 'Broker Dealer Revenue', count: 3021 },
  { title: 'Withdrawal Ledger', count: 3021 },
  {
    title: 'Total OTC Transactional Volume-InstaCryptoPurchase.com',
    count: 3021,
  },
  {
    title: 'Total OTC Transactional Revenue-InstaCryptoPurchase.com',
    count: 3021,
  },
  {
    title: 'Direct OTC Transactional Revenue-InstaCryptoPurchase.com',
    count: 3021,
  },
  {
    title: 'Indirect OTC Transactional Revenue-InstaCryptoPurchase.com',
    count: 3021,
  },
];

export const BOTS_MENU = [
  {
    title: 'Transactions',
    header: 'What Is The Type Of Transaction',
    subMenu: [
      {
        title: 'Add',
        icon: require('../assets/images/support-category-icons/add.png')
          .default,
      },
      {
        title: 'Send',
        icon: require('../assets/images/support-category-icons/send.png')
          .default,
      },
      {
        title: 'Trade',
        icon: require('../assets/images/support-category-icons/trade.png')
          .default,
      },
      {
        title: 'Invest',
        icon: require('../assets/images/support-category-icons/invest.png')
          .default,
      },
    ],
  },
  {
    title: 'Actions',
    subMenu: [
      {
        title: 'Add',
        icon: require('../assets/images/support-category-icons/add.png')
          .default,
      },
      {
        title: 'Send',
        icon: require('../assets/images/support-category-icons/send.png')
          .default,
      },
      {
        title: 'Fiat Funding',
        icon: require('../assets/images/support-category-icons/trade.png')
          .default,
      },
      {
        title: 'Invest',
        icon: require('../assets/images/support-category-icons/invest.png')
          .default,
      },
    ],
  },
  {
    title: 'Learn',
    header: 'What Do You Want To Learn About?',
    subMenu: [],
  },
];

export const AGENCY_API_URL = 'https://fxagency.apimachine.io';

export const TELLERS_API_URL = 'https://teller2.apimachine.io';

export const MAILSURP_KEY =
  '86cee2f39d56b3b5a6b2e4c827cc1382d1be6bad16a9d35cd0e659ef9272d02c';

export const S3_CONFIG = {
  accessKeyId: Buffer.from('QUtJQVFENFFZWFdGV1dTVEdFNjQ=', 'base64').toString(),
  secretAccessKey: Buffer.from(
    'WElmckJsTG42OG1Dc2MxV3pMaVZrTnU2dkRuR1hSOW5UM2xCZExHZw==',
    'base64',
  ).toString(),
};

export const NEW_CHAT_API = 'https://testchatsioapi.globalxchange.io';
export const NEW_CHAT_SOCKET = 'https://testsockchatsio.globalxchange.io';

export const APPS_LIST = [
  // {
  //   appName: 'Affiliate',
  //   headerText: 'For Anyone Who Wants To Make Money',
  //   desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
  //   path: '/affiliate',
  //   appPath: '/app/affiliate',
  //   loginPath: '/apps/affiliate/login',
  //   registerPath: '/apps/affiliate/registration',
  //   logo: require('../assets/images/affliate-app-dark-full-logo.svg').default,
  //   icon: require('../assets/images/affliate-app-dark-logo.svg').default,
  //   cover: require('../assets/images/AppsCovers/1.jpg').default,
  // },
  {
    appName: 'Engagement',
    headerText: 'For Brands That Want Affiliates',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/engagement',
    appPath: '/app/engagement',
    loginPath: '/apps/engagement/login',
    registerPath: '/apps/engagement/registration',
    logo: require('../assets/images/engagement-app-logo.svg').default,
    icon: require('../assets/images/engagment-app-icon.svg').default,
    cover: require('../assets/images/AppsCovers/2.jpg').default,
  },
  {
    appName: 'Legacy MLM',
    headerText: 'For Existing Network-Marketing Companies',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/mlmexchange',
    appPath: '/app/mlmexchange',
    loginPath: '/apps/mlmexchange/login',
    registerPath: '/apps/mlmexchange/registration',
    logo: require('../assets/images/mlm-exchange-app-full.svg').default,
    icon: require('../assets/images/mlm-exchange-small-icon.svg').default,
    cover: require('../assets/images/AppsCovers/3.jpg').default,
  },
  {
    appName: 'CryptoInfluencers',
    headerText: 'For Inlfuencers In The Crypto Industry',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/cryptoinfluencers',
    appPath: '/app/cryptoinfluencers',
    loginPath: '/apps/cryptoinfluencers/login',
    registerPath: '/apps/cryptoinfluencers/registration',
    logo: require('../assets/images/crypto-influencers-full-logo.svg').default,
    icon: require('../assets/images/crypto-influncers-small-icon.svg').default,
    cover: require('../assets/images/AppsCovers/4.jpg').default,
  },
  {
    appName: 'Oppurtunities',
    headerText: 'For Anyone Looking For Opportunities',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/opportunities',
    appPath: '/app/opportunities',
    loginPath: '/apps/opportunities/login',
    registerPath: '/apps/opportunities/registration',
    logo: require('../assets/images/opertunities-app.svg').default,
    icon: require('../assets/images/opertunities-small-icon.svg').default,
    cover: require('../assets/images/AppsCovers/5.jpg').default,
  },
  {
    appName: 'Recruiting',
    headerText: 'For Brands To Looking To Grow',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/recruiting',
    appPath: '/app/recruiting',
    loginPath: '/apps/recruiting/login',
    registerPath: '/apps/recruiting/registration',
    logo: require('../assets/images/recruting-app-full-logo.svg').default,
    icon: require('../assets/images/recruting-small-icon.svg').default,
    cover: require('../assets/images/AppsCovers/6.jpg').default,
    iconSize: { height: 80 },
  },
  {
    appName: 'CompPlans',
    headerText: 'Design & Scale Compensation Packages',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/compplans',
    appPath: '/app/compplans',
    loginPath: '/apps/compplans/login',
    registerPath: '/apps/compplans/registration',
    logo: require('../assets/images/comp-plan-full-logo.svg').default,
    icon: require('../assets/images/comp-plan-small-icon.svg').default,
    cover: require('../assets/images/AppsCovers/7.jpg').default,
    iconSize: { height: 80 },
  },
  {
    appName: 'CryptoGiveaway',
    headerText: 'For People Looking To Earn Free Crypto',
    desc: 'A cryptocurrency (or crypto currency) is a digital asset designed to work as a medium of exchange wherein individual coin ownership records are stored in a ledger existing in a form of computerized database using strong cryptography to secure transaction records',
    path: '/cryptogiveaway',
    appPath: '/app/cryptogiveaway',
    loginPath: '/apps/cryptogiveaway/login',
    registerPath: '/apps/cryptogiveaway/registration',
    logo: require('../assets/images/crypto-giveaway-full-logo.svg').default,
    icon: require('../assets/images/crypto-giveaway-small-icon.svg').default,
    cover: require('../assets/images/AppsCovers/8.jpg').default,
    iconSize: { height: 80 },
  },
];

export const TELLER_API2_ENDPOINT = 'https://teller2.apimachine.io';

export const BRAIN_SECRET = 'uyrw7826^&(896GYUFWE&*#GBjkbuaf';
export const EMAIL_DEV = 'muhammad@nvestbank.com';
export const BRAIN_API_ENDPOINT = 'https://drivetest.globalxchange.io';
export const BRAIN_PATH = 'root/AffiliateApp/';
