import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FullPageContainer, FullPagePanel } from './FullPageReact';

const AppsCarousel = ({ setActivePos, activePos }) => {
  const [dotColor, setDotColor] = useState('');

  useEffect(() => {
    setDotColor(APP_LIST[activePos - 1].textColor);
  }, [activePos]);

  return (
    <div className="app-carousel">
      <FullPageContainer
        showIndicators
        activePos={activePos}
        dotColor={dotColor}
        onPanelChange={(panelNumber) => setActivePos(panelNumber)}
      >
        {APP_LIST.map((item, index) => (
          <FullPagePanel key={index} bgColor={item.bgColor}>
            <div className="page-item">
              <img src={item.icon} alt="" className="app-logo" />
              <div className="actions-container">
                <Link
                  to={item.loginPath || '#'}
                  className="login-button"
                  style={{ color: item.textColor, borderColor: item.textColor }}
                >
                  Login
                </Link>
                <div
                  className="divider"
                  style={{ backgroundColor: item.textColor }}
                />
                <Link
                  to={item.registerPath || '#'}
                  className="register-button"
                  style={{
                    backgroundColor: item.textColor,
                    color: item.bgColor,
                  }}
                >
                  Register
                </Link>
              </div>
            </div>
          </FullPagePanel>
        ))}
      </FullPageContainer>
    </div>
  );
};

export default AppsCarousel;

const APP_LIST = [
  {
    bgColor: '#08152d',
    icon: require('../assets/images/all-apps-category/affliate-app.svg')
      .default,
    textColor: 'white',
    appName: 'Affiliate App',
    loginPath: '/app/affiliate/login',
    registerPath: '/app/affiliate/registration',
  },
  {
    bgColor: 'white',
    icon: require('../assets/images/all-apps-category/engagement-app.svg')
      .default,
    textColor: '#08152d',
    appName: 'Engagement App',
    loginPath: '/app/engagement/login',
    registerPath: '/app/engagement/registration',
  },
  {
    bgColor: '#08152d',
    icon: require('../assets/images/all-apps-category/affliate-bank.svg')
      .default,
    textColor: 'white',
    appName: 'Affiliate Bank',
    loginPath: '/app/affiliatebank/login',
    registerPath: '/app/affiliatebank/registration',
  },
  {
    bgColor: 'white',
    icon: require('../assets/images/all-apps-category/empowerment-app.svg')
      .default,
    textColor: '#08152d',
    appName: 'Empowerment App',
    loginPath: '/app/empowered/login',
    registerPath: '/app/empowered/registration',
  },
];
