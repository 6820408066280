
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { APP_CODE, GX_API_ENDPOINT } from '../configs';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
//mobile view

const getAppData = async () => {
  const resp = await axios.get(`${GX_API_ENDPOINT}/gxb/apps/get`, {
    params: { app_code: APP_CODE },
  });

  const appData = resp.data?.apps ? resp.data?.apps[0] : '';

  return appData;
};
export const useAppData = () => {
  const appData = useQuery('appData', getAppData);

  return appData;
};

const getExchangePairs = async ({ queryKey }) => {
  const [_key, { email, type }] = queryKey;
  const { data } = await axios.get(
    `${GX_API_ENDPOINT}/coin/trade/user/fees/get`,
    {
      params: { email, get_fees_type: type },
    },
  );
  return data?.data?.[0]?.trade_fees;
};

export const useExchangePairs = (email, type) =>
  useQuery(['exchangePairs', { email, type }], getExchangePairs);

const getMoneyMarketList = async ({ queryKey }) => {
  const [_key, { email, type }] = queryKey;
  const { data } = await axios.get(
    `${GX_API_ENDPOINT}/coin/iced/user/get/interest/fees`,
    {
      params: { email, type: type },
    },
  );
  return data?.feesData?.fees;
};

export const useMoneyMarketList = (email, type) =>
  useQuery(['moneyMarketPairs', { email, type }], getMoneyMarketList);
