import { useState } from 'react';
import FilterButton from './FilterButton';

const ListFilter = () => {
  const [openedMenu, setOpenedMenu] = useState('');
  const [filterSettings, setFilterSettings] = useState({});

  const onFilterSelect = (filter, key) => {
    setFilterSettings((prevSettings) => {
      const newSettings = { ...prevSettings };
      newSettings[key] = filter;

      return newSettings;
    });
    setOpenedMenu('');
  };

  return (
    <div className="filter-container">
      <div className="filters-list">
        {FILTERS.map((item) => (
          <FilterButton
            key={item.title}
            title={item.title}
            onOpen={() =>
              setOpenedMenu(openedMenu === item.title ? '' : item.title)
            }
            filters={item.filters}
            isOpen={openedMenu === item.title}
            onFilterSelect={(selectedFilter) =>
              onFilterSelect(selectedFilter, item.key)
            }
            activeFilter={filterSettings[item.key]}
          />
        ))}
      </div>
      <div className="tags-container">
        <input type="text" className="tags-input" placeholder="Enter Tag..." />
      </div>
    </div>
  );
};

export default ListFilter;

const FILTERS = [
  {
    key: 'directional',
    title: 'Add Directional Filter',
    filters: [
      {
        title: 'Only Credits',
        icon: require('../../assets/images/filterIcons/only-credits.svg')
          .default,
      },
      {
        title: 'Only Debits',
        icon: require('../../assets/images/filterIcons/only-debits.svg')
          .default,
      },
    ],
  },
  { title: 'Add Categorical Filter' },
  { title: 'Add Date Filter' },
  { title: 'Add Vault Filter' },
];
