import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const MobileAppsPage = () => {
  return (
    <div className="mobile-apps-page">
      <Slider dots>
        {APP_LIST.map((item) => (
          <div className="page-item">
            <Link to="/" className="close-btn">
              <img
                src={
                  item.bgColor === 'white'
                    ? require('../assets/images/times-colored-icon.svg').default
                    : require('../assets/images/times-icon.svg').default
                }
                alt=""
                className="close-icon"
              />
            </Link>
            <div
              className="d-flex h-100 flex-column flex-fill justify-content-center align-items-center"
              style={{ backgroundColor: item.bgColor }}
            >
              <img src={item.icon} alt="" className="app-logo" />
              <div className="actions-container">
                <Link
                  to={item.learnMorePath || '#'}
                  className="login-button"
                  style={{ color: item.textColor, borderColor: item.textColor }}
                >
                  Learn More
                </Link>
                <div
                  className="divider"
                  style={{ backgroundColor: item.textColor }}
                />
                <Link
                  to={item.installerPath || '#'}
                  className="register-button"
                  style={{
                    backgroundColor: item.textColor,
                    color: item.bgColor,
                  }}
                >
                  {item.installerPath ? 'Download Apps' : 'Get Started'}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MobileAppsPage;

const APP_LIST = [
  {
    bgColor: '#08152d',
    icon: require('../assets/images/all-apps-category/affliate-app.svg')
      .default,
    textColor: 'white',
    appName: 'AffiliateApp',
    learnMorePath: '#',
    installerPath: '/app-installer',
  },
  {
    bgColor: 'white',
    icon: require('../assets/images/all-apps-category/engagement-app.svg')
      .default,
    textColor: '#08152d',
    appName: 'Engagement App',
    learnMorePath: '#',
  },
  {
    bgColor: '#08152d',
    icon: require('../assets/images/all-apps-category/affliate-bank.svg')
      .default,
    textColor: 'white',
    appName: 'Affiliate Bank',
    learnMorePath: '#',
  },
  {
    bgColor: 'white',
    icon: require('../assets/images/all-apps-category/empowerment-app.svg')
      .default,
    textColor: '#08152d',
    appName: 'Empowerment App',
    learnMorePath: '#',
  },
];
