import axios from 'axios';

export const GetInformationofcards = (e) => {
  try {
    let fetchdata = axios.get(
      `https://comms.globalxchange.io/brokerage/stats/getUsers?affiliate_id=${e}`
    );
    return fetchdata;
  } catch (error) {
    return error.message;
  }
};



export const GetInformationofSider = (obj) => {
  try {
    let fetchdata = axios.get(
      `https://comms.globalxchange.io/brokerage/stats/get/uplines?email=${obj}`
    );
    return fetchdata;
  } catch (error) {
    return error.message;
  } 
};


export const GetInformationofDownline = (obj) => {
  try {
    let fetchdata = axios.get(
      `https://comms.globalxchange.io/brokerage/otc/broker/team/stats?email=${obj}&sort=txnsexport`
    );
    return fetchdata;
  } catch (error) {
    return error.message;
  } 
};


export const GetInformationofBrands = (obj) => {
  try {
    let fetchdata = axios.get(
      `https://teller2.apimachine.com/banker/followingList?email=${obj}`
    );
    return fetchdata;
  } catch (error) {
    return error.message;
  } 
};


