/* eslint-disable no-extend-native */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import FullLoadingComponent from '../components/FullLoadingComponent';
import ProfileAvatar from '../components/ProfileAvatar';
import { APP_CODE, GX_API_ENDPOINT } from '../configs';
import PopupModalLayout from '../layouts/PopupModalLayout';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import Banner from '../components/Banner'
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const ProfilePage = () => {
  const { userName, tabName } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [userData, setUserData] = useState();
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState();
  const [activeSearchFilter, setActiveSearchFilter] = useState(
    FILTER_OPTION[0],
  );
  const [isSearchFilterPopupOpen, setIsSearchFilterPopupOpen] = useState(false);

  const STATS = [
    {
      title: 'Contacts',
      header: 'Contacts',
      count: userData?.downlinesCount,
      subTitle: `Explore Who Is In ${userData?.userData?.name?.capitalize()}’s NetworkChain™`,
      placeholder: `Search Through ${
        userData?.userData?.name?.capitalize() || ''
      }'s Contacts...`,
      list: userData?.downlines,
      actionIcon: require('../assets/images/networkchains-logo.svg').default,
      actionLink: `https://networkchains.com/users/${userData?.userData?.email}`,
    },
    {
      title: 'Endorsed Brands',
      header: 'Endorsements',
      subTitle: `Explore The Brands That ${userData?.userData?.name?.capitalize()} Is Endorsing`,
      placeholder: `Search ${userData?.userData?.name || ''}'s Endorsements...`,
      count: userData?.followingCount,
      list: userData?.followingData,
      actionIcon: require('../assets/images/endorsment-logo.svg').default,
      actionLink: `https://endorsement.app/affiliate/${userData?.userData?.username}`,
    },
    {
      title: 'Opportunities',
      header: 'Opportunities',
      subTitle: `Explore The Opportunities ${userData?.userData?.name?.capitalize()} Is Apart Of`,
      placeholder: `Search Through ${
        userData?.userData?.name || ''
      }'s Opportunities...`,
      count: 0,
      actionIcon: require('../assets/images/opertunities-app.svg').default,
      actionLink: `https://oppotunities.app/affiliates/${userData?.userData?.email}`,
    },
    {
      title: 'Introductions',
      header: 'Introductions',
      subTitle: `Explore Who ${userData?.userData?.name?.capitalize()} Has Made Introductions For`,
      placeholder: `Search Through ${
        userData?.userData?.name || ''
      }'s Introductions...`,
      count: 0,
      actionIcon: require('../assets/images/introduction-logo.svg').default,
      actionLink: `https://introductions.app/affiliate/${userData?.userData?.email}`,
    },
  ];
  const [activeTab, setActiveTab] = useState(STATS[0]);

  useEffect(() => {
    if (location.state?.selectedUser) {
      setUserData({ ...location.state?.selectedUser, downlines: null });
    }

    const email = userData?.userData?.email || LocalStorageHelper.getAppEmail();

    let params = {
      // app_code: APP_CODE,
      downlines_count: true,
      downlines_data: true,
    };
    if (userName) {
      params = { ...params, username: userName };
    } else {
      params = { ...params, email };
    }

    axios
      .get(`${GX_API_ENDPOINT}/coin/vault/service/users/holdings/data/get`, {
        params,
      })
      .then(({ data }) => {
        // console.log('Profile Data', data);

        const users = data?.users || [];

        setUserData(users[0] || '');
      })

      .catch((error) => {
        console.log('getUserDetails Error', error);
      });
  }, [userName, location]);

  useEffect(() => {
    if (userData) {
      const searchQuery = searchText.trim().toLowerCase();

      const list = activeTab.list?.filter(
        (x) =>
          (x[activeSearchFilter.key] || '')
            .toLowerCase()
            .includes(searchQuery) ||
          x?.bankerDetails?.displayName?.toLowerCase().includes(searchQuery),
      );
      setFilteredList(list);
    }
  }, [searchText, userData, activeTab]);

  useEffect(() => {
    setSearchText('');
  }, [activeTab]);

  useEffect(() => {
    if (tabName) {
      const tab = STATS.find(
        (x) => x.header.toLowerCase() === tabName.toLowerCase(),
      );
      setActiveTab(tab || STATS[0]);
    } else {
      setActiveTab(STATS[0]);
    }
  }, [userData, tabName]);

  const onItemClick = (item) => {
    if (activeTab.title === 'Contacts') {
      setUserData();
      axios
        .get(`${GX_API_ENDPOINT}/coin/vault/service/users/holdings/data/get`, {
          params: { email: item.email, downlines_count: true },
        })
        .then(({ data }) => {
          let user = data?.users ? data?.users[0] : null;

          if (user) {
            user = {
              ...user,
              name: user.userData?.name,
              profile_img: user.userData?.profile_img,
              email: user.userData?.email,
            };
            // console.log('Clicked user Data', user);

            history.push('/', { isOpenUser: true, userToOpen: user });
          }
        })

        .catch((error) => {
          console.log('getUserDetails Error', error);
        });
    }
  };

  const onTabItemClick = (item) => {
    // setActiveTab(item);
    history.replace(
      `/brokerid/${userName || userData?.userData?.username}${
        item ? `/${item.header.toLowerCase()}` : ''
      }`,
      {
        selectedUser: userData,
      },
    );
  };

  return (
    <div className="landing-search-layout">
      {userData ? null : <FullLoadingComponent />}
{/* <Banner/> */}
      <div className="search-layout-header">
        <Link className="app-logo-container" to="/">
          <img
            src={
              require('../assets/images/affliate-app-dark-full-logo.svg')
                .default
            }
            alt=""
            className="app-img"
          />
        </Link>
        <Link
          to={{ pathname: '/', state: { openEdit: true } }}
          className="search-input-btn"
        >
          Search Affiliates
        </Link>
        <Link
          className="selected-option"
          to={{ pathname: '/', state: { openOptions: true } }}
          onClick={() => {}}
        >
          <img
            src={require('../assets/images/affliate-app-dark-logo.svg').default}
            alt=""
            className="option-img"
          />
        </Link>
      </div>
      <div className="user-selected-view-wrapper">
        <div className="profile-user-details-container">
          {userData ? (
            <div className="user-item">
              <ProfileAvatar
                avatar={userData?.userData?.profile_img}
                name={userData?.userData?.name || ''}
                size={100}
                className="user-img"
              />
              <div className="user-details">
                <div className="user-name">{userData?.userData?.name}</div>
                <div className="user-email">{userData?.userData?.email}</div>
              </div>
            </div>
          ) : (
            <div className="user-item">
              <Skeleton width={100} height={100} circle />
              <div className="user-details">
                <Skeleton className="user-name" width={200} height={40} />
                <Skeleton className="user-email" width={300} height={10} />
              </div>
            </div>
          )}
        </div>
        <div className="user-stats-container">
          {STATS.map((item) => (
            <div
              key={item.title}
              className={`stats-item ${
                activeTab.title === item.title ? 'active' : 'not-active'
              } `}
              onClick={() => onTabItemClick(item)}
            >
              {item.count === undefined ? (
                <Skeleton className="stats-value" width={80} height={40} />
              ) : (
                <div className="stats-value">{item.count}</div>
              )}
              <div className="stats-label">{item.title}</div>
            </div>
          ))}
        </div>
        <div className="user-stats-details-wrapper">
          <div className="user-stats-details">
            {userData ? (
              <>
                <div className="details-title">
                  <span>{userData?.userData?.name}</span>’s{' '}
                  {activeTab.header || activeTab.title}
                </div>
                <div className="details-sub-title">{activeTab.subTitle}</div>
              </>
            ) : (
              <>
                <Skeleton
                  className="details-title mb-2"
                  width={300}
                  height={35}
                />
                <Skeleton
                  className="details-sub-title"
                  width={270}
                  height={15}
                />
              </>
            )}
            <div className="search-container">
              <input
                type="text"
                placeholder={activeTab.placeholder}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {activeTab.title === 'Contacts' && (
                <div
                  className="selected-option"
                  onClick={() => setIsSearchFilterPopupOpen(true)}
                >
                  <img
                    src={activeSearchFilter.icon}
                    alt=""
                    className="option-img"
                  />
                </div>
              )}
            </div>
            <div className="list-container row">
              {filteredList ? (
                filteredList.length > 0 ? (
                  filteredList.map((item, index) => (
                    <div
                      key={item.username || item.bankerDetails?.displayName}
                      className="list-item"
                      onClick={() => onItemClick(item)}
                    >
                      <ProfileAvatar
                        avatar={
                          item.profile_img || item.bankerDetails?.profilePicURL
                        }
                        name={
                          item.username || item.bankerDetails?.displayName || ''
                        }
                        size={95}
                      />
                      <div className="user-name">
                        {item.username || item.bankerDetails?.displayName}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty-message">
                    No {activeTab.header || activeTab.title} Found
                  </div>
                )
              ) : (
                Array(18)
                  .fill(1)
                  .map((_, index) => (
                    <div key={index} className="list-item">
                      <Skeleton width={95} height={95} circle />
                      <Skeleton className="user-name" width={60} height={10} />
                    </div>
                  ))
              )}
            </div>
          </div>
          {activeTab.actionLink && (
            <a
              href={activeTab.actionLink}
              target="_blank"
              rel="noopener noreferrer"
              className="action-button"
            >
              <img src={activeTab.actionIcon} alt="" className="action-icon" />
            </a>
          )}
        </div>
      </div>
      <PopupModalLayout
        isOpen={isSearchFilterPopupOpen}
        onClose={() => setIsSearchFilterPopupOpen(false)}
        headerText={`Search ${userData?.userData?.name || ''}'s ${
          activeTab.title
        } By`}
        width={800}
      >
        <div className="search-options-wrapper">
          {FILTER_OPTION.map((item) => (
            <div
              key={item.title}
              className={`option-item ${
                activeSearchFilter?.title === item.title ? 'active' : ''
              }`}
              onClick={() => {
                setActiveSearchFilter(item);
                setIsSearchFilterPopupOpen(false);
              }}
            >
              <div className="bran-img-container">
                <img src={item.icon} alt="" className="option-image" />
              </div>
              <div className="option-name">{item.title}</div>
            </div>
          ))}
        </div>
      </PopupModalLayout>
    </div>
  );
};

export default ProfilePage;

const FILTER_OPTION = [
  {
    title: 'Email',
    icon: require('../assets/images/gmail-icon.svg').default,
    key: 'email',
  },
  {
    title: 'Username',
    icon: require('../assets/images/filter-by-username-icon.svg').default,
    key: 'username',
  },
  {
    title: 'Name',
    icon: require('../assets/images/filter-by-name.svg').default,
    key: 'username',
  },
];
