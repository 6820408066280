import { ScrollView } from '@cantonjs/react-scroll-view';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import LoadingAnimation from '../../components/LoadingAnimation';

const width = window.innerWidth;

const SLIDER_WIDTH = width;
const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 0.5);
const ITEM_HEIGHT = Math.round((ITEM_WIDTH * 4) / 6);

export default function BrokerEarningCarousel({ close, carouselData }) {
  const [current, setCurrent] = useState(0);
  const length = carouselData.length;
  const handlePrevious = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  const handleNextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  return (
    <div
      className="minimizecarousel"
      style={{
        height: close ? 0 : 180,
        width: '100%',
        paddingTop: close ? 0 : 20,
      }}
    >
      {carouselData ? (
        <>
          {carouselData.map((item, key) => {
            return (
              <div
                className={key === current ? 'carousel_item_container ' : ''}
                key={key}
                style={{
                  minWidth: key === current ? '100%' : '0',
                  height: key === current ? '100%' : 0,
                  opacity: 1,
                  position: 'relative',
                  display: close ? 'none' : 'flex',
                }}
              >
                {key === current && (
                  <>
                    <div
                      onClick={() => {
                        handlePrevious();
                      }}
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: '38%',
                        width: 40,
                        height: 40,

                        color: 'white',
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 25,
                      }}
                    >
                      {'<'}
                    </div>
                    <div className="carousel_item_value">{item.value}</div>
                    <div className="carousel_item_title">{item.title}</div>
                    <div
                      onClick={() => {
                        handleNextSlide();
                      }}
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: '38%',
                        width: 40,
                        height: 40,

                        color: 'white',
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 25,
                      }}
                    >
                      {'>'}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingAnimation dark />
        </div>
      )}
    </div>
  );
}
