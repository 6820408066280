import React, { useContext, useEffect, useState } from 'react';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';
import HashForm from './HashForm';
import VaultDeposit from './VaultDepost';

const ActionAdd = () => {
  const { walletCoinData } = useContext(BrokerAppContext);

  const [ethCoins, setEthCoins] = useState();

  const [assetType, setAssetType] = useState();
  const [sourceType, setSourceType] = useState();
  const [selectedCoin, setSelectedCoin] = useState();
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [activeView, setActiveView] = useState();

  useEffect(() => {
    setSourceType();
  }, [assetType]);

  useEffect(() => {
    setSelectedCoin();
  }, [sourceType]);

  useEffect(() => {
    if (selectedCoin) {
      switch (sourceType) {
        case 'External':
          setActiveView(<HashForm selectedCoin={selectedCoin || ''} />);
          break;
        case 'GX App':
          setIsDepositModalOpen(true);
          break;
        default:
      }
    }
  }, [sourceType, selectedCoin]);

  useEffect(() => {
    if (walletCoinData) {
      const coins = walletCoinData.filter(
        (x) =>
          Object.prototype.hasOwnProperty.call(x, 'erc20Address') ||
          x.coinSymbol === 'ETH',
      );

      // console.log('coins', coins);
      setEthCoins(coins);
    }
  }, [walletCoinData]);

  if (activeView) {
    return activeView;
  }

  return (
    <div className="action-add-wrapper">
      <div className="choice-group">
        <div className="choice-header">
          What Type Of Asset Do You Want To Add?
        </div>
        <div className="choice-list">
          {ASSET_TYPES.map((item) => (
            <div
              onClick={item.disabled ? null : () => setAssetType(item.title)}
              key={item.title}
              className={`choice-item ${item.disabled ? 'disabled' : ''} ${
                assetType === item.title ? 'active' : ''
              }`}
            >
              <img src={item.icon} alt="" className="choice-icon" />
              <div className="choice-name">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      {assetType ? (
        <div className="choice-group">
          <div className="choice-header">
            Where Are You Sending The Crypto From?
          </div>
          <div className="choice-list">
            {SOURCE_TYPES.map((item) => (
              <div
                onClick={item.disabled ? null : () => setSourceType(item.title)}
                key={item.title}
                className={`choice-item ${
                  item.disabled || (assetType === 'Fiat' && item.fiatDisabled)
                    ? 'disabled'
                    : ''
                } ${sourceType === item.title ? 'active' : ''}`}
              >
                <img src={item.icon} alt="" className="choice-icon" />
                <div className="choice-name">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {sourceType ? (
        <div className="choice-group">
          <div className="choice-header">
            Where Are You Sending The Crypto From?
          </div>
          <div className="choice-list">
            {ethCoins?.map((item) => (
              <div
                onClick={item.disabled ? null : () => setSelectedCoin(item)}
                key={item.title}
                className={`choice-item ${item.disabled ? 'disabled' : ''} ${
                  selectedCoin?.coinName === item.coinName ? 'active' : ''
                }`}
              >
                <img src={item.coinImage} alt="" className="choice-icon" />
                <div className="choice-name">
                  {item.coinName?.length < 10 ? item.coinName : item.coinSymbol}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <VaultDeposit
        openModal={isDepositModalOpen}
        setOpenModal={setIsDepositModalOpen}
        isDeposit
        selectedCoin={selectedCoin || ''}
        coinSymbol={selectedCoin?.coinSymbol || ''}
      />
    </div>
  );
};

export default ActionAdd;

const ASSET_TYPES = [
  {
    title: 'Crypto',
    icon: require('../../../assets/images/crypto-asset-icon.svg').default,
  },
  {
    title: 'Fiat',
    icon: require('../../../assets/images/fiat-asset-icon.svg').default,
  },
];

const SOURCE_TYPES = [
  {
    title: 'External',
    icon: require('../../../assets/images/support-category-icons/add.png')
      .default,
    fiatDisabled: true,
  },
  {
    title: 'GX App',
    icon: require('../../../assets/images/support-category-icons/send.png')
      .default,
  },
  {
    title: 'MoneyMarket',
    icon: require('../../../assets/images/support-category-icons/trade.png')
      .default,
    disabled: true,
  },
  {
    title: 'Bonds',
    icon: require('../../../assets/images/support-category-icons/invest.png')
      .default,
    disabled: true,
  },
];
