import React from 'react';
import Modal from 'react-modal';

const PopupModalLayout = ({
  isOpen,
  onClose,
  children,
  headerText,
  noHeader,
  width = 400,
  style = {},
}) => (
  <Modal isOpen={isOpen} onRequestClose={onClose} style={modelStyle}>
    <div className="modal-popup-container" style={{ width }}>
      {noHeader || <div className="header-container">{headerText || ''}</div>}
      <div className="modal-content" style={style}>
        {children}
      </div>
    </div>
  </Modal>
);

export default PopupModalLayout;

const modelStyle = {
  overlay: {
    zIndex: '9999999999',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'none',
    overflow: 'auto',
    borderRadius: 'none',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '80%',
    paddingTop: '5rem',
    paddingBottom: '5rem',
    paddingLeft: '0',
    paddingRight: '0',
  },
};
