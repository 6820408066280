// import React, { useState, useEffect, useContext } from 'react';
// import { Navbar, Nav, Container, Button } from 'react-bootstrap';
// import logo from '../../assets/images/logo-desk.svg';
// import mobo from '../../assets/images/logo-desk.svg';
// import 'bootstrap/dist/css/bootstrap.min.css';
// // import closemark from "../Image/closemark.png";
// import './Navbar.scss';

// import { Switch, useHistory, Redirect, useLocation } from 'react-router-dom';

// // import { MainContext } from "../Component/Context"
// export default function NavSection({ active }) {
//   const histroy = useHistory();
//   const location = useLocation();
//   console.log('czxczxcxzc', location);
//   //   const { setopencard, setpagemask, settherightcard, setclosedisp, setnavName, setopenmodel } = useContext(MainContext)
//   console.log('zxczxcxzc', active);
//   const [info, setinfo] = useState('About');
//   const history = useHistory();
//   const [expanded, setExpanded] = useState(false);
//   const [navExpanded, satnavExpanded] = useState(false);
//   const handleFunction = () => {
//     history.push('/Events/Cities');
//   };
//   const handleFunction1 = () => {
//     setExpanded(!expanded);
//    // history.push('/Events/Cities');
//   };

//   const hanldeRoute = () => {
//     // setnavName("Portfolio")
//     // setopenmodel(true)
//   };
//   const Contact = () => {
//     // setnavName("Contact")
//     // setopenmodel(true)
//   };

//   const holding = () => {
//     history.push('/holdings/all');
//   };

//   const hanldeRoutePad = () => {
//     // setnavName("Launch")
//     setopenmodel(true);
//   };
//   const meta = [
//     {
//       name: 'About',
//     },
//     {
//       name: 'Brands',
//     },
//     {
//       name: 'Products',
//     },
//     {
//       name: 'Explorer',
//     },
//     {
//       name: 'Login',
//     },
//   ];

//   const data = [
//     {
//       name: 'About',
//     },
//     {
//       name: 'Portfolio',
//     },
//     {
//       name: 'Services',
//     },
//     {
//       name: 'Incubation',
//     },
//     {
//       name: 'IMVerse',
//     },
//   ];

//   const [store, setstore] = useState([]);

//   useEffect(() => {
//     setmedata('');

//     setstore(meta);

//     return () => {};
//   }, [info]);

//   const handler = async (e) => {
//     if (e === 'Launch Pad' || e === 'Portfolio') {
//     } else {
//       setExpanded(!expanded);
//       if (e === 'About') {
//         await setinfo(e);

//         history.push(`/about`);
//       }

//       if (e === 'Contact') {
//         await setinfo(e);

//         history.push('/contact');
//       }

//       if (e === 'Holdings') {
//         await setinfo(e);
//         history.push('/holdings/all');
//       }
//       if (e === 'Portfolio') {
//         await setinfo(e);
//       }
//       if (e === 'Launch Pad') {
//       }
//       await setinfo(e);
//     }
//   };
//   console.log('asdazzxczxc', info);
//   const handler1 = async (e) => {
//     active = e;
//     if (e === 'About') {
//       await setinfo(e);
//       history.push(`/about`);
//     }
//     if (e === 'Portfolio') {
//       await setinfo(e);
//       history.push(`/investors/portfolio/sharetokens`);
//     }
//     if (e === 'Starters') {
//       await setinfo(e);
//       history.push(`/incubation`);
//     }
//   };

//   const aboutpage = (e) => {
//     history.push(`/about`);
//   };

//   const starterpage = (e) => {
//     history.push('/incubation');
//   };

//   const core = (e) => {
//     history.push('/holdings/all');
//   };
//   const foliopage = (e) => {
//     history.push('/portfolio');
//   };

//   const setNavExpanded = (expanded) => {
//     satnavExpanded(expanded);
//   };
//   const [medata, setmedata] = useState('');
//   const metamobilefun = () => {
//     history.push('/metaversify');
//     setmedata('meta');

//     setExpanded(!expanded);
//     setinfo('meta');
//   };

//   const metdeskfun = () => {
//     history.push('/metaversify');
//     setmedata('meta');

//     setinfo('meta');
//   };
//   return (
//     <>
//       {expanded ? <div className="blackscreen"></div> : ''}
//       <div className="custom_navbar1e mobo">
//         <Navbar
//           onToggle={setNavExpanded}
//           expanded={expanded}
//           expand="lg"
//           variant="dark"
//         >
//           <Container fluid className="p-0 mobile">
//             <Navbar.Brand href="/">
//               {' '}
//               <img
//                 src={logo}
//                 alt=""
//                 style={{ width: '180px', height: '40.26px' }}
//               />
//             </Navbar.Brand>
//             {expanded ? (
//               <Navbar.Toggle
//                 onClick={() => setExpanded(!expanded)}
//                 aria-controls="offcanvasNavbar"
//               >
//                 {/* <img style={{ width: "17px" }} src={closemark} alt="" /> */}
//               </Navbar.Toggle>
//             ) : (
//               <Navbar.Toggle
//                 onClick={() => setExpanded(!expanded)}
//                 aria-controls="offcanvasNavbar"
//               />
//             )}

//             <img
//               className="edit"
//               onClick={() => history.push(`/`)}
//               style={{ width: '225px', height: '40.26px' }}
//               alt=""
//               src={mobo}
//             />

//             {expanded ? (
//               <Navbar.Collapse id="responsive-navbar-nav">
//                 {/* <Nav className="me-auto">

//     </Nav> */}
//                 <Nav>
//                   <>
//                     {store.map((item) => {
//                       return (
//                         <>
//                           <h6
//                             onClick={() => handler(item.name)}
//                             style={
//                               item.name === 'Launch Pad' ||
//                               item.name === 'Portfolio'
//                                 ? {
//                                     cursor: 'not-allowed',
//                                     opacity: '0.5',
//                                     fontSize: '22px ',
//                                   }
//                                 : info === item.name
//                                 ? { fontWeight: 'bold', fontSize: '30px ' }
//                                 : { opacity: '0.5', fontSize: '22px ' }
//                             }
//                           >
//                             {item.name}
//                           </h6>
//                         </>
//                       );
//                     })}
//                     <div className="sectionlabel-mobile">
//                       <label
//                         className="label1"
//                         onClick={() =>
//                           window.open(
//                             'https://my.affiliate.app/register',
//                             '_blank',
//                           )
//                         }
//                       >
//                         {' '}
//                         Register
//                       </label>
//                     </div>
//                   </>
//                 </Nav>
//               </Navbar.Collapse>
//             ) : (
//               ''
//             )}
//           </Container>
//         </Navbar>
//       </div>

//       <div className="custom_navbar1e desk">
//         <Navbar expand="lg" variant="dark">
//           <Container fluid className="p-0 mobile">
//             <Navbar.Brand href="/" className="img-space">
//               {' '}
//               <img
//                 src={logo}
//                 alt=""
//                 style={{ width: '180px', height: '40.26px', marginTop: '10px' }}
//               />
//             </Navbar.Brand>

//             <Navbar.Toggle aria-controls="offcanvasNavbar" />
//             <div className="vl-top"></div>
//             <input type="text" placeholder="Search...." className="searchsty" />

//             <img
//               className="edit"
//               style={{ width: '225px', height: '40.26px' }}
//               src={logo}
//               alt=""
//             />

//             <Navbar.Collapse id="responsive-navbar-nav">
//               <Nav className="me-auto"></Nav>
//               <Nav>
//                 <div className="vl-topnav"></div>
//                 <Nav.Link
//                   onClick={() => histroy.push('/about')}
//                   style={
//                     location?.pathname == '/about'
//                       ? { fontWeight: 700, color: '#08152D' }
//                       : { color: '#08152D' }
//                   }
//                   className={
//                     'menu-items ' + (active === 'About' ? 'active' : '')
//                   }
//                 >
//                   About
//                 </Nav.Link>
//                 <div className="vl-topnav"></div>

//                 <Nav.Link
//                   onClick={() => histroy.push('/brands')}
//                   className={
//                     'menu-items ' + (active === 'Brands' ? 'active' : '')
//                   }
//                 >
//                   Brands
//                 </Nav.Link>
//                 <div className="vl-topnav"></div>
//                 <Nav.Link
//                   className={
//                     'menu-items ' + (active === 'Brands' ? 'active' : '')
//                   }
//                 >
//                   Products
//                 </Nav.Link>
//                 <div className="vl-topnav"></div>
//                 <Nav.Link
//                   onClick={() => histroy.push('/explorer')}
//                   style={
//                     location?.pathname == '/explorer'
//                       ? { fontWeight: 700, color: '#08152D' }
//                       : { color: '#08152D' }
//                   }
//                   className={
//                     'menu-items ' + (active === 'corebrand' ? 'active' : '')
//                   }
//                 >
//                   Explorer
//                 </Nav.Link>
//                 <div className="vl-topnav"></div>
//                 <Nav.Link
//                   className={
//                     'menu-items ' + (active === 'Starter' ? 'active' : '')
//                   }
//                   onClick={() =>
//                     window.open('https://my.affiliate.app', '_blank')
//                   }
//                 >
//                   Login
//                 </Nav.Link>
//                 {/* <div className="vl-topnav"></div>
//                 <Nav.Link className="menu-items">Accelerator</Nav.Link> */}
//                 {/* <div className="vl-topnav"></div>
//                 <Nav.Link onClick={hanldeRoute} className={"menu-items " + (active === "Portfolio" ? "active" : "")}>Portfolio</Nav.Link> */}

//                 {/* <div className="vl-topnav"></div>
//                 <Nav.Link
//                   onClick={Contact}
//                   className={"menu-items " + (active === "Contact" ? "active" : "")}

//                 >Contact</Nav.Link> */}
//                 <div className="vl-topnav"></div>
//                 <div
//                   className="investor-class"
//                   onClick={() =>
//                     window.open('https://my.affiliate.app/register', '_blank')
//                   }
//                   style={{
//                     backgroundColor: '#08152D',
//                     color: '#fff',
//                     cursor: 'pointer',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <h5
//                     style={{
//                       margin: '0',
//                       fontSize: '15px',
//                       color: '#fff',
//                       fontWeight: '700',
//                       marginTop: '9px',
//                     }}
//                   >
//                     Register
//                   </h5>
//                 </div>
//                 {/* <div className="vl-topnav"></div>
//                 <Nav.Link className="menu-items">Services</Nav.Link>
//                 <div className="vl-topnav"></div>
//                 <Nav.Link className="menu-items">Media</Nav.Link> */}
//               </Nav>
//             </Navbar.Collapse>
//           </Container>
//         </Navbar>
//       </div>

//     </>
//   );
// }

import React, { useState, useEffect, useContext } from "react";
import "./Navbar.scss";
import { Switch, useHistory, Redirect, useLocation } from "react-router-dom";
import landinglogo from "../../assets/images/landinglogo.svg";
import dropdown from "../../assets/images/clipIcons/dropdown.svg";
import upIcon from "../../assets/images/clipIcons/upIcon.svg";
import close from "../../assets/images/clipIcons/close.svg";
import { VaultContext } from "../../contexts/VaultContext";

import navbarRight from "../../assets/images/navbarRight.svg";
export default function index() {
  const { isHamMenuOpen, setIsHamMenuOpen, selected, setSelected } =
    useContext(VaultContext);
  const histroy = useHistory();
  return (
    <div className="NavBar_dash">
      <div className="nav-mob">
        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
          <img src={landinglogo} onClick={() => histroy.push("/")} />
          <div
            className="dropdown-container"
            onClick={() => {
              setSelected(!selected);
            }}
          >
            <div>
              By <span style={{ fontWeight: "650" }}>AIR</span>
            </div>
            <div style={{ display: "flex" }}>
              <img src={selected ? upIcon : dropdown} alt="dropdown" />
            </div>
          </div>
        </div>
        <img
          className="navbarCointainer"
          src={isHamMenuOpen ? close : navbarRight}
          onClick={() => {
            setIsHamMenuOpen(!isHamMenuOpen);
            if (!isHamMenuOpen) {
              document.body.style.overflow = "hidden";
            } else {
              document.body.style.overflow = "auto";
            }
          }}
        />
      </div>
      <div className="text-nav">
        <h5
          style={{
            color: "#08152D",
            fontWeight: "700",
          }}
        >
          Explorer
        </h5>
        <h5
          onClick={() => window.open("https://affiliate.viral.group", "_blank")}
        >
          For Affiliates
        </h5>
        <h5>For Influencers</h5>
        <h5
          onClick={() =>
            window.open("https://engagement.viral.group", "_blank")
          }
        >
          For Brands
        </h5>
      </div>
      <div className="buttonHanding">
        <label
          className="Login"
          onClick={() => window.open("https://verse.viral.group/", "_blank")}
        >
          Login
        </label>
        <label
          className="start"
          onClick={() =>
            window.open("https://verse.viral.group/register", "_blank")
          }
        >
          Get Started
        </label>
      </div>
    </div>
  );
}
