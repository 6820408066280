import React, { Component, createContext } from 'react';

const ChatContext = createContext();

export class ChatContextProvider extends Component {
  constructor(props) {
    super();

    this.state = {
      botActiveCategory: '',
      botActiveSubCategory: '',
      learnCategories: '',
      learnCategoriesFull: '',
      isBot: false,
      isBotFullScreen: false,
      activeFooterMenu: '',
      isChatClosed: true,
    };
  }

  setBotActiveCategory = botActiveCategory => {
    this.setState({ botActiveCategory });
  };

  setBotActiveSubCategory = botActiveSubCategory => {
    this.setState({ botActiveSubCategory });
  };

  setLeanCatagories = (learnCategories, learnCategoriesFull = '') => {
    this.setState({ learnCategories, learnCategoriesFull });
  };

  setIsBot = isBot => {
    this.setState({ isBot });
  };

  setIsBotFullScreen = isBotFullScreen => {
    this.setState({ isBotFullScreen });
  };

  setActiveFooterMenu = activeFooterMenu => {
    this.setState({ activeFooterMenu });
  };

  toggleChatClose = () => {
    const { isChatClosed } = this.state;

    this.setState({ isChatClosed: !isChatClosed });
  };

  render() {
    const { children } = this.props;

    return (
      <ChatContext.Provider
        value={{
          ...this.state,
          setBotActiveCategory: this.setBotActiveCategory,
          setBotActiveSubCategory: this.setBotActiveSubCategory,
          setLeanCatagories: this.setLeanCatagories,
          setIsBot: this.setIsBot,
          setIsBotFullScreen: this.setIsBotFullScreen,
          setActiveFooterMenu: this.setActiveFooterMenu,
          toggleChatClose: this.toggleChatClose,
        }}
      >
        {children}
      </ChatContext.Provider>
    );
  }
}

export default ChatContext;
