import { useState } from 'react';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import { MONTHS_ARRAY } from '../../utils';

const BankPage = () => {
  const [activeMonth, setActiveMonth] = useState(MONTHS_ARRAY[0]);

  return (
    <LayoutWithSideBar>
      <div className="fragment-root bank-page-wrapper">
        <div className="header-container">
          <div className="header-item">
            <div className="header-value-name">INTEREST</div>
            <div className="header-value">$463.58</div>
          </div>
          <div className="header-item big">
            <div className="header-value-name">Affiliate Bank</div>
            <div className="header-value">$1,013.52</div>
          </div>
          <div className="header-item">
            <div className="header-value-name">EXPENSES</div>
            <div className="header-value">$1,013.52</div>
          </div>
        </div>
        <div className="month-tab-container">
          {MONTHS_ARRAY.map((item) => (
            <div
              key={item}
              className={`month-tab-item ${
                activeMonth === item ? 'active' : ''
              }`}
              onClick={() => setActiveMonth(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="list-container">
          {[...LIST, ...LIST, ...LIST].map((item, index) => (
            <div key={index} className="list-item">
              <img src={item.icon} alt="" className="item-icon" />
              <div className="item-details">
                <div className="d-flex flex-column">
                  <div className="item-name">{item.title}</div>
                  <div className="item-sub-title">{item.subTitle}</div>
                </div>
                <div className="d-flex flex-column">
                  <div className={`item-amount ${item.up ? 'up' : ''}`}>
                    {item.amount}
                  </div>
                  <div className="item-usd-amount">{item.usdAmount}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </LayoutWithSideBar>
  );
};

export default BankPage;

const LIST = [
  {
    title: 'Deposits',
    subTitle: 'Over 150 operators',
    amount: '0.3425 BTC',
    usdAmount: '$2,242.82',
    up: true,
    icon: require('../../assets/images/BankIcons/deposits-icon.svg').default,
  },
  {
    title: 'Payments',
    subTitle: '30 more',
    amount: '0.3425 BTC',
    usdAmount: '$2,242.82',
    up: false,
    icon: require('../../assets/images/BankIcons/payments-icon.svg').default,
  },
  {
    title: 'Withdrawals',
    subTitle: 'Over 60 wallets',
    amount: '0.3425 BTC',
    usdAmount: '$2,242.82',
    up: false,
    icon: require('../../assets/images/BankIcons/whidrawal-icon.svg').default,
  },
  {
    title: 'Investments',
    subTitle: 'Over 150 operators',
    amount: '0.3425 BTC',
    usdAmount: '$2,242.82',
    up: true,
    icon: require('../../assets/images/BankIcons/investments-icon.svg').default,
  },
  {
    title: 'Transfers',
    subTitle: 'Over 150 operators',
    amount: '0.3425 BTC',
    usdAmount: '$2,242.82',
    up: false,
    icon: require('../../assets/images/BankIcons/transfer-icon.svg').default,
  },
];
