import React, { useContext, useEffect, useState } from 'react';
import UserLevels from './UserLevels';
import UserForm from './UserForm';
import ChatContext from '../../../contexts/ChatContext';
import SignUpStatus from './SignUpStatus';
import LicensesBot from '../LicensesBot';

const FAQ = () => {
  const { isBotFullScreen, setIsBotFullScreen } = useContext(ChatContext);

  const [selectedOption, setSelectedOption] = useState();
  const [userQuery, setUserQuery] = useState();
  const [showLocateUser, setShowLocateUser] = useState(false);
  const [showSignUpStatus, setShowSignUpStatus] = useState(false);
  const [showUserLicenses, setShowUserLicenses] = useState(false);

  useEffect(() => {
    if (selectedOption) {
      setIsBotFullScreen(true);
    } else {
      setIsBotFullScreen(false);
    }
  }, [selectedOption]);

  if (showUserLicenses) {
    return (
      <LicensesBot
        userEmail={userQuery?.email}
        onClose={() => setShowUserLicenses(false)}
        onBack={() => {
          setShowUserLicenses(false);
        }}
      />
    );
  }

  if (showSignUpStatus) {
    return (
      <SignUpStatus
        selectedOption={selectedOption}
        userEmail={userQuery?.email}
        onClose={() => setShowSignUpStatus(false)}
        onBack={() => {
          setShowSignUpStatus(false);
        }}
      />
    );
  }

  if (showLocateUser) {
    return (
      <UserLevels
        selectedOption={selectedOption}
        userQuery={userQuery}
        onClose={() => setShowLocateUser(false)}
        onBack={() => {
          setShowLocateUser(false);
        }}
      />
    );
  }

  switch (selectedOption?.title) {
    case 'Locate A Person':
      return (
        <UserForm
          selectedOption={selectedOption}
          setUserQuery={setUserQuery}
          onSubmit={() => setShowLocateUser(true)}
          onBack={() => setSelectedOption()}
        />
      );

    case 'Who Is Above Me?':
      return (
        <UserLevels
          selectedOption={selectedOption}
          onBack={() => setSelectedOption()}
        />
      );

    case 'Did This Person Sign Up Properly?':
      return (
        <UserForm
          selectedOption={selectedOption}
          setUserQuery={setUserQuery}
          onSubmit={() => setShowSignUpStatus(true)}
          onBack={() => setSelectedOption()}
        />
      );
    case 'Is This Person Active?':
      return (
        <UserForm
          selectedOption={selectedOption}
          setUserQuery={setUserQuery}
          onSubmit={() => setShowUserLicenses(true)}
          onBack={() => setSelectedOption()}
        />
      );
    default:
      return (
        <div className="faq-container">
          <div className="header">Lineage FAQ’s</div>
          <div className="option-list">
            {LIST.map((item) => (
              <div
                key={item.title}
                className="option-item"
                onClick={() => setSelectedOption(item)}
              >
                <img src={item.icon} alt="" className="option-icon" />
                <div className="option-name">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      );
  }
};

export default FAQ;

const LIST = [
  {
    title: 'Locate A Person',
    icon: require('../../../assets/images/find-person.png').default,
  },
  {
    title: 'Did This Person Sign Up Properly?',
    icon: require('../../../assets/images/sign-up-correct.png').default,
  },
  {
    title: 'Is This Person Active?',
    icon: require('../../../assets/images/active-person.png').default,
  },
  {
    title: 'Who Is Above Me?',
    icon: require('../../../assets/images/upline-icon.png').default,
  },
];
