import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ProductsBrandSlider from '../../components/ProductsBrandSlider';
import { GX_API_ENDPOINT } from '../../configs';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import LandingProductList from '../../components/LandingProductList';
import Skeleton from 'react-loading-skeleton';
import PopupModalLayout from '../../layouts/PopupModalLayout';

const BrandsPage = () => {
  const [searchText, setSearchText] = useState('');
  const [activeBrand, setActiveBrand] = useState(CAROUSEL_ITEM[0]);
  const [allProducts, setAllProducts] = useState('');
  const [allBrands, setAllBrands] = useState();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('');
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(FILTERS[0]);
  const [filterTabList, setFilterTabList] = useState();
  const [productCategories, setProductCategories] = useState();
  const [filterList, setFilterList] = useState([]);
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    axios
      .get('https://teller2.apimachine.io/admin/allBankers')
      .then(({ data }) => {
        const brands = [
          { displayName: 'ALL BRANDS', colorCode: '002A51' },
          ...(data?.data || []),
        ];
        setAllBrands(brands);
        setActiveBrand(brands[0] || '');
      })
      .catch((error) => {
        console.log('Error on getting brands', error);
      });

    axios
      .get(`${GX_API_ENDPOINT}/gxb/product/category/get`)
      .then(({ data }) => {
        const categories = [{ name: 'All' }, ...(data?.categories || [])];
        setProductCategories(categories);
        // setActiveCategory(categories[0]);
      })
      .catch((error) => {
        console.log('Error on getting product categories', error);
      });

    axios
      .get(`${GX_API_ENDPOINT}/gxb/product/get`)
      .then(({ data }) => {
        const products = data?.products || [];
        // console.log('products', products);
        setAllProducts(products);
      })
      .catch((error) => {
        console.log('Error on getting products list', error);
      });
  }, []);
  useEffect(() => {
    if (!isSearchOpen) {
      setSearchText('');
    }
  }, [isSearchOpen]);

  useEffect(() => {
    if (allProducts) {
      const filter = allProducts.filter((x) => {
        let mainCategoryFilter = true;

        switch (activeFilter.name) {
          case 'Categories':
            mainCategoryFilter = activeCategory.code
              ? x.product_category_code === activeCategory?.code
              : true;
            break;
          case 'Billing':
            mainCategoryFilter = x[activeCategory?.value];
            break;
          case 'Status':
            mainCategoryFilter = x.product_active === activeCategory?.value;
            break;
          default:
        }

        const brandNameFilter =
          activeBrand?.displayName === 'ALL BRANDS' ||
          x.product_created_by === activeBrand?.email;

        return mainCategoryFilter && brandNameFilter;
      });

      setFilterList(filter);
    }
  }, [allProducts, activeCategory, activeBrand, activeFilter]);

  useEffect(() => {
    const searchQuery = searchText.trim().toLowerCase();

    const searchFilter = filterList.filter((x) =>
      x.product_name.toLowerCase().includes(searchQuery),
    );

    setSearchList(searchFilter);
  }, [filterList, searchText]);

  useEffect(() => {
    switch (activeFilter.name) {
      case 'Categories':
        setFilterTabList(productCategories);
        if (productCategories) {
          setActiveCategory(productCategories[0]);
        }
        break;
      case 'Billing':
        setFilterTabList(BILLING_FILTER);
        setActiveCategory(BILLING_FILTER[0]);
        break;
      case 'Status':
        setFilterTabList(STATUS_FILTER);
        setActiveCategory(STATUS_FILTER[0]);
        break;
      default:
    }
  }, [activeFilter, productCategories]);

  const onFilterSelect = (item) => {
    setActiveFilter(item);
    setFilterPopupOpen(false);
  };

  const list = isSearchOpen ? searchList : filterList;

  return (
    <LayoutWithSideBar>
      <div className="endorsement-brands-wrapper">
        <div className="header-container flex-column align-items-center py-5">
          <img
            src={
              require('../../assets/images/product-brands/endorsement-logo.svg')
                .default
            }
            alt=""
            className="header-logo"
          />
          <div className="header-text">
            Endorse Products To Add Them To Your Store
          </div>
        </div>
        <ProductsBrandSlider
          allBrands={allBrands}
          activeBrand={activeBrand}
          setActiveBrand={setActiveBrand}
        />
        <div className="frag-tab-container">
          {filterTabList ? (
            <>
              {isSearchOpen ? (
                <input
                  type="text"
                  className="search-input"
                  placeholder={`Find Products From ${
                    !activeCategory?.name || activeCategory?.name === 'All'
                      ? 'All Categories'
                      : activeCategory?.name
                  } and ${
                    !activeBrand?.displayName ||
                    activeBrand?.displayName === 'All'
                      ? 'All Brands'
                      : activeBrand?.displayName
                  }...`}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              ) : (
                filterTabList?.map((item) => (
                  <div
                    key={item.name}
                    className={`tab-item ${
                      item.name === activeCategory?.name ? 'active' : ''
                    }`}
                    onClick={() => setActiveCategory(item)}
                  >
                    {item.name}
                  </div>
                ))
              )}
              <div className="tab-actions-container">
                <div
                  className="tab-action"
                  onClick={() => setFilterPopupOpen(true)}
                >
                  <img
                    src={activeFilter?.icon}
                    alt=""
                    className="tab-action-icon"
                  />
                </div>
                <div
                  className={`tab-action ${isSearchOpen ? 'close' : ''}`}
                  onClick={() => setIsSearchOpen(!isSearchOpen)}
                >
                  <img
                    src={
                      isSearchOpen
                        ? require('../../assets/images/close-close.svg').default
                        : require('../../assets/images/search-colorful.svg')
                            .default
                    }
                    alt=""
                    className="tab-action-icon"
                  />
                </div>
              </div>
            </>
          ) : (
            Array(5)
              .fill(1)
              .map((_, index) => (
                <div key={index} className="tab-item">
                  <Skeleton count={1} width={100} />
                </div>
              ))
          )}
        </div>
        <div className="fragment-container">
          <LandingProductList
            activeBrand={activeBrand}
            allProducts={allProducts}
            list={list}
            searchInput={searchText}
          />
        </div>
      </div>
      <PopupModalLayout
        isOpen={filterPopupOpen}
        onClose={() => setFilterPopupOpen(false)}
        headerText="Filter By"
      >
        <div className="filter-container">
          {FILTERS.map((item) => (
            <div
              key={item.name}
              className={`filter-item ${
                activeFilter?.name === item.name ? 'active' : ''
              }`}
              onClick={() => onFilterSelect(item)}
            >
              <img src={item.icon} alt="" className="item-icon" />
              <div className="item-name">{item.name}</div>
            </div>
          ))}
        </div>
      </PopupModalLayout>
    </LayoutWithSideBar>
  );
};

export default BrandsPage;

const CAROUSEL_ITEM = [
  {
    title: 'All Brands',
    logo: require('../../assets/images/product-brands/all-brands-logo.svg')
      .default,
  },
  {
    title: 'Shares',
    logo: require('../../assets/images/product-brands/shares-offering-logo.svg')
      .default,
  },
  {
    title: 'NFTs',
    logo: require('../../assets/images/product-brands/nft-logo.svg').default,
  },
  {
    title: 'Bonds',
    logo: require('../../assets/images/product-brands/bonds-logo.svg').default,
  },
  {
    title: 'Funds',
    logo: require('../../assets/images/product-brands/funds-offering-logo.svg')
      .default,
  },
  {
    title: 'All Brands',
    logo: require('../../assets/images/product-brands/all-brands-logo.svg')
      .default,
  },
  {
    title: 'Shares',
    logo: require('../../assets/images/product-brands/shares-offering-logo.svg')
      .default,
  },
  {
    title: 'NFTs',
    logo: require('../../assets/images/product-brands/nft-logo.svg').default,
  },
  {
    title: 'Bonds',
    logo: require('../../assets/images/product-brands/bonds-logo.svg').default,
  },
  {
    title: 'Funds',
    logo: require('../../assets/images/product-brands/funds-offering-logo.svg')
      .default,
  },
];

const FILTERS = [
  {
    name: 'Categories',
    icon: require('../../assets/images/product-tab-action.svg').default,
  },
  {
    name: 'Billing',
    icon: require('../../assets/images/billing-filter-icon.svg').default,
  },
  {
    name: 'Status',
    icon: require('../../assets/images/status-filter-icon.svg').default,
  },
];

const STATUS_FILTER = [
  { name: 'In Stock', value: true },
  { name: 'Discontented', value: false },
];

const BILLING_FILTER = [
  { name: 'Monthly', value: 'monthly' },
  { name: 'Annual', value: 'annual' },
  { name: 'One-Time', value: 'lifetime' },
  { name: 'Staking', value: 'staking_allowed' },
];
