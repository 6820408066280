import React, { useContext, useEffect } from 'react';
import ChatContext from '../../contexts/ChatContext';

const FooterMenu = () => {
  const { setActiveFooterMenu, activeFooterMenu } = useContext(ChatContext);

  useEffect(() => {
    setActiveFooterMenu(FOOTER_MENU[0]);
  }, []);

  return (
    <div className="bot-footer-container">
      {FOOTER_MENU.map((item, index) => (
        <React.Fragment key={item.title}>
          <div
            className="footer-item"
            onClick={() => setActiveFooterMenu(item)}
          >
            <img
              src={item.icon}
              alt=""
              className={`footer-icon ${
                activeFooterMenu?.title === item.title ? 'active' : ''
              }`}
            />
          </div>
          {index === 1 && (
            <div
              className="menu-button"
              onClick={() => setActiveFooterMenu({ title: 'Menu' })}
            >
              <img
                src={
                  require('../../assets/images/bot-footer-icons/chats-io-icon.svg')
                    .default
                }
                alt=""
                className="menu-icon"
              />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FooterMenu;

const FOOTER_MENU = [
  {
    title: 'Chats',
    icon: require('../../assets/images/bot-footer-icons/chat-icon.svg').default,
  },
  {
    title: 'Bots',
    icon: require('../../assets/images/bot-footer-icons/bot-icon.svg').default,
  },
  {
    title: 'Calls',
    icon: require('../../assets/images/bot-footer-icons/call-icons.svg')
      .default,
  },
  {
    title: 'Mail',
    icon: require('../../assets/images/bot-footer-icons/mails-icon.svg')
      .default,
  },
];
