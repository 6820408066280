import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import { emailValidator } from '../utils';
import ButtonLoadingAnimation from './ButtonLoadingAnimation';
import { APP_CODE, GX_API_ENDPOINT, GX_AUTH_URL } from '../configs';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import { BrokerAppContext } from '../contexts/BrokerAppContext';
import LoadingAnimation from './LoadingAnimation';
import OtpInput from 'react-otp-input';
import SideImage from '../assets/images/login-side-image.jpg';
import PasswordResetFrom from './PasswordResetForm';
import AppLogo from '../assets/images/affliate-app-dark-full-logo.svg';
import LoginLoadingAnimation from './LoginLoadingAnimation';

const LoginFrom = ({ history, appCode }) => {
  const { setRefreshData } = useContext(BrokerAppContext);

  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const [otpInput, setOtpInput] = useState('');
  const [isPasswordRestOpen, setIsPasswordRestOpen] = useState(false);

  useEffect(() => {
    if (otpInput.trim().length === 6) {
      onFormSubmit();
    }
  }, [otpInput]);

  const onFormSubmit = (event) => {
    if (event?.preventDefault) {
      event.preventDefault();
    }

    if (!isLoading) {
      const email = emailInput.toLowerCase().trim();

      if (!email) {
        toast.error('📧 Please enter your email');
      } else if (!emailValidator(email)) {
        toast.error('📧 Please enter a valid email');
      } else if (!passwordInput) {
        toast.error('🔑 Please enter your password');
      } else {
        setIsLoading(true);

        let postData = {
          email,
          password: passwordInput,
        };

        if (show2FA) {
          postData = { ...postData, totp_code: otpInput };
        }

        Axios.post('https://gxauth.apimachine.com/gx/user/login', postData)
          .then((response) => {
            const { data } = response;
            if (data.mfa) {
              setShow2FA(true);
              setIsLoading(false);
            } else if (data.status) {
              // toast.success('✅ Logged in...');

              Axios.post(`${GX_API_ENDPOINT}/gxb/apps/register/user`, {
                email,
                app_code: appCode || APP_CODE,
              }).then((profileResp) => {
                // console.log('profileResp', profileResp.data);

                if (profileResp.data.profile_id) {
                  LocalStorageHelper.setProfileId(profileResp.data.profile_id);
                  setRefreshData();
                }
              });

              Axios.post(`${GX_API_ENDPOINT}/get_affiliate_data`, {
                email,
              })
                .then((res) => {
                  LocalStorageHelper.setUserDetails(
                    res.data.name,
                    `0x${res.data.ETH_Address}`,
                    res.data.affiliate_id,
                  );
                })
                .catch((error) => {
                  console.log('getUserDetails Error', error);
                })
                .finally(() => {
                  LocalStorageHelper.setAppLoginData(
                    data.idToken,
                    data.accessToken,
                    email,
                  );
                  history.push('/');
                });
            } else {
              setIsLoading(false);
              if (show2FA) {
                setOtpInput('');
              } else {
                setPasswordInput('');
              }
              toast.error(`❌ ${data.message}`);
            }
          })
          .catch((error) => {
            console.log('Login Error', error);
            setIsLoading(false);
          });
      }
    } else {
      toast.warn('Logging in...');
    }
  };

  const onRestPasswordRequest = () => {
    const email = emailInput.trim().toLowerCase();

    if (!email) {
      return toast.error('Please Input Your Email Address First');
    }

    Axios.post(`${GX_AUTH_URL}/gx/user/password/forgot/request`, {
      email,
    });

    setIsPasswordRestOpen(true);
  };

  return (
    <>
      <div className="login-page-wrapper container-fluid">
        <div className="row flex-fill">
          <div className="col-md-5 px-5">
            <form className="login-form px-5" onSubmit={onFormSubmit}>
              <img className="broker-logo" src={AppLogo} alt="" />
              <div className="login-action-container">
                {isPasswordRestOpen ? (
                  <PasswordResetFrom
                    email={emailInput}
                    setIsLoading={setIsLoading}
                    onClose={() => setIsPasswordRestOpen(false)}
                  />
                ) : show2FA ? (
                  <>
                    <div className="otp-header">
                      Enter The 2FA Code From Google Authenticator
                    </div>
                    <OtpInput
                      value={otpInput}
                      onChange={(otp) => setOtpInput(otp)}
                      numInputs={6}
                      containerStyle="otp-container"
                      inputStyle="input-field otp-input"
                      shouldAutoFocus
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Email"
                      value={emailInput}
                      onChange={(e) => setEmailInput(e.target.value)}
                    />
                    <input
                      type="password"
                      className="form-input"
                      placeholder="Password"
                      value={passwordInput}
                      onChange={(e) => setPasswordInput(e.target.value)}
                    />
                    <button className="actions-btn inverted" type="submit">
                      {isLoading ? 'Loggin In' : 'Login'}{' '}
                      {isLoading && <ButtonLoadingAnimation />}
                    </button>
                    <div
                      className="passwd-reset-text"
                      onClick={onRestPasswordRequest}
                    >
                      <span>Click Here</span> To Reset Password
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
          <div className="col-md-7 px-0 d-flex flex-column">
            <img src={SideImage} alt="" className="side-image" />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="login-loader-container">
          <LoginLoadingAnimation />
          <div className="loading-text">Loading Up Your Affiliate App</div>
        </div>
      )}
    </>
  );
};

export default withRouter(LoginFrom);
