import React from 'react';

const ButtonLoadingAnimation = ({ color }) => {
  return (
    <div className="loading-wrapper">
      <div className="loader">
        <div />
        <div>
          <div />
        </div>
      </div>
    </div>
  );
};

export default ButtonLoadingAnimation;
