import React, { useContext, useEffect, useState } from 'react';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import BrandControllerScreen from '../components/BrandControllerScreen';
import ExchangeFeePairScreen from '../components/ExchangeFeePairScreen';
import MobileNav from '../components/MobileNav';

export default function Complans() {
  const { userFullName } = useContext(BrokerAppContext);
  const [isBrandController, setIsBrandController] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [complanFeePairScreen, setComplanFeePairScreen] = useState([]);

  return (
    <>
      <MobileNav />
      {isBrandController ? (
        <BrandControllerScreen
          selectedBrand={selectedBrand}
          setIsBrandController={setIsBrandController}
          setComplanFeePairScreen={setComplanFeePairScreen}
          complanFeePairScreen={complanFeePairScreen}
        />
      ) : (
        <div className="complan_container">
          <div className="complan_subtitle">Welcome {userFullName}</div>
          <div className="complan_header">Select The ComPlan</div>
          {Item.map((item, key) => {
            return (
              <div
                className="brand_item"
                key={key}
                onClick={() => {
                  setSelectedBrand(item);
                  setIsBrandController(true);
                }}
              >
                <img
                  style={{
                    width: 31,
                    height: 30.93,
                  }}
                  src={item.halfIcon}
                />
                <div className="brand_title">{item.title}</div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

const Item = [
  {
    title: 'ExchangeFees',
    halfIcon: require('../../assets/mobileassets/exchange-fee-icon.svg')
      .default,
    mtToolName: 'Exchange Fees',
    icon: require('../../assets/mobileassets/exchange-fee-icon.png').default,
  },
  {
    title: 'MoneyMarkets',
    halfIcon: require('../../assets/mobileassets/money-market-icon.svg')
      .default,
    mtToolName: 'Money Market Fees',
    icon: require('../../assets/mobileassets/money-markets-full-icon.png')
      .default,
  },
  {
    title: 'Bonds',
    halfIcon: require('../../assets/mobileassets/bonds.svg').default,
    mtToolName: 'Bond Fees',
    icon: require('../../assets/mobileassets/bonds-full-icon.png').default,
  },
];
