import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Button, Alert, message, Input } from "antd";
import Axios from "axios";
Modal.setAppElement("#root");

const modelStyle = {
  overlay: {
    zIndex: "9999999999",
    backgroundColor: "rgba(103, 103, 103, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
    overflow: "auto",
    borderRadius: "none",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    maxWidth: "80%",
    paddingTop: "5rem",
    paddingBottom: "5rem",
    paddingLeft: "0",
    paddingRight: "0"
  }
};

const OldStakeModal = ({ open, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const signupHandler = () => {
    setIsLoading(true);

    Axios.post("https://comms.globalxchange.io/coin/requestStake", {
      address: localStorage.getItem("user_eth_addr"),
      email: localStorage.getItem("user_account"),
      token: localStorage.getItem("token"),
      value: 1000,
      type: "stake"
    })
      .then(res => {
        console.log("Staking (Legacy) Resp => ", res.data);
        if (res.data.status) {
          closeModal();
          message.success("Staked Successfully...");
        } else {
          setError(
            res.data.message ? res.data.message : "Some thing went wrong...!"
          );
        }
      })
      .catch(error => {
        console.log("Staking (Legacy) error => ", error);
        setError("Some thing went wrong...!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      setError("");
    };
  }, [open]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={modelStyle}
      contentLabel="Signup to Broker Dashboard"
    >
      <div className="broker-modal-container broker-sign-up-container">
        <h4 className="login-header">Stake 1000 GXT</h4>
        <p className="desc">Stake 1000 GXT for broker activating license</p>
        {error.length > 0 ? (
          <div className="error-wrapper">
            <Alert message={error} type="error" showIcon />
          </div>
        ) : null}
        <div className="login-btn-wrapper">
          <Button type="primary" loading={isLoading} onClick={signupHandler}>
            {isLoading ? "Staking" : "Stake"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OldStakeModal;
