const setAppLoginData = (
  brokerAppToken,
  brokerAppAccessToken,
  brokerAppLoginAccount,
) => {
  localStorage.setItem('brokerAppToken', brokerAppToken);
  localStorage.setItem('brokerAppAccessToken', brokerAppAccessToken);
  localStorage.setItem('brokerAppLoginAccount', brokerAppLoginAccount);
  localStorage.setItem('adminLoginEmail', brokerAppLoginAccount);
};

const setProfileId = profileId => {
  localStorage.setItem('profileId', profileId);
  localStorage.setItem('adminProfileId', profileId);
};

const getProfileId = () => {
  return localStorage.getItem('profileId');
};

const setAppDataOnLogOut = () => {
  localStorage.removeItem('brokerAppToken');
  localStorage.removeItem('brokerAppAccessToken');
  localStorage.removeItem('brokerAppLoginAccount');
  localStorage.removeItem('brokerName');
  localStorage.removeItem('brokerETHAddress');
  localStorage.removeItem('brokerAffId');
  localStorage.removeItem('profileId');
  localStorage.removeItem('adminLoginEmail');
  localStorage.removeItem('adminProfileId');
};

const getAppToken = () => {
  return localStorage.getItem('brokerAppToken');
};

const getAppEmail = () => {
  return localStorage.getItem('brokerAppLoginAccount');
};

const saveDashboard = (tabs, name) => {
  let currentSaves = localStorage.getItem('savedDashboards');
  if (currentSaves) {
    currentSaves = JSON.parse(currentSaves);
    currentSaves = [...currentSaves, { name, tabs }];
    localStorage.setItem('savedDashboards', JSON.stringify(currentSaves));
  } else {
    localStorage.setItem('savedDashboards', JSON.stringify([{ name, tabs }]));
  }
};

const getSavedDashboards = () => {
  const dashboards = localStorage.getItem('savedDashboards');

  return JSON.parse(dashboards);
};

const setUserDetails = (name, ethAddress, affiliateId) => {
  localStorage.setItem('brokerName', name);
  localStorage.setItem('brokerETHAddress', ethAddress.toLowerCase());
  localStorage.setItem('brokerAffId', affiliateId);
};

const getETHAddress = () => {
  return localStorage.getItem('brokerETHAddress');
};

const getBrokerName = () => {
  return localStorage.getItem('brokerName');
};

const getBrokerAffId = () => {
  return localStorage.getItem('brokerAffId');
};

const setAdminView = (userEmail, userProfileId) => {
  localStorage.setItem('isAdminView', JSON.stringify(true));

  const adminEmail = localStorage.getItem('adminLoginEmail');
  if (!adminEmail) {
    const email = getAppEmail();
    localStorage.setItem('adminLoginEmail', email);
  }

  const adminProfileId = localStorage.getItem('adminProfileId');
  if (!adminProfileId) {
    const profileId = getProfileId();
    localStorage.setItem('adminProfileId', profileId);
  }

  localStorage.setItem('brokerAppLoginAccount', userEmail);
  localStorage.setItem('profileId', userProfileId);
};

const getIsAdminView = () => {
  return JSON.parse(localStorage.getItem('isAdminView')) || false;
};

const removeAdminView = () => {
  const email = localStorage.getItem('adminLoginEmail');
  const profileId = localStorage.getItem('adminProfileId');

  localStorage.setItem('isAdminView', JSON.stringify(false));
  localStorage.setItem('brokerAppLoginAccount', email);
  localStorage.setItem('profileId', profileId);
};

export default {
  setAppLoginData,
  setAppDataOnLogOut,
  getAppToken,
  getAppEmail,
  saveDashboard,
  getSavedDashboards,
  setUserDetails,
  getETHAddress,
  getBrokerName,
  getBrokerAffId,
  setProfileId,
  getProfileId,
  setAdminView,
  getIsAdminView,
  removeAdminView,
};
