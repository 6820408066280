
import moment from 'moment-timezone';
import { Parser } from 'html-to-react';
import TimeAgo from 'javascript-time-ago';

export const emailValidator = (email) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const usernameRegex = /^(?=[a-z_\d]*[a-z])[a-z_\d]{6,}$/;

export const usdValueFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat('en-US');

export const getTimeFromTimeStamp = (timestamp) => {
  const date = new Date(timestamp);
  const m = moment.utc(date);
  return m.clone().tz('America/New_York').format('hh:mm A');
};

export const getDateFromTimeStamp = (timestamp) => {
  const date = new Date(timestamp);
  const m = moment.utc(date);

  const dateString = m.clone().tz('America/New_York').format('MMM Do YYYY');

  let formattedDate;

  if (dateString.match('st')) {
    formattedDate = dateString.replace('st', "<span class='ordinal'>st</span>");
  } else if (dateString.match('nd')) {
    formattedDate = dateString.replace('nd', "<span class='ordinal'>nd</span>");
  } else if (dateString.match('rd')) {
    formattedDate = dateString.replace('rd', "<span class='ordinal'>rd</span>");
  } else {
    formattedDate = dateString.replace('th', "<span class='ordinal'>th</span>");
  }

  const htmlParser = new Parser();

  return htmlParser.parse(formattedDate);
};

export const formatFromExpValues = (value) => {
  const strValue = value.toString();
  const formattedValue = `${strValue.substring(0, 3)}${strValue.substring(
    strValue.indexOf('e'),
    strValue.length,
  )}`;
  return formattedValue;
};

export const formatToExpValue = (value) => {
  return value.toExponential(1);
};

export const formatterWithTwoDigits = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const nameFormatter = (name) => {
  const [firstName, lastName] = name?.split(' ');

  return `${firstName || ''} ${lastName ? lastName[0] : ''}`;
};

export const onPaste = async (callback) => {
  if (callback) {
    const copiedText = await navigator.clipboard.readText();

    callback(copiedText.trim());
  }
};

export const isCrypto = (coinSymbol) => {
  const fiatCurrencies = [
    'AUS',
    'GBP',
    'CAD',
    'USD',
    'CNY',
    'COP',
    'EUR',
    'INR',
    'IDR',
    'JPY',
    'MXN',
    'AED',
    'USD',
  ];

  return !fiatCurrencies.includes(coinSymbol || '');
};

export const usdValueFormatterWithoutSign = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const cryptoVolumeFormatter = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});

export const cryptoFormatter = (value) => {
  const parsedFloatValue = parseFloat(value);
  const parsedIntValue = parseInt(value);

  const numberOfdigitsBeforDecimel = parsedIntValue.toString().length;

  const delta = 5 - numberOfdigitsBeforDecimel;

  return parsedFloatValue.toFixed(delta < 0 ? 0 : delta);
};

const fiat = [
  'INR',
  'AUD',
  'GBP',
  'CAD',
  'EUR',
  'CNY',
  'JPY',
  'AED',
  'USD',
  'USDT',
  'GXT',
];
const crypto = ['ETH', 'BTC', 'LTC'];

export const formatterHelper = (value, currency) => {
  if (!currency) {
    return usdValueFormatterWithoutSign.format(value);
  }

  if (crypto.includes(currency.toUpperCase())) {
    return cryptoFormatter(value);
  }

  return usdValueFormatterWithoutSign.format(value);
};

export const timestampParser = (timestamp) =>
  moment(timestamp).format('DD-MM-YYYY hh:mm A');

export const timeParserExpanded = (timestamp) => {
  const date = moment(timestamp).tz('America/New_York').format('MMM Do YYYY');
  const time = moment(timestamp).tz('America/New_York').format('hh:mm A');

  return `${date || ''} At ${time || ''} EST`;
};

export const timestampParserMini = (timestamp) =>
  moment(timestamp).tz('America/New_York').format('MMM Do YYYY');

export const timeAgoFormatter = (timestamp) => {
  let text = '';

  try {
    const timeAgo = new TimeAgo('en-US');
    text = timeAgo.format(timestamp);
  } catch (error) {}

  return text;
};

export const MONTHS_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const getUriImage = (uri) => {
  return uri !== null &&
    uri !== undefined &&
    uri.includes('/') &&
    uri.includes('.')
    ? uri
    : '';
};

export const percentageFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
