import React from 'react';

const ChoicePicker = ({ onClose, setActiveView }) => {
  return (
    <div className="body-container">
      <div className="title">Which Option Best Explains You?</div>
      <div className="body">
        <div className="option-list my-5">
          {OPTIONS.map((item) => (
            <div
              key={item.action || item.title}
              className="option-item"
              onClick={() => setActiveView(item.action || '')}
            >
              <img src={item.icon} alt="" className="option-icon" />
              <div className="action-name">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div onClick={onClose || null} className="action-button">
        Close
      </div>
    </div>
  );
};

export default ChoicePicker;

const OPTIONS = [
  {
    title: 'I Am A Legacy GXBroker',
    icon: require('../../../../assets/images/gx-logo.png').default,
    action: 'LegacyReview',
  },
  {
    title: 'I Am A First Time License Buyer',
    icon: require('../../../../assets/images/license-icon.svg').default,
    action: 'SelectCoin',
  },
  {
    title: 'I Am Upgrading A License',
    icon: require('../../../../assets/images/money-padlock.svg').default,
    action: 'PurchaseUpgrade',
  },
];
