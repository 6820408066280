import React from 'react';
import PopupModalLayout from '../../layouts/PopupModalLayout';

const FilterOptionSelector = ({
  isOpen,
  onClose,
  options,
  setActiveSearchOption,
  setCustomactiveSearchOption,
  activeSearchFilter,
}) => {
  return (
    <PopupModalLayout
      isOpen={isOpen}
      onClose={onClose}
      headerText=""
      width={800}
    >
      <h1>Search For Affiliate By</h1>
      <div className="search-options-wrapper">
        {options.map(item => (
          <div
            key={item.title}
            className={`option-item ${
              activeSearchFilter?.title === item.title ? 'active' : ''
            }`}
            onClick={() => {
              setActiveSearchOption(item);
            
              onClose();
            }}
          >
            <div className="bran-img-container">
              <img src={item.icon} alt="" className="option-image" />
            </div>
            <div className="option-name" style={{marginLeft:"13px"}}>{item.title}</div>
          </div>
        ))}
      </div>
    </PopupModalLayout>
  );
};

export default FilterOptionSelector;
