import React, { useState } from 'react';
import AppsCarousel from '../components/AppsCarousel';
import { Link } from 'react-router-dom';

const AllAppsPage = () => {
  const [activePos, setActivePos] = useState(1);

  return (
    <div className="all-apps-wrapper">
      <Link className="close-btn" to="/">
        <img
          src={require('../assets/images/times-icon.svg').default}
          alt=""
          className="close-icon"
        />
      </Link>
      <div className="controller-container">
        <img
          src={require('../assets/images/influence-app-logo.svg').default}
          alt=""
          className="influence-logo"
        />
        {ALL_APPS_CATEGORY.map((item, index) => (
          <div
            key={item.title}
            className={`category-item ${
              index === activePos - 1 ? 'active' : ''
            }`}
            onClick={() => setActivePos(index + 1)}
          >
            <img src={item.image} alt="" className="category-image" />
            <div className="category-name">{item.title}</div>
          </div>
        ))}
      </div>
      <div className="carousel-container">
        <AppsCarousel setActivePos={setActivePos} activePos={activePos} />
      </div>
    </div>
  );
};

export default AllAppsPage;

const ALL_APPS_CATEGORY = [
  {
    title: 'Affiliate Marketers',
    image: require('../assets/images/all-apps-category/1.jpg').default,
  },
  {
    title: 'Businesses & Brands',
    image: require('../assets/images/all-apps-category/2.jpg').default,
  },
  {
    title: 'Influencer Financial Management',
    image: require('../assets/images/all-apps-category/3.jpg').default,
  },
  {
    title: 'Social Influencers',
    image: require('../assets/images/all-apps-category/4.jpg').default,
  },
];
