import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Button, Alert, message, Input } from "antd";
import Axios from "axios";

Modal.setAppElement("#root");

const modelStyle = {
  overlay: {
    zIndex: "9999999999",
    backgroundColor: "rgba(103, 103, 103, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
    overflow: "auto",
    borderRadius: "none",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    maxWidth: "80%",
    paddingTop: "5rem",
    paddingBottom: "5rem",
    paddingLeft: "0",
    paddingRight: "0"
  }
};

const StakeComponent = ({ open, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const signupHandler = () => {
    if (pin === "") {
      setError("Please your Promo Code");
    } else {
      setIsLoading(true);
      Axios.post("https://comms.globalxchange.io/coin/vault/gx/promo/stake", {
        email: localStorage.getItem("user_account"),
        token: localStorage.getItem("token"),
        promocode: pin.toString()
      })
        .then(resp => {
          console.log("Broker SignUp Resp =>", resp.data);
          if (resp.data.status) {
            closeModal();
            message.success("Staked Successfully...");
          } else {
            setError(
              resp.data.message
                ? resp.data.message
                : "Some thing went wrong...!"
            );
          }
        })
        .catch(error => {
          console.log("Broker SignUp Error =>", error);
          setError("Some thing went wrong...!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setError("");
    console.log("PIN IS =>", pin);
    return () => {};
  }, [pin]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={modelStyle}
      contentLabel="Signup to Broker Dashboard"
    >
      <div className="broker-modal-container broker-sign-up-container">
        <h4 className="login-header">Stake 1000 GXT</h4>
        <p className="desc">Stake 1000 GXT for activating broker license</p>
        <div className="pin-input-wrapper">
          <Input
            value={pin}
            onChange={e => setPin(e.target.value)}
            placeholder="Enter your promo code"
          />
        </div>
        {error.length > 0 ? (
          <div className="error-wrapper">
            <Alert message={error} type="error" showIcon />
          </div>
        ) : null}
        <div className="login-btn-wrapper">
          <Button type="primary" loading={isLoading} onClick={signupHandler}>
            {isLoading ? "Redeeming" : "Redeem"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default StakeComponent;
