import React, { useContext, useEffect, useState } from 'react';
import { BrokerAppContext } from '../../../contexts/BrokerAppContext';
import TxnList from './TxnList';

const FiatFunding = () => {
  const { walletCoinData } = useContext(BrokerAppContext);

  const [selectedType, setSelectedType] = useState('');
  const [fiats, setFiats] = useState();
  const [selectedFiat, setSelectedFiat] = useState();

  useEffect(() => {
    if (walletCoinData) {
      const coins = walletCoinData.filter((x) => x.asset_type === 'Fiat');
      setFiats(coins || []);
    }
  }, [walletCoinData]);

  if (selectedFiat) {
    return <TxnList coin={selectedFiat} status={selectedType} />;
  }

  return (
    <div className="action-add-wrapper">
      <div className="choice-group">
        <div className="choice-header">
          Which Type Of Funding Transactions Do You Want To See?
        </div>
        <div className="choice-list">
          {STATUS_TYPES.map((item) => (
            <div
              onClick={item.disabled ? null : () => setSelectedType(item.title)}
              key={item.title}
              className={`choice-item ${item.disabled ? 'disabled' : ''} ${
                selectedType === item.title ? 'active' : ''
              }`}
            >
              <img src={item.icon} alt="" className="choice-icon" />
              <div className="choice-name">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      {selectedType ? (
        <div className="choice-group">
          <div className="choice-header">
            You Have Funding Transactions In The Following Currencies. Please
            Select One
          </div>
          <div className="choice-list">
            {fiats?.map((item) => (
              <div
                onClick={item.disabled ? null : () => setSelectedFiat(item)}
                key={item.coinName}
                className={`choice-item ${item.disabled ? 'disabled' : ''} ${
                  selectedFiat?.coinName === item.coinName ? 'active' : ''
                }`}
              >
                <img src={item.coinImage} alt="" className="choice-icon" />
                <div className="choice-name">
                  {item.coinName?.length < 10 ? item.coinName : item.coinSymbol}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FiatFunding;

const STATUS_TYPES = [
  {
    title: 'Completed',
    icon: require('../../../assets/images/green-check-round.png').default,
    disabled: true,
  },
  {
    title: 'Cancelled',
    icon: require('../../../assets/images/cross-circle.png').default,
    disabled: true,
  },
  {
    title: 'In Process',
    icon: require('../../../assets/images/pending-txn.png').default,
  },
];
