import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import EndoresmentProductList from '../../components/EndoresmentProductList';
import ProfileAvatar from '../../components/ProfileAvatar';
import UserBrandsContactsModal from '../../components/UserBrandsContactsModal';
import UserSearchSettingsModal from '../../components/UserSearchSettingsModal';
import { GX_API_ENDPOINT } from '../../configs';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

const MyEndoresmentPage = () => {
  const axiosCancelRef = useRef();

  const [userData, setUserData] = useState('');
  const [activeBrand, setActiveBrand] = useState(ALL_BRAND_OBJ);
  const [allProducts, setAllProducts] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContacts, setIsContacts] = useState(false);
  const [endorsedBrands, setEndorsedBrands] = useState('');
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [billingMethods, setBillingMethods] = useState('');
  const [activeBillingMethods, setActiveBillingMethods] = useState(
    ALL_METHOD_OBJ,
  );
  const [productApps, setProductApps] = useState('');
  const [activeProductApp, setActiveProductApp] = useState(ALL_APP_OBJ);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    const email = LocalStorageHelper.getAppEmail();

    axios
      .get(`${GX_API_ENDPOINT}/coin/vault/service/users/holdings/data/get`, {
        params: {
          // app_code: APP_CODE,
          downlines_count: true,
          downlines_data: true,
          email,
        },
      })
      .then(({ data }) => {
        // console.log('Profile Data', data);
        const users = data?.users || [];
        setUserData(users[0] || '');
      })
      .catch((error) => {
        console.log('getUserDetails Error', error);
      });

    axios
      .post(`${GX_API_ENDPOINT}/gxb/product/price/with/fees/get`, {
        broker_user: email,
      })
      .then(({ data }) => {
        // console.log('data', data);

        const products = data?.products || [];
        const parsedProduct = products.map((item) => ({
          ...item.product,
        }));

        const brands = data.productCreators || {};
        let parsedBrands = Object.keys(brands).map((key) => ({
          ...brands[key],
        }));
        parsedBrands = [ALL_BRAND_OBJ, ...parsedBrands];

        const methods = data.billingMethods || {};
        let parsedMethods = Object.keys(methods).map((key) => ({
          ...methods[key],
          ...getMehodeObj(key),
        }));
        parsedMethods = [ALL_METHOD_OBJ, ...parsedMethods];

        const apps = data.AppsData || {};
        let parsedApps = Object.keys(apps).map((key) => ({
          ...apps[key],
        }));
        parsedApps = [ALL_APP_OBJ, ...parsedApps];

        // console.log('object', parsedBrands);
        // console.log('parsedProduct', parsedProduct);
        // console.log('parsedMethods', parsedMethods);
        // console.log('parsedApps', parsedApps);

        setAllProducts(parsedProduct);
        setEndorsedBrands(parsedBrands);
        setBillingMethods(parsedMethods);
        setProductApps(parsedApps);
      })
      .catch((error) => {
        console.log('Error on getting products list', error);
      });
  }, []);

  useEffect(() => {
    const email = LocalStorageHelper.getAppEmail();

    if (email) {
      if (axiosCancelRef.current) {
        axiosCancelRef.current.cancel('Cancelled');
      }
      setAllProducts();
      axiosCancelRef.current = axios.CancelToken.source();

      let postData = {
        broker_user: email,
      };

      if (activeBrand.name !== 'All Brands') {
        postData = { ...postData, product_creator: activeBrand?.email };
      }

      if (activeBillingMethods.billingMethodName !== 'All Methods') {
        postData = {
          ...postData,
          app_code: activeBillingMethods?.billingMethod,
        };
      }

      if (activeProductApp.app_name !== 'All Apps') {
        postData = { ...postData, app_code: activeProductApp?.app_code };
      }

      axios
        .post(`${GX_API_ENDPOINT}/gxb/product/price/with/fees/get`, postData, {
          cancelToken: axiosCancelRef.current.token,
        })
        .then(({ data }) => {
          // console.log('Filter api', data);

          const products = data?.products || [];
          const parsedProduct = products.map((item) => ({
            ...item.product,
          }));

          // console.log('parsedProduct', parsedProduct);

          setAllProducts(parsedProduct);
        })
        .catch((error) => {
          console.log('Error on getting products list', error);
        });
    }
  }, [activeBrand, activeBillingMethods, activeProductApp]);

  const onStatsItemClick = (openContact = false) => {
    setIsModalOpen(true);
    setIsContacts(openContact);
  };

  const getMehodeObj = (methodeName) => {
    switch (methodeName) {
      case 'monthly':
        return MONTHLY_OBJ;
      case 'onetime':
        return ONE_TIME_OBJ;
      case 'annually':
        return ANNUALLY_OBJ;
      case 'staking':
        return STAKING_OBJ;
      default:
        break;
    }
  };

  return (
    <LayoutWithSideBar>
      <div className="endorsement-brands-wrapper">
        {userData ? (
          <div className="profile-container">
            <div className="profile-details-container">
              <ProfileAvatar
                avatar={userData?.userData?.profile_img}
                name={userData?.userData?.name || ''}
                size={100}
                onClick={() => setSelectedProduct()}
              />
              <div className="profile-details">
                <div className="user-name">{userData?.userData?.name}</div>
                <a
                  href={`https://endorsement.app/affiliate/${userData?.userData?.username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="user-stats"
                >
                  Go To Endorsement Store
                </a>
              </div>
              <div className="user-stats-container">
                <div
                  className="user-stats-item"
                  onClick={() => onStatsItemClick(false)}
                >
                  <div className="stats-value">
                    {userData?.followingCount || 0}
                  </div>
                  <div className="stats-name">Brands</div>
                </div>
                <div className="user-stats-item">
                  <div className="stats-value">{allProducts?.length}</div>
                  <div className="stats-name">Products</div>
                </div>
                <div
                  className="user-stats-item"
                  onClick={() => onStatsItemClick(true)}
                >
                  <div className="stats-value">
                    {userData?.downlinesCount || 0}
                  </div>
                  <div className="stats-name">Contacts</div>
                </div>
                <UserBrandsContactsModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  isContacts={isContacts}
                  setIsContacts={setIsContacts}
                  contacts={userData?.downlines || []}
                  brands={endorsedBrands || []}
                  name={userData?.userData?.name}
                  setActiveBrand={setActiveBrand}
                  activeBrand={activeBrand}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="profile-container">
            <div className="profile-details-container">
              <Skeleton width={100} height={100} circle />
              <div className="profile-details">
                <Skeleton className="user-name mb-2" width={200} height={40} />
                <Skeleton className="user-stats" width={280} height={20} />
              </div>
            </div>
          </div>
        )}
        <div className="fragment-container">
          {/* <ProductsBrandSlider
          allBrands={CAROUSEL_ITEM}
          activeBrand={activeBrand}
          setActiveBrand={setActiveBrand}
        /> */}
          <div className="frag-header-container">
            <div
              className="active-category"
              onClick={() => setSelectedProduct()}
            >
              <img
                src={activeBrand?.profile_img}
                alt=""
                className="active-category-img"
              />
              <div className="active-brand-name">{activeBrand?.name}</div>
            </div>
            <div className="search-container">
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input"
                placeholder={`Find Products From ${activeBrand?.name} ${userData?.userData?.name} Endorses...`}
              />
            </div>
            <div
              className="header-action-button"
              onClick={() => setIsSettingOpen(true)}
            >
              <img
                src={require('../../assets/images/settings-icon.svg').default}
                alt=""
                className="action-icon"
              />
            </div>
            <UserSearchSettingsModal
              isOpen={isSettingOpen}
              onClose={() => setIsSettingOpen(false)}
              name={userData?.userData?.name}
              methods={billingMethods || []}
              apps={productApps || []}
              setActiveBillingMethods={setActiveBillingMethods}
              setActiveProductApp={setActiveProductApp}
              activeProductApp={activeProductApp}
              activeBillingMethods={activeBillingMethods}
            />
          </div>
          <EndoresmentProductList
            activeBrand={activeBrand}
            allProducts={allProducts}
            searchInput={searchText}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </div>
      </div>
    </LayoutWithSideBar>
  );
};

export default MyEndoresmentPage;

const ALL_BRAND_OBJ = {
  name: 'All Brands',
  profile_img: require('../../assets/images/product-brands/all-brands-small-icon.svg')
    .default,
};

const ALL_METHOD_OBJ = {
  billingMethodName: 'All Methods',
  icon: require('../../assets/images/product-billing/all-billing-type-icon.svg')
    .default,
};
const MONTHLY_OBJ = {
  billingMethodName: 'Monthly',
  icon: require('../../assets/images/product-billing/mothly-icon.svg').default,
};
const ONE_TIME_OBJ = {
  billingMethodName: 'One Time',
  icon: require('../../assets/images/product-billing/one-time-icon.svg')
    .default,
};
const ANNUALLY_OBJ = {
  billingMethodName: 'Annually',
  icon: require('../../assets/images/product-billing/annually-icon.svg')
    .default,
};
const STAKING_OBJ = {
  billingMethodName: 'Staking',
  icon: require('../../assets/images/product-billing/staking-icon.svg').default,
};

const ALL_APP_OBJ = {
  app_name: 'All Apps',
  app_code: 'all',
  app_icon: require('../../assets/images/all-apps-icon.svg').default,
};
