import React, { Component, createContext } from 'react';
import arrayMove from 'array-move';
import Axios from 'axios';
import { GX_API_ENDPOINT } from '../configs';
import LocalStorageHelper from '../utils/LocalStorageHelper';

let axiosToken = null;

export const CRMContext = createContext();

class CRMContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crmTabs: [],
      activeCrmTab: null,

      isTableSearchOpen: false,
      isTableExpanded: false,

      totalUsersList: null,
      directUsersList: null,
      indirectUsersList: null,

      totalCustomersList: null,
      directCustomersList: null,
      indirectCustomersList: null,

      totalBrokerList: null,
      directBrokersList: null,
      indirectBrokersList: null,

      totalTokenTxnVolume: null,
      directTokenTxnRevenue: null,
      indirectTokenTxnRevenue: null,

      totalOtcVolume: null,
      totalOtcRevenue: null,
      directOtcRevenue: null,
      indirectOtcRevenue: null,

      brokerageMarket: null,
      totalDigitalRevenue: null,
      directDigitalVolume: null,
      indirectDigitalVolume: null,
      brokerDealerRevenue: null,

      withdrawals: null,

      currentPage: 1,
      unfilteredDataSet: null,
      currentDataSet: null,
      brokerageVolume: 0,
    };
  }

  addToCrmTabs = tab => {
    const { crmTabs } = this.state;
    this.setState({ crmTabs: [...crmTabs, tab] });
  };

  removeCrmTabs = tab => {
    const { crmTabs } = this.state;
    const filteredTabs = crmTabs.filter(item => item.title !== tab.title);
    this.setState({ crmTabs: filteredTabs });
  };

  setActiveCrmTab = activeCrmTab => {
    this.setState({ activeCrmTab });
  };

  setCrmDashboardView = crmTabs => {
    this.setState({ crmTabs, activeCrmTab: crmTabs[0].title });
  };

  changeCrmTabPos = (tab, left, step) => {
    const { crmTabs } = this.state;

    const itemIndex = crmTabs.findIndex(item => item.title === tab.title);

    let toIndex;

    if (left) {
      toIndex = itemIndex - step;
      if (toIndex < 0) {
        toIndex = 0;
      }
    } else {
      toIndex = itemIndex + step;
      if (toIndex >= crmTabs.length) {
        toIndex = crmTabs.length - 1;
      }
    }

    const updatedTabs = arrayMove(crmTabs, itemIndex, toIndex);

    this.setState({ crmTabs: updatedTabs });
  };

  setTableSearchOpen = isTableSearchOpen => {
    this.setState({ isTableSearchOpen });
  };

  expandTable = () => {
    const { isTableExpanded } = this.state;
    this.setState({ isTableExpanded: !isTableExpanded });
  };

  setCurrentPage = currentPage => {
    this.setState({ currentPage });
  };

  filterDataSet = query => {
    const { unfilteredDataSet } = this.state;

    if (unfilteredDataSet) {
      if (query === '') {
        this.setState({ currentDataSet: unfilteredDataSet, currentPage: 1 });
      } else {
        const filtered = unfilteredDataSet.filter(item => {
          try {
            if (item.name || item.email) {
              if (
                item.name.toLowerCase().includes(query.toLowerCase()) ||
                item.email.toLowerCase().includes(query.toLowerCase())
              )
                return true;
            }
            if (item.txn.name || item.txn.email) {
              if (
                item.txn.name.toLowerCase().includes(query.toLowerCase()) ||
                item.txn.email.toLowerCase().includes(query.toLowerCase())
              )
                return true;
            }
          } catch (error) {
            return false;
          }
          return false;
        });
        this.setState({ currentDataSet: filtered, currentPage: 1 });
      }
    }
  };

  getUsersDetails = payload => {
    if (axiosToken) {
      axiosToken.cancel('Cancelled');
    }

    axiosToken = Axios.CancelToken.source();

    const affId = LocalStorageHelper.getBrokerAffId();

    // const affId = 'c789942f257ecbf1cea811d52c3a9937adc9bc8b';

    Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/getUsers`, {
      params: {
        affiliate_id: affId,
      },
      cancelToken: axiosToken.token,
    })
      .then(resp => {
        const { data } = resp;

        const totalUsersList = data.all ? data.all.users : [];
        const directUsersList = data.direct ? data.direct.users : [];
        const indirectUsersList = data.indirect ? data.indirect.users : [];

        const totalCustomersList = data.customers ? data.customers.users : [];
        const directCustomersList = data.customers.users.filter(
          item => item.ref_affiliate === affId,
        );
        const indirectCustomersList = data.customers.users.filter(
          item => item.ref_affiliate !== affId,
        );

        const totalBrokerList = data.brokers ? data.brokers.users : [];
        const directBrokersList = data.brokers.users.filter(
          item => item.ref_affiliate === affId,
        );
        const indirectBrokersList = data.brokers.users.filter(
          item => item.ref_affiliate !== affId,
        );

        let currentDataSet;

        switch (payload) {
          case 'Total Users':
            currentDataSet = totalUsersList;
            break;
          case 'Direct Users':
            currentDataSet = directUsersList;
            break;
          case 'Indirect Users':
            currentDataSet = indirectUsersList;
            break;
          case 'Total Customers':
            currentDataSet = totalCustomersList;
            break;
          case 'Direct Customers':
            currentDataSet = directCustomersList;
            break;
          case 'Indirect Customers':
            currentDataSet = indirectCustomersList;
            break;
          case 'Total Brokers':
            currentDataSet = totalBrokerList;
            break;
          case 'Direct Brokers':
            currentDataSet = directBrokersList;
            break;
          case 'Indirect Brokers':
            currentDataSet = indirectBrokersList;
            break;
          default:
        }

        this.setState({
          totalUsersList: {
            list: totalUsersList,
            count: totalUsersList.length,
          },
          directUsersList: {
            list: directUsersList,
            count: directUsersList.length,
          },
          indirectUsersList: {
            list: indirectUsersList,
            count: indirectUsersList.length,
          },
          totalCustomersList: {
            list: totalCustomersList,
            count: totalCustomersList.length,
          },
          directCustomersList: {
            list: directCustomersList,
            count: directCustomersList.length,
          },
          indirectCustomersList: {
            list: indirectCustomersList,
            count: indirectCustomersList.length,
          },
          totalBrokerList: {
            list: totalBrokerList,
            count: totalBrokerList.length,
          },
          directBrokersList: {
            list: directBrokersList,
            count: directBrokersList.length,
          },
          indirectBrokersList: {
            list: indirectBrokersList,
            count: indirectBrokersList.length,
          },
          currentDataSet,
          unfilteredDataSet: currentDataSet,
        });
      })
      .catch(err => {
        console.log('Error getting user Info', err);
      });
  };

  getTokenTransactions = payload => {
    if (axiosToken) {
      axiosToken.cancel('Cancelled');
    }

    axiosToken = Axios.CancelToken.source();

    const email = LocalStorageHelper.getAppEmail();

    // const email = 'salkhanempire@yahoo.com';

    Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/token_txns`, {
      params: {
        email,
        getTop: 6,
      },
      cancelToken: axiosToken.token,
    })
      .then(resp => {
        const { data } = resp;

        const totalTokenTxnVolume = data.all ? data.all : [];
        const directTokenTxnRevenue = data.direct ? data.direct : [];
        const indirectTokenTxnRevenue = data.indirect ? data.indirect : [];

        let currentDataSet;

        switch (payload) {
          case 'Total Token Transactional Volume':
            currentDataSet = totalTokenTxnVolume;
            break;
          case 'Direct Token Transactional Revenue':
            currentDataSet = directTokenTxnRevenue;
            break;
          case 'Indirect Token Transactional Revenue':
            currentDataSet = indirectTokenTxnRevenue;
            break;
          case 'Broker Dealer Transactional Revenue':
            currentDataSet = totalTokenTxnVolume;
            break;
          default:
        }

        this.setState({
          totalTokenTxnVolume: {
            list: totalTokenTxnVolume,
            count: data.volume ? data.volume.ttv : 0,
          },
          directTokenTxnRevenue: {
            list: directTokenTxnRevenue,
            count: data.revenue ? data.revenue.dtr : 0,
          },
          indirectTokenTxnRevenue: {
            list: indirectTokenTxnRevenue,
            count: data.revenue ? data.revenue.itr : 0,
          },
          brokerDealerTxnRevenue: {
            list: totalTokenTxnVolume,
            count: data.revenue ? data.revenue.bdr : 0,
          },
          currentDataSet,
          unfilteredDataSet: currentDataSet,
        });
      })
      .catch(err => {
        console.log('Error getting token Txns ', err);
      });
  };

  getOtcTransactions = payload => {
    if (axiosToken) {
      axiosToken.cancel('Cancelled');
    }

    axiosToken = Axios.CancelToken.source();

    const email = LocalStorageHelper.getAppEmail();

    // const email = 'salkhanempire@yahoo.com';

    Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/otc_txns`, {
      params: {
        email,
        getTop: 6,
      },
      cancelToken: axiosToken.token,
    })
      .then(resp => {
        const { data } = resp;

        const totalOtcVolume = data.all ? data.all : [];
        const directOtcRevenue = data.direct ? data.direct : [];
        const indirectOtcRevenue = data.indirect ? data.indirect : [];

        let currentDataSet;

        switch (payload) {
          case 'Total OTC Transactional Volume':
            currentDataSet = totalOtcVolume;
            break;
          case 'Total OTC Transactional Revenue':
            currentDataSet = totalOtcVolume;
            break;
          case 'Direct OTC Transactional Revenue':
            currentDataSet = directOtcRevenue;
            break;
          case 'Indirect OTC Transactional Revenue':
            currentDataSet = indirectOtcRevenue;
            break;
          default:
        }

        this.setState({
          totalOtcVolume: {
            list: totalOtcVolume,
            count: data.volume ? data.volume.ttv : 0,
          },
          totalOtcRevenue: {
            list: totalOtcVolume,
            count: data.revenue ? data.revenue.ttr : 0,
          },
          directOtcRevenue: {
            list: directOtcRevenue,
            count: data.revenue ? data.revenue.dtr : 0,
          },
          indirectOtcRevenue: {
            list: indirectOtcRevenue,
            count: data.revenue ? data.revenue.itr : 0,
          },
          currentDataSet,
          unfilteredDataSet: currentDataSet,
        });
      })
      .catch(err => console.log('Error fetching OTC TXn', err));
  };

  getDigitalTransaction = payload => {
    if (axiosToken) {
      axiosToken.cancel('Cancelled');
    }

    axiosToken = Axios.CancelToken.source();

    const email = LocalStorageHelper.getAppEmail();

    // const email = 'salkhanempire@yahoo.com';

    Axios.get(`${GX_API_ENDPOINT}/brokerage/stats/digital_txns`, {
      params: {
        email,
        getTop: 6,
      },
      cancelToken: axiosToken.token,
    })
      .then(resp => {
        const { data } = resp;

        const totalDigitalTxn = data.all ? data.all : [];
        const directDigitalTxn = data.direct ? data.direct : [];
        const indirectDigitalTxn = data.indirect ? data.indirect : [];

        let currentDataSet;

        switch (payload) {
          case 'Brokerage Money Market Earnings':
            currentDataSet = totalDigitalTxn;
            break;
          case 'Total Digital Transactional Revenue':
            currentDataSet = totalDigitalTxn;
            break;
          case 'Direct Digital Transactional Volume':
            currentDataSet = directDigitalTxn;
            break;
          case 'Indirect Digital Transactional Volume':
            currentDataSet = indirectDigitalTxn;
            break;
          case 'Broker Dealer Revenue':
            currentDataSet = totalDigitalTxn;
            break;
          default:
        }

        this.setState({
          brokerageMarket: {
            list: totalDigitalTxn,
            count: data.volume.ttv ? data.volume.ttv : 0,
          },
          totalDigitalRevenue: {
            list: totalDigitalTxn,
            count: data.revenue.ttr ? data.revenue.ttr : 0,
          },
          directDigitalVolume: {
            list: directDigitalTxn,
            count: data.revenue.dtr ? data.revenue.dtr : 0,
          },
          indirectDigitalVolume: {
            list: indirectDigitalTxn,
            count: data.revenue.itr ? data.revenue.itr : 0,
          },
          brokerDealerRevenue: {
            list: totalDigitalTxn,
            count: data.revenue.bdr ? data.revenue.bdr : 0,
          },
          currentDataSet,
          unfilteredDataSet: currentDataSet,
        });
      })
      .catch(err => console.log('Error fetching OTC TXn', err));
  };

  getWithdrawalData = () => {
    if (axiosToken) {
      axiosToken.cancel('Cancelled');
    }

    axiosToken = Axios.CancelToken.source();

    const ethAddress = LocalStorageHelper.getETHAddress();

    const email = LocalStorageHelper.getAppEmail();
    // const email = 'salkhanempire@yahoo.com';

    Axios.post(
      `${GX_API_ENDPOINT}withdrawal_address`,
      {
        ref_eth: ethAddress,
        email,
      },
      { cancelToken: axiosToken.token },
    ).then(resp => {
      console.log('Withdrawals details', resp.data);

      const withdrawals = resp.data;
      withdrawals.reverse();
      withdrawals.shift();

      this.setState({
        withdrawals: { list: withdrawals, count: 0 },
        currentDataSet: withdrawals,
        unfilteredDataSet: withdrawals,
      });
    });
  };

  setCurrentSets = sets => {
    this.setState({ currentDataSet: sets.list, unfilteredDataSet: sets.list });
  };

  render() {
    const { children } = this.props;
    return (
      <CRMContext.Provider
        value={{
          ...this.state,
          addToCrmTabs: this.addToCrmTabs,
          removeCrmTabs: this.removeCrmTabs,
          setActiveCrmTab: this.setActiveCrmTab,
          setCrmDashboardView: this.setCrmDashboardView,
          changeCrmTabPos: this.changeCrmTabPos,
          setTableSearchOpen: this.setTableSearchOpen,
          expandTable: this.expandTable,
          getUsersDetails: this.getUsersDetails,
          setCurrentPage: this.setCurrentPage,
          filterDataSet: this.filterDataSet,
          setCurrentSets: this.setCurrentSets,
          getTokenTransactions: this.getTokenTransactions,
          getOtcTransactions: this.getOtcTransactions,
          getDigitalTransaction: this.getDigitalTransaction,
          getWithdrawalData: this.getWithdrawalData,
        }}
      >
        {children}
      </CRMContext.Provider>
    );
  }
}
export const CRMConsumer = CRMContext.Consumer;

export default CRMContextProvider;
