import React from 'react';
import PopupModalLayout from '../../layouts/PopupModalLayout';

const OptionSelector = ({
  isOpen,
  onClose,
  options,
  setActiveSearchOption,
  setCustomactiveSearchOption,
  activeSearchOption,
}) => {
  return (
    <PopupModalLayout
      isOpen={isOpen}
      onClose={onClose}
      // headerText="Select Category"
      width={800}
    >
      <h1>What Are You Trying To Find?</h1>
      <div className="search-options-wrapper">
        {options.map(item => (
          <div
            key={item.title}
            className={`option-item ${
              activeSearchOption?.title === item.title ? 'active' : ''
            }`}
            onClick={() => {
              setActiveSearchOption(item);
              onClose();
              setCustomactiveSearchOption(item)
            }}
          >
            <div className="bran-img-container">
              <img src={item.icon} alt="" className="option-image" />
            </div>
            <div className='handle-modle-text'>
            <div className="option-name">{item.title}</div>
            <div className="option-name-title">{item.name}</div>
            </div>
          </div>
        ))}
      </div>
    </PopupModalLayout>
  );
};

export default OptionSelector;
