import React from 'react';
import { Link } from 'react-router-dom';
import LayoutWithSideBar from '../../layouts/LayoutWithSideBar';

const AffiliateBankHomePage = () => (
  <LayoutWithSideBar>
    <div className="endorsement-home-wrapper">
      <div
        className="cover-img"
        style={{
          backgroundImage: `url(${
            require('../../assets/images/affilate-bank-cover.jpg').default
          })`,
        }}
      />
      <div className="header-container">
        <Link to="/vaults" className="header-action">
          Vaults
        </Link>
        <div className="header-logo-container">
          <img
            src={require('../../assets/images/affiliate-bank-icon.svg').default}
            alt=""
            className="header-logo"
            style={{ width: 300, height: 50 }}
          />
        </div>
        <Link to="/gx-tokens" className="header-action">
          Influence
        </Link>
      </div>
      <div className="content-container">
        <div className="content-title">
          Select One Of The Following Options To Get Started
        </div>
        <div className="cards-container">
          <div className="card-item-container">
            <div className="card-item-header">Introduction Video</div>
            <div className="card-item-body">
              <div className="card-text">
                If This Is Your First Time Using AffiliateBank
              </div>
              <div className="card-action dark">Watch Video</div>
            </div>
          </div>
          <div className="card-item-container">
            <div className="card-item-header alt">Frequent Actions</div>
            <div className="card-item-body btn-list">
              {ACTIONS.map((item) => (
                <div key={item.title} className="body-btn-item">
                  <img src={item.icon} alt="" className="btn-icon" />
                  <div className="btn-text">{item.title}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="card-item-container">
            <div className="card-item-header">Understanding IFC</div>
            <div className="card-item-body">
              <div className="card-text">
                Learn How The InfluenceCoin Can Improve Your Business
              </div>
              <div className="card-action dark">Start Training</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutWithSideBar>
);

export default AffiliateBankHomePage;

const ACTIONS = [
  {
    title: 'Check Earnings',
    icon: require('../../assets/images/check-earnings-icon.svg').default,
  },
  {
    title: 'Withdraw Earnings',
    icon: require('../../assets/images/withdraw-earning-icon.svg').default,
  },
  {
    title: 'Transfer Between Vaults',
    icon: require('../../assets/images/transfer-vaults-icon.svg').default,
  },
];
