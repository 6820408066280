import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { GX_AUTH_URL } from '../configs';

const PasswordResetFrom = ({ email, setIsLoading, onClose }) => {
  const [otpInput, setOtpInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [confirmInput, setConfirmInput] = useState('');
  const [lengthValidity, setLengthValidity] = useState(false);
  const [capitalValidity, setCapitalValidity] = useState(false);
  const [numberValidity, setNumberValidity] = useState(false);
  const [specialCharValidity, setSpecialCharValidity] = useState(false);
  const [confirmValidity, setConfirmValidity] = useState(false);
  const [isOtpDone, setIsOtpDone] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);

  useEffect(() => {
    if (isOtpDone) {
      setIsInputValid(true);
    } else if (otpInput.trim().length === 6) {
      setIsInputValid(true);
    }
  }, [otpInput, isOtpDone]);

  useEffect(() => {
    const capRegex = new RegExp(/^.*[A-Z].*/);
    const numRegex = new RegExp(/^.*[0-9].*/);
    const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

    const password = passwordInput.trim();

    setLengthValidity(password.length >= 6);
    setCapitalValidity(capRegex.test(password));
    setNumberValidity(numRegex.test(password));
    setSpecialCharValidity(speRegex.test(password));
  }, [passwordInput]);

  useEffect(() => {
    setConfirmValidity(
      confirmInput.length >= 6 && confirmInput === passwordInput,
    );
  }, [confirmInput, passwordInput]);

  const requestResetPassword = () => {
    if (
      !(
        lengthValidity &&
        capitalValidity &&
        numberValidity &&
        specialCharValidity &&
        confirmValidity
      )
    ) {
      return toast.error('Password Does Not Match');
    }

    setIsLoading(true);

    const userEmail = email.trim().toLowerCase();

    axios
      .post(`${GX_AUTH_URL}/gx/user/password/forgot/confirm`, {
        email: userEmail,
        code: otpInput,
        newPassword: passwordInput,
      })
      .then(({ data }) => {
        console.log('RestPassword Data', data);

        if (data.status) {
          // setShowSuccess(true);
          toast.success(data.message);
          onClose();
        } else {
          toast.error(data.message || 'Something Went Wrong');
          setIsConfirmPassword(false);
          setIsOtpDone(false);
        }
      })
      .catch(error => {
        toast.error('Error on reseeting password');
        console.log('Error on reseeting password', error);
        setIsConfirmPassword(false);
        setIsOtpDone(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOtpSubmit = () => {
    if (otpInput.trim().length === 6) {
      setIsOtpDone(true);
    } else {
      toast.error('Please Input The OTP');
    }
  };

  const onPasswordsSubmit = () => {
    if (!lengthValidity) {
      toast.error('Password Should Have atleast 6 Characters');
    } else if (!capitalValidity) {
      toast.error('Password Should Have atleast 1 Capital Letter');
    } else if (!numberValidity) {
      toast.error('Password Should Have atleast 1 Numerical Character');
    } else if (!specialCharValidity) {
      toast.error('Password Should Have atleast 1 Special Character');
    } else {
      setIsConfirmPassword(true);
    }
  };

  const onConfrimSubmit = () => {
    if (confirmValidity) {
      requestResetPassword();
    } else {
      toast.error('Passwords does not match');
    }
  };

  return (
    <>
      {isOtpDone ? (
        <div className="forgot-input-container">
          <input
            type="password"
            className="form-input"
            placeholder={
              isConfirmPassword ? 'Confrim Password' : 'New Password'
            }
            value={isConfirmPassword ? confirmInput : passwordInput}
            onChange={e =>
              isConfirmPassword
                ? setConfirmInput(e.target.value)
                : setPasswordInput(e.target.value)
            }
          />
          <div
            className={`validity-status ${
              isConfirmPassword
                ? confirmInput === passwordInput
                : lengthValidity &&
                  capitalValidity &&
                  numberValidity &&
                  specialCharValidity
            }`}
          />
        </div>
      ) : (
        <>
          <div className="otp-header">
            Enter The Code We Just Sent To Your Email
          </div>
          <OtpInput
            value={otpInput}
            onChange={otp => setOtpInput(otp)}
            numInputs={6}
            containerStyle="otp-container"
            inputStyle="input-field otp-input"
            shouldAutoFocus
          />
        </>
      )}
      <button
        className={`reset-action actions-btn ${isInputValid ? 'inverted' : ''}`}
        type="button"
        onClick={
          isOtpDone
            ? isConfirmPassword
              ? onConfrimSubmit
              : onPasswordsSubmit
            : onOtpSubmit
        }
      >
        {isOtpDone ? 'Next' : 'Submit'}
      </button>
      <div className="passwd-reset-text" onClick={onClose}>
        <span>Click Here</span> To Reset Password
      </div>
    </>
  );
};

export default PasswordResetFrom;
