import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import './assets/scss/index.scss';
import 'animate.css/animate.min.css';
import 'fullpage-react-fs/dist/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import BrokerAppProvider from './contexts/BrokerAppContext';
import CRMContextProvider from './contexts/CRMContext';
import { ChatContextProvider } from './contexts/ChatContext';
import VaultContextProvider from './contexts/VaultContext';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);
ReactDOM.render(
  <BrokerAppProvider>
    <CRMContextProvider>
      <ChatContextProvider>
        <VaultContextProvider>
          <App />
        </VaultContextProvider>
      </ChatContextProvider>
    </CRMContextProvider>
  </BrokerAppProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
