import React, { useContext, useEffect, useState } from 'react';
import ChatContext from '../../../contexts/ChatContext';
import FAQ from './FAQ';
import SwapUpline from './SwapUpline';

const BotLineage = () => {
  const { isBotFullScreen, setIsBotFullScreen } = useContext(ChatContext);

  const [selectedOption, setSelectedOption] = useState('');

  const [activeFragment, setActiveFragment] = useState(null);

  useEffect(() => {
    switch (selectedOption?.title) {
      case "FAQ's":
        setActiveFragment(<FAQ />);
        break;
      case 'Swap Uplines':
        setActiveFragment(
          <SwapUpline
            onBack={() => setSelectedOption()}
            selectedOption={selectedOption}
          />,
        );
        break;
      default:
        setActiveFragment(null);
        break;
    }
  }, [selectedOption]);

  return (
    <div className="bot-lineage action-add-wrapper">
      <div className={`choice-group ${isBotFullScreen ? 'd-none' : ''}`}>
        <div className="choice-header">
          Select One Of The Brands That You Follow
        </div>
        <div className="choice-list">
          {OPTIONS.map((item) => (
            <div
              onClick={item.disabled ? null : () => setSelectedOption(item)}
              key={item._id}
              className={`choice-item ${item.disabled ? 'disabled' : ''} ${
                selectedOption?.title === item.title ? 'active' : ''
              }`}
            >
              <img src={item.icon} alt="" className="choice-icon" />
              <div className="choice-name">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      {activeFragment}
    </div>
  );
};

export default BotLineage;

const OPTIONS = [
  {
    title: "FAQ's",
    icon: require('../../../assets/images/question-mark.png').default,
  },
  {
    title: 'ChainView',
    icon: require('../../../assets/images/network-tree.png').default,
  },
  {
    title: 'Swap Uplines',
    icon: require('../../../assets/images/swap-upline-icon.png').default,
  },
];
