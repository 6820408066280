import Axios from 'axios';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APP_CODE, GX_API_ENDPOINT, GX_AUTH_URL } from '../../configs';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import LoadingAnimation from '../LoadingAnimation';
import CreatePassword from './CreatePassword';
import TempLogin from './TempLogin';

const PreRegisterSteps = ({ history, appCode }) => {
  const { setRefreshData } = useContext(BrokerAppContext);

  const [activeStep, setActiveStep] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [emailId, setEmailId] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const tempLoginClickHandler = () => {
    if (!isLoading) {
      setIsLoading(true);
      const email = emailId.trim().toLowerCase();

      const postData = { email, password: tempPassword };

      Axios.post(`${GX_AUTH_URL}/gx/user/login`, postData)
        .then((resp) => {
          const { data } = resp;

          // console.log('Temp Login Data', data);

          if (data.resetPassword) {
            setActiveStep('CreatePassword');
          } else {
            toast.error(data.message || 'API Error');
          }
        })
        .catch((error) => {
          console.log('Error on Login', error);
          toast.error('Network Error');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const loginHandler = () => {
    setIsLoading(true);

    const email = emailId.trim().toLowerCase();

    const postData = {
      email,
      password: tempPassword,
      newPassword: passwordInput,
    };

    // console.log('postData', postData);

    Axios.post(`${GX_AUTH_URL}/gx/user/login`, postData)
      .then((resp) => {
        const { data } = resp;
        if (data.status) {
          toast.success('✅ Logged in...');
          Axios.post(`${GX_API_ENDPOINT}/gxb/apps/register/user`, {
            email,
            app_code: appCode || APP_CODE,
          }).then((profileResp) => {
            // console.log('profileResp', profileResp.data);

            if (profileResp.data.profile_id) {
              LocalStorageHelper.setProfileId(profileResp.data.profile_id);
              setRefreshData();
            }
          });
          Axios.post(`${GX_API_ENDPOINT}/get_affiliate_data`, {
            email,
          })
            .then((res) => {
              LocalStorageHelper.setUserDetails(
                res.data.name,
                `0x${res.data.ETH_Address}`,
                res.data.affiliate_id,
              );
            })
            .catch((error) => {
              console.log('getUserDetails Error', error);
            })
            .finally(() => {
              LocalStorageHelper.setAppLoginData(
                data.idToken,
                data.accessToken,
                email,
              );
              setIsLoading(false);
              history.push('/');
            });
        } else {
          toast.error(`❌ ${data.message}`);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error on Login', error);
        toast.error(`Network Error`);
      });
  };

  if (isLoading) {
    return (
      <div className="d-flex h-100 flex-fill justify-content-center align-items-center">
        <LoadingAnimation />
      </div>
    );
  }

  switch (activeStep) {
    case 'CreatePassword':
      return (
        <CreatePassword
          passwordInput={passwordInput}
          setPasswordInput={setPasswordInput}
          onNext={loginHandler}
        />
      );
    default:
      return (
        <TempLogin
          emailId={emailId}
          setEmailId={setEmailId}
          tempPassword={tempPassword}
          setTempPassword={setTempPassword}
          onNext={() => tempLoginClickHandler()}
        />
      );
  }
};

export default withRouter(PreRegisterSteps);
