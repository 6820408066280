import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import mail from "../../assets/images/mail.svg";
import arrowside from "../../assets/images/arrowside.svg";
import network from "../../assets/images/network.svg";

import {
  GetInformationofcards,
  GetInformationofSider,
  GetInformationofDownline,
  GetInformationofBrands,
} from "./apiservice";
import { useParams, useHistory } from "react-router-dom";

import "./CustomSearch.scss";
// import '../../assets/scss/site-landing-page.scss'
import Slider from "react-slick";
import ProfileAvatar from "../ProfileAvatar";
import Navbar from "../../components/NavItem";
import Skeleton from "react-loading-skeleton";
export default function index() {
  const [getEmailData, setgetEmailData] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [affilateList, setaffilateList] = useState([]);
  const [innerDetail, setinnerDetail] = useState([]);
  const [searchNearByItems, setsearchNearByItems] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [getLoctionName, setgetLoctionName] = useState("");
  const [lenghtofdata, setlenghtofdata] = useState("");
  const [customersTotal, setcustomersTotal] = useState("");
  const [BrokerTotal, setBrokerTotal] = useState("");
  const [loader, setloading] = useState(false);
  const [storeTab, setstoreTab] = useState("customers");
  const [BrandStore, setBrandStore] = useState([]);
  const [filterInput, setfilterInput] = useState("");
  const [brandsLoader, setbrandsLoader] = useState(false);
  const [mailLoader, setmailLoader] = useState(false);
  const params = useParams();

  const history = useHistory();

  console.log(params.nametab, "czxczxcxz");
  useEffect(() => {
    setloading(true);
    setmailLoader(true);
    setgetLoctionName(params.id);
    downline(params.id);

    Brands(params.id);
    setstoreTab(params.nametab);
    return () => {};
  }, []);

  const handpushCustome = (e) => {
    setloading(true);
    setmailLoader(true);
    history.push(`/explorer/${e.email}/${storeTab}`);
    downline(e?.email);
    //handle(e?.affiliate_id);
    Brands(e.email);
  };

  const handlepush = (e) => {
    // setloading(true);
    history.push(`/explorer/${e.email}/${storeTab}`);
    // handle(e?.affiliate_id);
    setSelectedUser(e);
    handleCutomerForTab(e?.affiliate_id, storeTab);
    // downline(e?.email)
    Brands(e.email);
    // setstoreAffilate(selectedUser?.affiliate_id)
  };

  const customerDataFuntion = async (e) => {
    await history.push(`/explorer/${selectedUser?.email}/${e}`);
    setfilterInput("");
    await setstoreTab(e);
    if (e == "brands") {
      // Brands(params.id);
    } else if (e == "transactions") {
    } else {
      await SelectedForTabCustom(selectedUser?.affiliate_id, e);
    }
  };
  const  capitalizeFirstLetter=(string)=> {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  const SelectedForTabCustom = (e, g) => {
    // alert(params.nametab)
    // setbrandsLoader(true);
    let obj = {};

    // setapiloading(true);
    setgetEmailData([]);
    setmailLoader(true);
    GetInformationofcards(e).then((response) => {
      let result =
        g == "customers"
          ? response?.data?.customers?.users
          : response?.data?.brokers?.users;
      // setcustomersTotal(response?.data?.customers?.length);
      // setBrokerTotal(response?.data?.brokers?.length);
      setgetEmailData(result);
      // setbrandsLoader(false);
      setmailLoader(false);
      //   setapiloading(false);
      console.log("psdofsdf", result);
    });

    console.log("sqsc", obj);
  };

  const Brands = (e) => {
    //  alert(params.nametab)
    // setloading(true);
    let obj = {};

    // setapiloading(true);
    // setgetEmailData([]);
    GetInformationofBrands(e).then((response) => {
      let result = response?.data?.data?.bankers;

      setBrandStore(result);
      // setloading(false);
      //   setapiloading(false);
      console.log("123433543", result);
    });

    console.log("sqsc", obj);
  };

  const handleCutomerForTab = (e, g) => {
    // alert(params.nametab)
    setbrandsLoader(true);
    let obj = {};

    // setapiloading(true);
    setgetEmailData([]);
    setmailLoader(true);
    GetInformationofcards(e).then((response) => {
      let result =
        g == "customers"
          ? response?.data?.customers?.users
          : response?.data?.brokers?.users;
      setcustomersTotal(response?.data?.customers?.length);
      setBrokerTotal(response?.data?.brokers?.length);
      setgetEmailData(result);
      setbrandsLoader(false);
      setmailLoader(false);
      //   setapiloading(false);
      console.log("psdofsdf", result);
    });

    console.log("sqsc", obj);
  };

  const downline = (e) => {
    setloading(true);
    GetInformationofDownline(e).then((response) => {
      let result = response?.data;
      // setSelectedUser(result?.user)
      // handle(result?.user);
      // setDisplayList(result);
      if (result?.status) {
        console.log("123dsdsfd", result?.data);
        handleSider(e, result?.data);
      } else {
        handleSider(e, []);
      }
      // setSelectedUser(add)
      //   setapiloading(false);
    });
  };

  const handleSider = (e, g) => {
    // alert();

    // setapiloading(true);
    setloading(true);
    GetInformationofSider(e).then((response) => {
      let result = response?.data;
      setSelectedUser(result?.user);
      // handle(result?.user);
      // setDisplayList(result);
      if (result?.status) {
        handle(result?.user?.affiliate_id);
        let add = [result?.user, ...result?.uplines, ...g];
        setaffilateList(add);
        setlenghtofdata(add.length);
        setinnerDetail(add);
        setsearchNearByItems(add);
        // setloading(false);
        setmailLoader(false);
      }
      // setSelectedUser(add)
      //   setapiloading(false);
      console.log("qweqweqwe", result);
    });
  };
  console.log("sqsc", selectedUser);
  const handle = (e) => {
    // alert(params.nametab);
    setloading(true);
    let obj = {};
    setcustomersTotal("");
    setBrokerTotal("");
    setmailLoader(true);
    // setapiloading(true);
    setgetEmailData([]);
    GetInformationofcards(e).then((response) => {
      let result =
        params.nametab === "customers"
          ? response?.data?.customers?.users
          : response?.data?.brokers?.users;
      setcustomersTotal(response?.data?.customers?.length);
      setBrokerTotal(response?.data?.brokers?.length);
      setgetEmailData(result);
      setloading(false);
      setmailLoader(false);
      //   setapiloading(false);
      console.log("psdofsdf", result);
    });

    console.log("sqsc", obj);
  };
  const carouselRef = useRef();
  const carouselWrapperRef = useRef();

  const [itemHeight, setItemHeight] = useState(150);

  useLayoutEffect(() => {
    if (carouselWrapperRef.current) {
      const height = carouselWrapperRef.current.clientHeight;
      setItemHeight(height / 5 - 15);
    }
  }, [
    carouselWrapperRef.current,
    carouselWrapperRef.current ? carouselWrapperRef.current.clientHeight : 0,
  ]);

  useEffect(() => {
    if (affilateList) {
      const listLength = affilateList.length;
      const totalItems = 30;

      let list;
      let filteredArr;
      if (listLength > totalItems) {
        let currentIndex = affilateList.findIndex(
          (user) => user.email === selectedUser.email
        );

        currentIndex = currentIndex > 0 ? currentIndex : 0;
        let startPoint = currentIndex - totalItems / 2;
        startPoint = startPoint <= totalItems / 2 ? currentIndex : 0;
        let endPoint = currentIndex + totalItems;
        endPoint =
          startPoint > 0
            ? endPoint
            : endPoint + (totalItems / 2 - currentIndex);
        endPoint = endPoint <= listLength ? endPoint : listLength;

        // console.log('listLength', listLength);
        // console.log('currentIndex', currentIndex);
        // console.log('startPoint', startPoint);
        // console.log('endPoint', endPoint);

        list = affilateList.slice(startPoint, endPoint);

        const foundItem = list.find(
          (item) => item.email === selectedUser.email
        );
        // console.log('foundItem', foundItem);

        if (!foundItem) {
          list = [...list, affilateList[currentIndex]];
        }
      } else {
        const seen = new Set();
        setDisplayList([]);
        const extraList = searchNearByItems.slice(1, totalItems - listLength);

        list = [...affilateList, ...extraList];

        //  filteredArr = list.filter(el => {
        //   const duplicate = seen.has(el.affiliate_id);
        //   seen.add(el.affiliate_id);
        //   return !duplicate;
        // });
      }

      setDisplayList(list);
    }
  }, [affilateList, searchNearByItems]);

  useEffect(() => {
    if (displayList && selectedUser) {
      const index = displayList.findIndex(
        (user) => user.email === selectedUser.email
      );

      if (index > 0) {
        carouselRef.current.slickGoTo(index, true);
      }
    }
  }, [displayList]);

  const onSliderChange = (newIndex) => {
    if (displayList) {
      setSelectedUser(displayList[newIndex]);
    }
  };

  const containerScrollListener = (e) => {
    if (carouselRef.current) {
      if (e.deltaY < 0) {
        carouselRef.current.slickPrev();
      } else {
        carouselRef.current.slickNext();
      }
    }
  };
  console.log("lisxxzczxczxct", lenghtofdata);

  console.log("list", lenghtofdata);
  return (
    <>
      <Navbar />

      <div className="customsearchlayout">
        <div className="leftside">
          <div className="user-selected-view-wrapper">
            <div
              ref={carouselWrapperRef}
              className="user-carousel-wrapper"
              onWheel={containerScrollListener}
            >
              {/* {
                load
              } */}

              {loader ? (
                <>
                  {Array(15)
                    .fill(1)
                    .map((_, index) => (
                      <div
                        // key={item._id || item.email}
                        className="user-carousel-item-wrapper"
                        onClick={() => handlepush(item)}
                      >
                        <div className="user-carousel-item-container">
                          <div className={`user-carousel-item `}>
                            <Skeleton
                              className="ImageTextEmail"
                              width={55}
                              height={55}
                            />

                            {/* <ProfileAvatar
            avatar={item?.profile_img}
            name={item?.name}
            size={70}
            className="user-img"
          /> */}
                            <div className="user-details">
                              <Skeleton className="" width={340} height={10} />
                              <Skeleton className="" width={240} height={10} />

                              {/* <div className="user-name">{item?.name}</div>
            <div className="user-email">{item?.email}</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  <Slider
                    infinite
                    slidesToShow={lenghtofdata > 5 ? 5 : lenghtofdata}
                    vertical
                    verticalSwiping
                    centerMode
                    swipeToSlide
                    className="carousel-container"
                    draggable
                    arrows={false}
                    dots={false}
                    focusOnSelect
                    afterChange={onSliderChange}
                    centerPadding={60}
                  >
                    {/* <div className="carousel-container"> */}
                    {displayList?.map((item) => (
                      <div
                        key={item._id || item.email}
                        className="user-carousel-item-wrapper"
                        onClick={() => handlepush(item)}
                        style={{
                          width: "auto",
                        }}
                      >
                        <div className="user-carousel-item-container">
                          <div
                            className={`user-carousel-item ${
                              item?.email === selectedUser?.email
                                ? "active"
                                : ""
                            }`}
                          >
                            <ProfileAvatar
                              avatar={item?.profile_img}
                              name={item?.name}
                              size={70}
                              className="user-img"
                            />
                            <div className="user-details">
                              <div className="user-name">{item?.name}</div>
                              <div className="user-email">{item?.email}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* </div> */}
                  </Slider>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="listCustomereSection">
          <div
            className="InnerNamelist"
            onClick={() => customerDataFuntion("customers")}
            style={{ opacity: params.nametab == "customers" ? 1 : 0.2 }}
          >
            {brandsLoader ? (
              <div className="Namevalue">
                <Skeleton className="" width={40} height={30} />
              </div>
            ) : (
              <div className="Namevalue">{customersTotal}</div>
            )}

            <div
              className="NameTextList"
              style={{
                color: params.nametab == "customers" && "#CF1A38",
                fontWeight: params.nametab == "customers" && 700,
              }}
            >
              {params.nametab == "customers" && <img src={arrowside} />}{" "}
              Customers
            </div>
          </div>
          <div
            className="InnerNamelist"
            onClick={() => customerDataFuntion("affiliates")}
            style={{ opacity: params.nametab == "affiliates" ? 1 : 0.2 }}
          >
            {brandsLoader ? (
              <div className="Namevalue">
                <Skeleton className="" width={40} height={30} />
              </div>
            ) : (
              <div className="Namevalue">{BrokerTotal}</div>
            )}

            <div
              className="NameTextList"
              style={{
                color: params.nametab == "affiliates" && "#CF1A38",
                fontWeight: params.nametab == "affiliates" && 700,
              }}
            >
              {params.nametab == "affiliates" && <img src={arrowside} />}
              Affiliates
            </div>
          </div>
          <div
            className="InnerNamelist"
            onClick={() => customerDataFuntion("brands")}
            style={{ opacity: params.nametab == "brands" ? 1 : 0.2 }}
          >
            <div className="Namevalue">{BrandStore?.length}</div>
            <div
              className="NameTextList"
              style={{
                color: params.nametab == "brands" && "#CF1A38",
                fontWeight: params.nametab == "brands" && 700,
              }}
            >
              {params.nametab == "brands" && <img src={arrowside} />}
              Brands
            </div>
          </div>
          <div
            className="InnerNamelist"
            onClick={() => customerDataFuntion("transactions")}
            style={{ opacity: params.nametab == "transactions" ? 1 : 0.2 }}
          >
            <div className="Namevalue">--</div>
            <div
              className="NameTextList"
              style={{
                color: params.nametab == "transactions" && "#CF1A38",
                fontWeight: params.nametab == "transactions" && 700,
              }}
            >
              {params.nametab == "transactions" && <img src={arrowside} />}
              Transactions
            </div>
          </div>
        </div>

        {storeTab === "transactions" ? (
          <div className="rightSideCustom">
            <div className="commingSoon">Coming Soon</div>
          </div>
        ) : (
          <div className="rightSide">
            {loader ? (
              <h1>
                <Skeleton className="" width={240} height={30} />
              </h1>
            ) : (
              <>
                <h1>
                  {selectedUser?.name}’s {capitalizeFirstLetter(params.nametab)}
                </h1>

                {storeTab === "brands" ? (
                  <p>
                    Explore The Brands That {selectedUser?.name} Is Promoting
                  </p>
                ) : (
                  <p>Explore Who Is In {selectedUser?.name}’s NetworkChain™</p>
                )}
              </>
            )}
            <div className="inputSectionSearch">
              <input
                placeholder={`Search Through ${selectedUser?.name}’s ${params.nametab}...`}
                onChange={(e) => setfilterInput(e.target.value)}
                value={filterInput}
              />
              {/* <div className="LeftSideSectionInput">
              <img src={mail} />
            </div> */}
            </div>
            <div className="ImageSectionNetworkTab">
              {mailLoader ? (
                <>
                  {Array(30)
                    .fill(1)
                    .map((_, index) => {
                      return (
                        <div className="inntercards">
                          <div className="TextAlingCenterSearch">
                            <Skeleton
                              className="ImageTextEmail"
                              width={55}
                              height={55}
                            />
                            <Skeleton
                              className="alingskeleton"
                              width={40}
                              height={10}
                            />
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {storeTab === "brands" ? (
                    <>
                      {BrandStore?.filter((dT) =>
                        dT?.bankerDetails?.displayName
                          ?.toLowerCase()
                          ?.includes(filterInput?.toLowerCase())
                      )?.map((item) => {
                        console.log("wrcxx", item);
                        return (
                          <div className="inntercards">
                            <div className="TextAlingCenterSearch">
                              <img
                                className="ImageTextEmail"
                                src={item?.bankerDetails?.profilePicURL}
                              />

                              <p>{item?.bankerDetails?.displayName}</p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {getEmailData
                        ?.filter((dT) => dT?.name?.includes(filterInput))
                        ?.map((item) => {
                          return (
                            <div className="inntercards">
                              <div
                                className="TextAlingCenterSearch"
                                onClick={() => handpushCustome(item)}
                              >
                                <div className="ImageTextEmail">
                                  {" "}
                                  {item.name?.charAt(0)}
                                </div>
                                <p>{item?.name}</p>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </>
              )}
              <div className="bottNetworkTab">
                <img src={network} />
              </div>
            </div>
            <div></div>
          </div>
        )}
      </div>
    </>
  );
}
