import axios from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { APP_CODE, GX_API_ENDPOINT } from '../../configs';
import { useUserBalance } from '../../queryHooks';
import { formatterHelper, usdValueFormatter } from '../../utils';
import LocalStorageHelper from '../../utils/LocalStorageHelper';

const getIntrestData = async ({ queryKey }) => {
  const [_key, coin] = queryKey;
  const email = LocalStorageHelper.getAppEmail();

  const { data } = await axios.get(
    `${GX_API_ENDPOINT}/coin/iced/liquid/earnings/per/user/get`,
    {
      params: {
        email,
        coin,
        app_code: APP_CODE,
      },
    },
  );

  return data?.coins[0] || '';
};

const MoreInfo = ({ activeAsset }) => {
  const { data: userBalance } = useUserBalance();

  const { data: intrestData } = useQuery(
    [`intrest${activeAsset.coinSymbol}`, activeAsset.coinSymbol],
    getIntrestData,
  );

  const coinData = useMemo(() => {
    if (!userBalance) {
      return '';
    }

    const item = userBalance?.coins_data?.find(
      (x) => x.coinSymbol === activeAsset.coinSymbol,
    );

    return item;
  }, [userBalance, activeAsset]);

  return (
    <div className="vault-more-info-container">
      <div className="header-container">
        <div className="txn-balance item-header">More Information</div>
      </div>
      <div className="more-info-container">
        <div className="coin-name-container">
          <img src={activeAsset.coinImage} alt="" className="coin-image" />
          <div className="coin-name">{activeAsset.coinName}</div>
        </div>
        <div className="value-container">
          <div className="value-label">Balance:</div>
          <div className="value">
            {formatterHelper(coinData?.coinValue || 0, activeAsset.coinSymbol)}
          </div>
        </div>
        <div className="value-container">
          <div className="value-label">Balance (USD):</div>
          <div className="value">
            {usdValueFormatter.format(coinData?.coinValueUSD || 0)}
          </div>
        </div>
        <div className="value-container">
          <div className="value-label">Interest:</div>
          <div className="value">{intrestData?.interest_earned || '-'}</div>
        </div>
        <div className="value-container">
          <div className="value-label">Interest (USD):</div>
          <div className="value">
            {usdValueFormatter.format(intrestData?.interest_earned_usd || 0)}
          </div>
        </div>
        <div className="actions-list-container">
          {ACTIONS.map((item) => (
            <div key={item.title} className="col-4">
              <div className="action-item">
                <img src={item.icon} alt="" className="action-img" />
                <div className="action-title">{item.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;

const ACTIONS = [
  {
    title: 'Withdraw',
    icon: require('../../assets/images/vaultActions/withdraw.svg').default,
  },
  {
    title: 'Deposit',
    icon: require('../../assets/images/vaultActions/deposit.svg').default,
  },
  {
    title: 'Transfer',
    icon: require('../../assets/images/vaultActions/transfer.svg').default,
  },
  {
    title: 'Trade',
    icon: require('../../assets/images/vaultActions/trade.svg').default,
  },
  {
    title: 'Money Markets',
    icon: require('../../assets/images/vaultActions/money-market.svg').default,
  },
];
