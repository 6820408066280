/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  BrowserRouter,
} from 'react-router-dom';
import { BrokerAppContext } from './contexts/BrokerAppContext';
import BrokerEarningSection from './MobileViews/components/BrokerEarningSection';
import Sidebar from './MobileViews/components/Sidebar';
import AffilateBank from './MobileViews/pages/AffilateBank';
import Bot from './MobileViews/pages/Bot';
import Complans from './MobileViews/pages/Complans';
import MobileLogin from './MobileViews/pages/MobileLogin';
import Network from './MobileViews/pages/Network';
import Onhold from './MobileViews/pages/Onhold';
import LocalStorageHelper from './utils/LocalStorageHelper';

const MobileRoutes = () => {
  return (
    <>
      <BrowserRouter basename="apps/affiliate">
        <Check />
      </BrowserRouter>
    </>
  );
};
const Check = () => {
  const history = useHistory();

  const { isSidebar } = useContext(BrokerAppContext);

  if (isSidebar) {
    return <Sidebar />;
  }

  const LoginHandle = () => {
    const token = LocalStorageHelper.getAppToken();
    if (token) {
      return <Redirect to={'/network'} />;
    } else {
      history.push('/login');
    }
  };
  useEffect(() => {
    LoginHandle();
  }, []);
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to={'/login'} />;
        }}
      />
      <Route exact path="/login" component={MobileLogin} />
      <Route exact path="/affilatebank" component={AffilateBank} />
      <Route exact path="/network" component={Network} />
      <Route exact path="/complans" component={Complans} />
      <Route exact path="/support" component={Bot} />
      <Route exact path="/onhold" component={Onhold} />
    </Switch>
  );
};

export default MobileRoutes;
