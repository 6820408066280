import React, { Component } from 'react';
import { sideBarMenu, GX_API_ENDPOINT, APP_CODE } from '../configs';
import Axios from 'axios';
import LocalStorageHelper from '../utils/LocalStorageHelper';

export const BrokerAppContext = React.createContext();

class BrokerAppProvider extends Component {
  constructor(props) {
    super();

    this.state = {
      activeSideMenuGroup: '',
      activeSideNav: sideBarMenu[0].menus[0].title,
      activeMainNav: '',
      isMenuOpen: true,
      isNavCollapsed: false,
      brokerageValuation: 21214.23,
      coverChartData: {
        type: 'Candle',
        theme: 'White',
        timeScale: 'All',
      },
      stakedGxt: 0,
      sefEarned: 0,
      withdrawals: null,
      brokerBalance: 0,
      isMobile: false,
      profileImg: '',
      userFullName: '',
      walletCoinData: '',
      refresh: false,
      userApps: [],
      coinListObject: {},
      walletActiveCoin: '',
      isAdminLoggedIn: false,
      isLoading: false,
      searchNearByItems: [],
      isSidebar: false,
    };
  }

  componentDidMount() {
    this.getBrokerValuation();
    this.getUserData();
    window.addEventListener('resize', this.screenResizeListener);
    this.getWalletCoinData();
    this.getAllApps();
    this.getAdminStatus();
    this.screenResizeListener();
    // this.getSearchNearbyItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { refresh, isNavCollapsed, walletCoinData } = this.state;

    if (prevState.refresh !== refresh) {
      this.getWalletCoinData();
      this.getUserData();
      this.getWithdrawals();
      this.getBrokerBalances();
      this.getBrokerValuation();
      this.getAdminStatus();
    }

    if (prevState.isNavCollapsed !== isNavCollapsed && isNavCollapsed) {
      this.setActiveMenuGroup('');
    }

    if (prevState.walletCoinData !== walletCoinData && walletCoinData) {
      this.setCoinObjects();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.screenResizeListener);
  }

  getAdminStatus = () => {
    const isAdminLoggedIn = LocalStorageHelper.getIsAdminView();
    this.setState({ isAdminLoggedIn });
  };

  screenResizeListener = () => {
    this.setState({ isMobile: window.innerWidth <= 576 });
  };

  setActiveSideNav = activeSideNav => {
    this.setState({ activeSideNav });
  };

  setActiveMainNav = activeMainNav => {
    this.setState({ activeMainNav });
  };

  setMenuOpen = isMenuOpen => {
    this.setState({ isMenuOpen });
  };

  setActiveMenuGroup = activeSideMenuGroup => {
    this.setState({ activeSideMenuGroup });
  };

  setCoverChartData = coverChartData => {
    this.setState({ coverChartData });
  };

  setIsNavCollapsed = isNavCollapsed => {
    this.setState({ isNavCollapsed });
  };

  setWalletActiveCoin = walletActiveCoin => {
    this.setState({ walletActiveCoin });
  };

  setRefreshData = () => {
    const { refresh } = this.state;
    this.setState({ refresh: !refresh });
  };

  getBrokerValuation = () => { };

  setCoinObjects = () => {
    const { walletCoinData } = this.state;
    if (walletCoinData) {
      const coinObj = {};

      walletCoinData.forEach(coin => {
        coinObj[coin.coinSymbol] = coin;
      });

      this.setState({ coinListObject: coinObj });
    }
  };

  getAllApps = async () => {
    const email = LocalStorageHelper.getAppEmail();
    if (email) {
      try {
        const res = await Axios.get(
          `${GX_API_ENDPOINT}/gxb/apps/registered/user`,
          { params: { email } },
        );
        const { data } = res;
        if (data.status) {
          this.setState({ userApps: data.userApps || [] });
        }
      } catch (error) {
        console.log('Error on getting apps List', error);
      }
    }
  };

  getUserData = () => {
    const email = LocalStorageHelper.getAppEmail();

    if (email) {
      Axios.post(`${GX_API_ENDPOINT}/get_affiliate_data`, {
        email,
      })
        .then(res => {
          const { data } = res;

          const stakedGxt = data.staked_value || 0;
          const sefEarned = data.sef_coins_earned || 0;

          // console.log('getUserData', data);
          this.setState({
            stakedGxt,
            sefEarned,
            profileImg: data?.profile_img || '',
            userFullName: data?.name || '',
          });
        })
        .catch(error => {
          console.log('getUserDetails Error', error);
        });
    }
  };

  getWithdrawals = () => {
    const email = LocalStorageHelper.getAppEmail();
    const ethAddress = LocalStorageHelper.getETHAddress();

    if (email && ethAddress) {
      Axios.post(`${GX_API_ENDPOINT}/withdrawal_address`, {
        ref_eth: ethAddress,
        email,
      })
        .then(data => {
          // console.log(data.data);

          const withd = data.data || [];

          withd.sort((a, b) => a.ts - b.ts);

          this.setState({
            withdrawals: withd,
            // total_withdraw_amount: withd[0]
            //   ? parseFloat(withd[0].withdrawl_amt)
            //   : 0
          });
        })
        .catch(error => console.log('Error on getting withdraws', error));
    }
  };

  getBrokerBalances = () => {
    const email = LocalStorageHelper.getAppEmail();
    if (email) {
      Axios.get(`${GX_API_ENDPOINT}/coin/vault/gxb/balance`, {
        params: { email },
      })
        .then(resp => {
          const { data } = resp;
          // console.log('Resp', data);
          this.setState({
            brokerBalance: data.gxbroker_account_balance,
            gxbroker_balance: data.gxbroker_balance,
          });
        })
        .catch(e => {
          console.log(e.message);
        });
    }
  };

  getWalletCoinData = async () => {
    const profileId = LocalStorageHelper.getProfileId();

    if (profileId) {
      Axios.post(`${GX_API_ENDPOINT}/coin/vault/service/coins/get`, {
        app_code: APP_CODE,
        profile_id: profileId,
      })
        .then(resp => {
          const { data } = resp;

          // console.log('getWalletCoinData', data);

          if (data.status) {
            const balances = data.coins_data || [];

            balances.sort((a, b) => (a.type !== b.type ? -1 : 1));

            this.setState({ walletCoinData: balances });
          }
        })
        .catch(error => { });
    }
  };

  reRenderApp = () => {
    this.forceUpdate();
  };

  setIsLoading = isLoading => {
    this.setState({ isLoading });
  };
  setisSidebar = isSidebar => {
    this.setState({ isSidebar });
  }
  getSearchNearbyItems = () => {
    Axios.get(`${GX_API_ENDPOINT}/coin/vault/service/users/holdings/data/get`, {
      params: {
        app_code: APP_CODE,
        downlines_count: true,
        limit: 30,
      },
    })
      .then(({ data }) => {
        // console.log('Search Data', data);

        const affList = data.users || [];
        const parsedList = affList.map(item => ({
          ...item,
          name: item.userData?.name,
          profile_img: item.userData?.profile_img,
          email: item.userData?.email,
        }));
        this.setState({ searchNearByItems: parsedList });
      })
      .catch(error => {
        console.log('Error on getting affliate list', error);
      });
  };

  render() {
    const { children } = this.props;
    return (
      <BrokerAppContext.Provider
        value={{
          ...this.state,
          setActiveSideNav: this.setActiveSideNav,
          setActiveMainNav: this.setActiveMainNav,
          setMenuOpen: this.setMenuOpen,
          getBrokerValuation: this.getBrokerValuation,
          setActiveMenuGroup: this.setActiveMenuGroup,
          setCoverChartData: this.setCoverChartData,
          reRenderApp: this.reRenderApp,
          setActiveMainNavItems: this.setActiveMainNavItems,
          getWithdrawals: this.getWithdrawals,
          getBrokerBalances: this.getBrokerBalances,
          getWalletCoinData: this.getWalletCoinData,
          setRefreshData: this.setRefreshData,
          setIsNavCollapsed: this.setIsNavCollapsed,
          setWalletActiveCoin: this.setWalletActiveCoin,
          setIsLoading: this.setIsLoading,
          setisSidebar: this.setisSidebar,
        }}
      >
        {children}
      </BrokerAppContext.Provider>
    );
  }
}

export const { Consumer } = BrokerAppContext;
export default BrokerAppProvider;
