import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { APP_CODE, GX_API_ENDPOINT } from "../../configs";
import FilterOptionSelector from "./FilterOptionSelector";
import LandingSearchLayout from "./LandingSearchLayout";
import OptionSelector from "./OptionSelector";
import filter from "../../assets/images/filter.svg";
import { useParams, useHistory } from "react-router-dom";
import './landing.scss'
// filter
import  wactvh from '../../assets/images/wactvh.svg'

import  arrowmobile from '../../assets/images/arrowmobile.svg'
const LandingSearch = ({settoggleTrueProduct,toggleTrueProduct,setmobilelandingDetail,setEmailmobilelandingDetail}) => {
  const location = useLocation();
const history=useHistory()
  const [allUsersList, setAllUsersList] = useState();
  const [productList, setProductList] = useState();
  const [brandsList, setBrandsList] = useState();
  const [influencersList, setInfluencersList] = useState();

  const SEARCH_OPTION = [
    {
      title: "Affiliates",
      icon: require("../../assets/images/affliate-app-dark-logo.svg").default,
      placeholder: "Find An Affiliate...",
      list: allUsersList,
      name: "Find people who are already promoting brands.",
    },
    {
      title: "Products",
      icon: require("../../assets/images/products-search-icon.svg").default,
      placeholder: "Search For Endorsed Products...",
      list: productList,
      name: "Find products that are being promoted by affiliates.",
    },
    {
      title: "Brands",
      icon: require("../../assets/images/brands-search-icon.svg").default,
      placeholder: "Search For Endorsed Brands...",
      list: brandsList,
      name: "Find brands that are being promoted by affiliates.",
    },
    {
      title: "Influencers",
      icon: require("../../assets/images/influence-search-icon.svg").default,
      placeholder: "Find An Influencer...",
      list: influencersList,
      name: "Find people who are already promoting brands who have over 50k followers.",
    },
  ];

  const [activeSearchOption, setActiveSearchOption] = useState(
    
     SEARCH_OPTION[0],
  );
  const [CustomactiveSearchOption, setCustomactiveSearchOption] = useState(
    {}
 );
  const [activeSearchFilter, setActiveSearchFilter] = useState(
    FILTER_OPTION[0]
  );
  const [searchText, setSearchText] = useState("");
  const [isOptionPopupOpen, setIsOptionPopupOpen] = useState(false);
  const [isSearchLayoutOpen, setIsSearchLayoutOpen] = useState(false);
  const [isSearchFilterPopupOpen, setIsSearchFilterPopupOpen] = useState(false);
  const [isSearchAsList, setIsSearchAsList] = useState(true);

  useEffect(() => {
    console.log("activeSearchOption", CustomactiveSearchOption);
    if (CustomactiveSearchOption?.title == "Affiliates") {
      // alert()
      
    }

  }, [CustomactiveSearchOption]);

const openSearch=()=>{
 
}
useEffect(()=>{
  if(toggleTrueProduct)
  {
    setIsOptionPopupOpen(true)
  }
  else{
    setIsOptionPopupOpen(false)
  }
},[toggleTrueProduct])

  useEffect(()=>{
    setmobilelandingDetail(activeSearchOption)
    setEmailmobilelandingDetail(activeSearchFilter)
    if(toggleTrueProduct)
    {
      setIsOptionPopupOpen(false)
    }
    // settoggleTrueProduct(false)
  },[activeSearchOption])
  // useEffect(() => {
  //   axios
  //     .get("https://teller2.apimachine.io/admin/allBankers")
  //     .then((resp) => {
  //       const { data } = resp;
  //       if (data.status) {
  //         // console.log('data', data);

  //         const brands = (data.data || []).map((item) => ({
  //           ...item,
  //           name: item.displayName,
  //           profile_img: item.profilePicURL,
  //         }));

  //         setBrandsList(brands);
  //       } else {
  //         setBrandsList([]);
  //       }
  //     })
  //     .catch((error) => {
  //       setBrandsList([]);
  //     });

  //   axios
  //     .get(`${GX_API_ENDPOINT}/gxb/product/get`)
  //     .then(({ data }) => {
  //       const products = data?.products || [];
  //       // console.log('products', products);

  //       const parsedList = products.map((item) => ({
  //         ...item,
  //         name: item.product_name,
  //         profile_img: item.product_icon,
  //         email: item.product_code,
  //       }));

  //       setProductList(parsedList);
  //     })
  //     .catch((error) => {
  //       console.log("Error on getting products list", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (searchText && isSearchAsList) {
  //     setIsSearchLayoutOpen(true);
  //      setnavtoggle(true);
  //   }
  // }, [searchText, isSearchAsList]);

  // useEffect(() => {
  //   if (!isSearchLayoutOpen) {
  //     setSearchText("");
  //   }
  // }, [isSearchLayoutOpen]);

  // useEffect(() => {
  //   if (location.state?.openEdit) {
  //     setIsSearchLayoutOpen(true);
  //     setnavtoggle(true);
  //   }

  //   if (location.state?.openOptions) {
  //     setIsOptionPopupOpen(true);
  //   }
  //   if (location.state?.isOpenUser) {
  //     setnavtoggle(true);
  //     setIsSearchLayoutOpen(true);
  //   }
  // }, [location]);

  const onSearchClick = () => {
    // setIsSearchAsList(false);
    if (searchText) {
     
      history.push(`/explorer/${searchText}/customers`);

    }
  };

  return (
    <>



<div className="searchBox">
<div className="SearchTop">
          <div className="innerTextSearch">Search</div>
          <div className='togglecomp'> 
        <div className='toggle-icon-part'>
          <input className='tgl1'  type="checkbox"  
          // defaultChecked={id==checkedName&&true}
          //   checked={checkedName==id&&true} 
        // onClick={}
        id={"id"}
        /><label className="tgl2" for={"imd"}></label>
        </div>
        {/* <p style={{color: JSON.parse(localStorage.getItem('light'))? "#5F6163" : '#E7E7E7', fontSize:'8px', textAlign:'center', marginTop:'3px'}}>{check === 'off' ? "With Balances" : "All Vaults"}</p> */}
    </div>
          <div className="innerTextSearch" 
          style={{opacity:"0.1"}}
          >Filter</div>
          <div className="borderSearch"></div>
          <div className="labelTextSearch" onClick={() => setIsOptionPopupOpen(true)}>{activeSearchOption?.title}</div>
          <div className="innerTextSearch BySearch">By</div>
          <div className="labelTextSearch" onClick={()=>setIsSearchFilterPopupOpen(true)}>{activeSearchFilter.title}</div>
        </div>
        <div className="SeachBottom">
<input 
       type="text"
       className="inputBoxText"
       placeholder={`Find An ${activeSearchOption?.title} By ${activeSearchFilter.title}..|`}
       value={searchText}
       onChange={(e) => setSearchText(e.target.value)}
/>
<div className="SearchData" onClick={onSearchClick}>Search</div>
        </div>


</div>



<div className="watchlabel">
  <img src={wactvh}/>
  Watch Intro Webinar 
</div>


      <div className="">

        {/* <div className="SearchTop">
          <div>Search</div>

          <div>Filter</div>
          <div className="border"></div>
          <div className="labelTextSearch">Affiliates</div>
          <div>By</div>
          <div className="labelTextSearch">Email</div>
        </div> */}
        <div>
      
        {/* <div
          className="selected-option"
          data-tip={activeSearchOption.title}
          data-for="search-list"
        >




          <img
            src={filter}
            alt=""
            className="option-img"
           
          />
           </div> */}
          {/* <ReactTooltip
            id="search-list"
            place="bottom"
            type="dark"
            effect="solid"
            border
            borderColor="#e5e5e5"
            backgroundColor="#fff"
            textColor="#343C5B"
            className="tooltip"
          /> */}
       
        {/* {activeSearchOption?.title === 'Affiliates' && (
          <div
            className="selected-option"
            onClick={() => setIsSearchFilterPopupOpen(true)}
            data-tip={activeSearchFilter.title}
            data-for="search-filter"
          >
            <img src={activeSearchFilter.icon} alt="" className="option-img" />
            <ReactTooltip
              id="search-filter"
              place="bottom"
              type="dark"
              effect="solid"
              border
              borderColor="#e5e5e5"
              backgroundColor="#fff"
              textColor="#343C5B"
              className="tooltip"
            />
          </div>
        )} */}
        {/* <input
          type="text"
          className="search-input"
          placeholder="Find An Affiliate By Email..|"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        /> */}
        {/* <div
          className={`selected-option right ${
            isSearchAsList ? 'no-active' : 'active'
          }`}
          onClick={onSearchClick}
          data-tip="Search"
          data-for="Search"
        >
          <img
            src={require('../../assets/images/search-as-item.svg').default}
            alt=""
            className="option-img"
          />
          <ReactTooltip
            id="Search"
            place="bottom"
            type="dark"
            effect="solid"
            border
            borderColor="#e5e5e5"
            backgroundColor="#fff"
            textColor="#343C5B"
            className="tooltip"
          />
        </div> */}
        <div
          // className={`selected-option right ${
          //   isSearchAsList ? 'active' : 'no-active'
          // }`}
          // onClick={() => setIsSearchAsList(true)}
          data-tip="Filter"
          data-for="Filter"
        >
          {/* <div className="SeacrhSear" onClick={onSearchClick}>
            Searchm
          </div> */}
          {/* <img
            src={require('../../assets/images/search-as-list.svg').default}
            alt=""
            className="option-img"
          />
          <ReactTooltip
            id="Filter"
            place="bottom"
            type="dark"
            effect="solid"
            border
            borderColor="#e5e5e5"
            backgroundColor="#fff"
            textColor="#343C5B"
            className="tooltip"
          /> */}
        </div>
        <OptionSelector
          isOpen={isOptionPopupOpen}
          onClose={() => setIsOptionPopupOpen(false)}
          options={SEARCH_OPTION}
          activeSearchOption={activeSearchOption}
          setActiveSearchOption={setActiveSearchOption}
          CustomactiveSearchOption={CustomactiveSearchOption}
          setCustomactiveSearchOption={setCustomactiveSearchOption}
        />
        <FilterOptionSelector
          isOpen={isSearchFilterPopupOpen}
          onClose={() => setIsSearchFilterPopupOpen(false)}
          options={FILTER_OPTION}
          activeSearchFilter={activeSearchFilter}
          setActiveSearchOption={setActiveSearchFilter}
          CustomactiveSearchOption={CustomactiveSearchOption}
          setCustomactiveSearchOption={setCustomactiveSearchOption}
        />
      </div>
      </div>
      {/* <LandingSearchLayout
        isSearchLayoutOpen={isSearchLayoutOpen}
        toggleSearchType={() => setIsSearchAsList(!isSearchAsList)}
        isSearchAsList={isSearchAsList}
        activeSearchOption={activeSearchOption}
        activeSearchFilter={activeSearchFilter}
        CustomactiveSearchOption={CustomactiveSearchOption}
        setCustomactiveSearchOption={setCustomactiveSearchOption}
        
        setnavtoggle={setnavtoggle}
        onBack={() => {
          setIsSearchLayoutOpen(false);
          setnavtoggle(true);
        }}
        searchText={searchText}
        setSearchText={setSearchText}
        openOptionPopup={() => setIsOptionPopupOpen(true)}
        openFilterPopup={() => setIsSearchFilterPopupOpen(true)}
      /> */}

   
    </>
  );
};

export default LandingSearch;

const FILTER_OPTION = [
  {
    title: "Email",
    icon: require("../../assets/images/gmail-icon.svg").default,
    key: "email",
  },
  {
    title: "Username",
    icon: require("../../assets/images/filter-by-username-icon.svg").default,
    key: "username",
  },
  {
    title: "Name",
    icon: require("../../assets/images/filter-by-name.svg").default,
    key: "name",
  },
  {
    title: "Sponsor",
    icon: require("../../assets/images/filter-by-upline.svg").default,
    key: "upline",
  },
];
