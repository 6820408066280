import React from 'react';
import { toast } from 'react-toastify';
import { emailValidator } from '../../utils';

const TempLogin = ({
  emailId,
  setEmailId,
  tempPassword,
  setTempPassword,
  onNext,
}) => {
  const onNextClick = () => {
    if (!emailValidator(emailId)) {
      return toast.error('PLEASE ENTER A VALID EMAIL');
    }

    if (!tempPassword) {
      return toast.error('PLEASE ENTER YOUR TEMP PASSWORD');
    }

    onNext();
  };

  return (
    <div className="register-component">
      <h1 className="header">Step 1</h1>
      <h6 className="sub-header">
        Enter The Temporary Credentials You Got In Your Email
      </h6>
      <div className="form-container">
        <div className="input-container">
          <div
            className="input-action"
            style={{ marginLeft: 0, marginRight: 10 }}
          >
            {/* <img
              src={require('../../assets/images/email-icon.svg').default}
              alt=""
              className="input-action-icon"
            /> */}
          </div>
          <input
            type="email"
            className="input-field"
            placeholder="EMAIL"
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
          />
        </div>
        <div className="input-container">
          <div
            className="input-action"
            style={{ marginLeft: 0, marginRight: 10 }}
          >
            {/* <img
              src={require('../../assets/images/user-white.svg').default}
              alt=""
              className="input-action-icon"
            /> */}
          </div>
          <input
            type="password"
            className="input-field"
            placeholder="PASSWORD"
            value={tempPassword}
            onChange={(e) => setTempPassword(e.target.value)}
          />
        </div>
      </div>
      <div onClick={onNextClick} className="next-btn">
        Next
      </div>
    </div>
  );
};

export default TempLogin;
