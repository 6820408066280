import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import learnArticle from '../../../assets/images/learnArticle.svg';
import learnVideos from '../../../assets/images/learnVideos.svg';
import LearnVideoCard from './LearnVideoCard';

function ChatLearnShowContent({ category }) {
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    Axios.get(
      `https://fxagency.apimachine.io/video/category/?category=${category._id}`,
    ).then(({ data }) => {
      if (data.status) {
        setVideoList(data.data);

        // console.log('data', data);
      }
    });
  }, [category]);

  const [isVideo, setIsVideo] = useState(true);
  return (
    <div className="chatLearn">
      <div className="headCategory">
        <img src={category.thumbnail} alt="" className="thumb" />
        <div className="textContent">
          <div className="pubTitle">{category.title}</div>
          <div className="pubDesc">{category.cv}</div>
        </div>
      </div>
      <div className="contentType">
        <div className="contentTypeLabel">
          How Do You Want To Consume Content?
        </div>
        <div className="contentBtns">
          <div
            className={`btnCntntType ${isVideo}`}
            onClick={() => setIsVideo(true)}
          >
            <img src={learnVideos} alt="" className="cntntIcon" />
            Videos
          </div>
          <div
            className={`btnCntntType ${!isVideo}`}
            onClick={() => setIsVideo(false)}
          >
            <img src={learnArticle} alt="" className="cntntIcon" />
            Articles
          </div>
        </div>
      </div>
      <div className="total">
        Total Of {videoList.length} Videos In This Playlist
      </div>
      {isVideo ? (
        <Scrollbars className="categoryListVideo">
          {videoList.map((video, index) => (
            <LearnVideoCard key={index} video={video} />
          ))}
        </Scrollbars>
      ) : (
        ''
      )}
    </div>
  );
}

export default ChatLearnShowContent;
