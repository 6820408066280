import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const CreatePassword = ({ passwordInput, setPasswordInput, onNext }) => {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);

  useEffect(() => {
    const capRegex = new RegExp(/^.*[A-Z].*/);
    const numRegex = new RegExp(/^.*[0-9].*/);
    const speRegex = new RegExp(/^.*[!@#$%^&*()+=].*/);

    const password = passwordInput;

    setIsPasswordValid(
      password.length >= 6 &&
        capRegex.test(password) &&
        numRegex.test(password) &&
        speRegex.test(password),
    );

    return () => {};
  }, [passwordInput]);

  useEffect(() => {
    if (isPasswordValid) {
      setIsPasswordConfirmed(confirmPassword === passwordInput);
    } else {
      setIsPasswordConfirmed(false);
    }
  }, [confirmPassword, isPasswordValid, passwordInput]);

  const onNextClick = () => {
    if (!isPasswordValid) {
      return toast.error(
        'PASSWORD SHOULD CONTAIN\n1. 6 Letters\n2. Atleast One Uppercase Character\n3. A Numeric Character\n4. A Special Character',
      );
    }

    if (!isPasswordConfirmed) {
      return toast.error('PASSWORDS DOES NOT MATCH');
    }

    onNext();
  };

  return (
    <div className="register-component">
      <h1 className="header">Great Job</h1>
      <h6 className="sub-header">Now Create A New Password</h6>
      <div className="form-container">
        <div className="input-container">
          <div
            className="input-action"
            style={{ marginLeft: 0, marginRight: 10 }}
          >
            {/* <img
              src={require('../../assets/images/padlock-white.svg').default}
              alt=""
              className="input-action-icon"
            /> */}
          </div>
          <input
            type="password"
            className="input-field"
            placeholder="Enter Password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
          <div
            className="validator"
            style={{ backgroundColor: isPasswordValid ? 'green' : '#d80027' }}
          />
        </div>
        <div className="input-container">
          <div
            className="input-action"
            style={{ marginLeft: 0, marginRight: 10 }}
          >
            {/* <img
              src={require('../../assets/images/padlock-white.svg').default}
              alt=""
              className="input-action-icon"
            /> */}
          </div>
          <input
            type="password"
            className="input-field"
            placeholder="Confirm Your Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div
            className="validator"
            style={{
              backgroundColor: isPasswordConfirmed ? 'green' : '#d80027',
            }}
          />
        </div>
      </div>
      <div onClick={onNextClick} className="next-btn">
        Next
      </div>
    </div>
  );
};

export default CreatePassword;
