import CloseBtn from './CloseBtn';

const ExtraRatings = ({
  extraRatings,
  setExtraRatings,
  addintionalLinks,
  setAddintionalLinks,
  noLinks,
}) => {
  const onItemClick = (item) => {
    const ratings = [...extraRatings];

    const isItemAlreadyExists = ratings.findIndex(
      (x) => x.title === item.title,
    );

    if (isItemAlreadyExists >= 0) {
      ratings.splice(isItemAlreadyExists, 1);
    } else {
      ratings.push({ ...item, rating: '0' });
    }
    setExtraRatings(ratings);
  };

  const isQualityActive = (quality) => {
    const isItemAlreadyExists = extraRatings.findIndex(
      (x) => x.title === quality,
    );

    return isItemAlreadyExists >= 0;
  };

  const setRating = (rating, index) => {
    try {
      const ratings = [...extraRatings];

      if (parseFloat(rating) > 10) {
        ratings[index].rating = 10;
      } else if (parseFloat(rating) < 0) {
        ratings[index].rating = 0;
      } else {
        ratings[index].rating = rating;
      }

      setExtraRatings(ratings);
    } catch (error) {}
  };

  const updateAddintionalLinks = (link, pos) => {
    try {
      const links = [...addintionalLinks];

      links[pos] = link;

      setAddintionalLinks(links);
    } catch (error) {}
  };

  const onPaste = async (pos) => {
    try {
      const copiedText = await navigator.clipboard.readText();

      updateAddintionalLinks(copiedText.trim(), pos);
    } catch (error) {}
  };

  const getItemIcon = (itemName) => {
    const item = RATING_ITEMS.find((x) => x.title === itemName);

    return item?.icon;
  };

  return (
    <>
      <div className="scroll-item">
        <div className="title">
          Select The Categories For Which You Want To Review The Product
          (Minimum 3)
        </div>
        <div className="app-list-container">
          {RATING_ITEMS.map((item) => (
            <div
              key={item.title}
              className={`app-item ${
                isQualityActive(item.title) ? 'active' : ''
              }`}
              onClick={() => onItemClick(item)}
            >
              <div className="icon-container">
                {isQualityActive(item.title) && <CloseBtn size={15} />}
                <img
                  src={item.icon}
                  alt=""
                  className="icon"
                  style={{ borderRadius: 0 }}
                />
              </div>
              <div className="app-name">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="scroll-item mt-0">
        <div className="title">
          Please Submit Ratings For Each Category Selected Above
        </div>
        <div className="ratings-items-container">
          {extraRatings?.length > 0 ? (
            extraRatings.map((item, index) => (
              <div key={item.title} className="rating-item">
                <div className="input-container">
                  <div className="unit rating-number">{index + 1}</div>
                  <div className="rating-name">
                    <img
                      src={item.icon || getItemIcon(item.title)}
                      alt=""
                      className="rating-icon"
                    />
                    {item.title}
                  </div>
                </div>
                <div className="input-container">
                  <input
                    type="number"
                    className="percentage-input rating-count"
                    value={item.rating}
                    onChange={(e) => setRating(e.target.value, index)}
                  />
                  <div className="unit rating-max">10</div>
                </div>
              </div>
            ))
          ) : (
            <div className="rating-item empty-item">
              <div className="input-container">
                <div className="unit rating-number">1</div>
                <div className="rating-name">Selected Category</div>
              </div>
              <div className="input-container">
                <input
                  type="number"
                  className="percentage-input rating-count"
                  value=""
                  placeholder="0.0"
                />
                <div className="unit rating-max">10</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {noLinks || (
        <div className="scroll-item">
          <div className="title">
            Add Any Additional Links You Want People To Click On
          </div>
          <div className="website-input">
            <input
              type="text"
              className="web-input"
              placeholder="Ex. www.youtube.com/review"
              value={addintionalLinks[0]}
              onChange={(e) => updateAddintionalLinks(e.target.value, 0)}
            />
            <img
              src={require('../../assets/images/paste-icon.svg').default}
              alt=""
              className="pate-icon"
              onClick={() => onPaste(0)}
            />
          </div>
          <div className="website-input">
            <input
              type="text"
              className="web-input"
              placeholder="Ex. www.youtube.com/review"
              value={addintionalLinks[1]}
              onChange={(e) => updateAddintionalLinks(e.target.value, 1)}
            />
            <img
              src={require('../../assets/images/paste-icon.svg').default}
              alt=""
              className="pate-icon"
              onClick={() => onPaste(1)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ExtraRatings;

const RATING_ITEMS = [
  {
    title: 'Product Quality',
    icon: require('../../assets/images/ratingIcons/qualitty-rating.svg')
      .default,
  },
  {
    title: 'Customer Service',
    icon: require('../../assets/images/ratingIcons/cutomer-service-rating.svg')
      .default,
  },
  {
    title: 'Durability',
    icon: require('../../assets/images/ratingIcons/durability-rating.svg')
      .default,
  },
  {
    title: 'Value For Money',
    icon: require('../../assets/images/ratingIcons/value-for-money-rating.svg')
      .default,
  },
  {
    title: 'Uniqueness',
    icon: require('../../assets/images/ratingIcons/uniquenes-rating.svg')
      .default,
  },
];
