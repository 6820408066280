import React from 'react';

const TYPES = [
  {
    key: 'crypto',
    name: 'Crypto',
    icon: require('../../assets/mobileassets/crypto.png').default,
  },
  {
    key: 'fiat',
    name: 'Forex',
    icon: require('../../assets/mobileassets/forex.png').default,
  },
];

export default function MoneyMarketTypeFooter({ type, setType }) {
  return (
    <>
      {TYPES.map((item, key) => {
        return (
          <div
            key={item.key}
            onClick={() => {
              setType(item.key);
            }}
            style={{
              borderColor: item.key === type ? '#08152D' : '#EBEBEB',
            }}
            className="exf_item2"
          >
            <img
              style={{
                height: 20,
                width: 20,
                marginRight: 4,
              }}
              src={item.icon}
              alt=""
            />
            {item.name}
          </div>
        );
      })}
    </>
  );
}
