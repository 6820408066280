import React, { useContext, useEffect, useState } from 'react'
import RefreshIcon from "../../assets/mobileassets/refresh-icon.svg";
import AddIcon from "../../assets/mobileassets/add-icon.svg";
import brokerAppLogo from '../../assets/images/app-icon.png';

import { Link, MemoryRouter, useHistory } from 'react-router-dom';
import { BrokerAppContext } from '../../contexts/BrokerAppContext';
import LocalStorageHelper from '../../utils/LocalStorageHelper';
import { nameFormatter } from '../../utils';
import { toast } from 'react-toastify';

const TABS = [
    {
        tabName: 'Affilate',
        tabIcon: require('../../assets/mobileassets/affiliate-logo.png').default,
        menu: [
            {
                name: 'Network',
                color: '#CF1A38',
                menuIcon: require('../../assets/mobileassets/network-chain.png'),
                route: 'network',
                params: { screen: 'Chain' },
            },

            {
                name: 'AffilateBank',
                menuIcon: require('../../assets/mobileassets/affilate-bank.png'),
                route: 'affilatebank',
                params: { screen: 'AffliateBank' },
            },
            {
                name: 'CompPlans',
                menuIcon: require('../../assets/mobileassets/comp-plans.png'),
                route: 'complans',
                params: { screen: 'CreateBrokerage' },
            },

        ],
    },

    {
        tabName: 'Support',
        tabIcon: require('../../assets/mobileassets/support.png').default,
        menu: [
            {
                name: 'Bot',
                menuIcon: require('../../assets/mobileassets/bots.png'),
                route: 'support',
                params: {
                    openBots: true,
                    openMessage: null,
                    openLearn: null,
                    openUserChat: null,
                },
            },

            {
                name: 'OnHold',
                menuIcon: require('../../assets/mobileassets/onhold.png'),
                color: '#6AE7C2',
                route: 'onhold',
            },
        ],
    },

];


export default function Sidebar() {

    const [activetab, SetActiveTab] = useState([TABS[0]]);
    const { isSidebar,
        setisSidebar,
        profileImg,
        userFullName,
        setRefreshData
    } = useContext(BrokerAppContext);

    useEffect(() => {
        SetActiveTab(TABS[0]);

    }, []);
    const logOutHandler = () => {
        LocalStorageHelper.setAppDataOnLogOut();
        window.location.replace('/');
    };
    const history = useHistory();

    return (
        <div
            className={isSidebar ? 'sidebar sidebar2 ' : 'sidebar '} >
            <div
                className="navIcons"
            >
                <img src={profileImg || brokerAppLogo}
                    className="navimg"
                />
                {
                    TABS.map((item, key) => {

                        return (
                            <img src={item.tabIcon}
                                key={key}
                                onClick={() => {
                                    SetActiveTab(item);
                                }}

                                className={item.tabName === activetab.tabName ? "navimg navactiveimg" : 'navimg'}
                            />
                        )
                    })
                }
                <img src={require('../../assets/mobileassets/logout-icon.svg').default}

                    onClick={() => {
                        logOutHandler();
                    }}

                    className='logout_icon'
                />



            </div>
            <div
                className="navdetails"
            >
                <div
                    className='navcover'
                >
                    <div className='usernamewrap'>
                        <div className='username'>{nameFormatter(userFullName)}</div>
                        <div className='navcopy'>Copy NetworkSync</div>


                    </div>
                    <div>
                        <img src={RefreshIcon} onClick={() => {
                            setRefreshData();
                            toast.success('Latest Data Added');

                        }} />
                        <img style={{ marginLeft: 20 }} src={AddIcon} />
                    </div>

                </div>

                {
                    activetab.menu &&


                    activetab.menu.map((item, key) => {
                        return (
                            <Link key={key}
                                // onClick={() => {
                                //     history.push(item.route);
                                //     setisSidebar(false);
                                // }}
                                to={item.route}
                                onClick={() => {

                                    setisSidebar(false);

                                }}
                                className="menubox"

                            >
                                <img src={(item.menuIcon).default}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        marginRight: 10
                                    }} />

                                <div className='text' style={{
                                    color: item.color ? item.color : 'black'
                                }}>
                                    {item.name}
                                </div>
                            </Link>
                        )
                    })

                }


            </div>

        </div>
    )
}



