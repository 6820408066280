import React from 'react';

const ProfileAvatar = ({
  avatar,
  name,
  size,
  bgColor,
  className = '',
  onClick,
}) => {
  const getPlaceholderText = () => {
    try {
      const splittedName = name?.toUpperCase().split(' ') || [''];

      const initials = splittedName.map((x) => x[0]);
      return initials.join('').substring(0, 2).trim();
    } catch (error) {
      return '';
    }
  };

  return (
    <>
      {avatar ? (
        <img
          style={{ width: size, height: size }}
          alt=""
          className={`profile-avatar ${className}`}
          src={avatar}
          onClick={onClick}
        />
      ) : (
        <div
          style={{ width: size, height: size, fontSize: size / 2.5 }}
          className={`profile-avatar-placeholder ${className}`}
          onClick={onClick}
        >
          {getPlaceholderText()}
        </div>
      )}
    </>
  );
};

export default ProfileAvatar;
