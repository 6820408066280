import axios from "axios";
import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import LandingSearch from "../components/LandingSearch";
import Typer from "../components/Typer";
import { GX_API_ENDPOINT } from "../configs";
import SiteLayout from "../layouts/SiteLayout";
import NavBar from "../components/NavItem";
import Banner from "../components/Banner";
import { VaultContext } from "../contexts/VaultContext";
import CountUp from "react-countup";
import { useHistory, useLocation } from "react-router-dom";

import android from "../assets/images/logos/android.svg";
import iosImage from "../assets/images/logos/ios.svg";
import rightSidearrow from "../assets/images/rightSidearrow.svg";
import viralverse from "../assets/images/landing-features/viralverse.svg";
import viralverse1 from "../assets/images/landing-features/viralversemobile.svg";
import networkChain from "../assets/images/logos/networkChain.svg";
// import affiliate from "../assets/images/logos/affiliate.svg";
// import engagement from "../assets/images/logos/engagement.svg";
// import connection from "../assets/images/logos/connection.svg";
import ai from "../assets/images/logos/ai.svg";
import viral from "../assets/images/logos/viral.svg";
import retired from "../assets/images/logos/retired.svg";
import tradestream from "../assets/images/logos/tradestream.svg";
import signals from "../assets/images/logos/signals.svg";
import accountants from "../assets/images/logos/accountants.svg";
import web3 from "../assets/images/logos/web3.svg";
import investmentbot from "../assets/images/logos/investmentbot.svg";
import networklogo from "../assets/images/network.svg";
import arrowmobile from "../assets/images/arrowmobile.svg";
import global from "../assets/images/global.png";
import affliate from "../assets/images/affliate.svg";
import connection from "../assets/images/connection.svg";
import engagement from "../assets/images/engagement.svg";
import foriphone from "../assets/images/foriphone.svg";
import forandroid from "../assets/images/forandroid.svg";
import { sign } from "jsonwebtoken";
import Dropdown from "../components/DropDown/dropdown";

import virula from "../assets/virula.svg";
const SiteLadingPage = () => {
  const [latestQuickCommit, setLatestQuickCommit] = useState();
  const [navtoggle, setnavtoggle] = useState(false);
  const [mobilelandingDetail, setmobilelandingDetail] = useState([]);
  const [EmailmobilelandingDetail, setEmailmobilelandingDetail] = useState([]);
  const [toggleTrueProduct, settoggleTrueProduct] = useState(false);
  const [mobsize, setmobsize] = useState(0);

  const {
    isHamMenuOpen,
    setIsHamMenuOpen,
    selectedMenu,
    setSelectedMenu,
    landingpageData,
    selected,
    setSelected,
    mobenterverse,
    setmobenterverse,
  } = useContext(VaultContext);

  const { pathname } = useLocation();

  useEffect(() => {
    axios
      .get(`${GX_API_ENDPOINT}/gxb/apps/mobile/app/links/logs/get`, {
        params: { app_code: "broker_app" },
      })
      .then(({ data }) => {
        // console.log('App Logs', data);

        const app = data.logs[0] || null;

        setLatestQuickCommit(app);
      })
      .catch((error) => {});
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setmobsize(window.innerWidth);
      // if(window.innerWidth > 900){
      //   setIsHamMenuOpen(false)

      // }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [mobsize]);

  useEffect(() => {
    if (mobsize > 900 && isHamMenuOpen === true) {
      setIsHamMenuOpen(false);
      location.reload();
    }
  }, [isHamMenuOpen, mobsize]);

  useEffect(() => {
    if (mobsize <= 900 && pathname.includes("downloadapps")) {
      setmobenterverse(true);
    }
  }, [pathname]);

  const onAppInstallerClick = (isAndroid) => {
    if (latestQuickCommit) {
      const link = isAndroid
        ? latestQuickCommit?.android_app_link || ""
        : latestQuickCommit?.ios_app_link || "";
      const win = window.open(link, "_blank");
      if (win != null) {
        win.focus();
      }
    } else {
      axios
        .get(`${GX_API_ENDPOINT}/gxb/apps/mobile/app/links/logs/get`, {
          params: { app_code: "broker_app" },
        })
        .then(({ data }) => {
          // console.log('App Logs', data);

          const app = data.logs[0] || null;

          const link = isAndroid
            ? app?.android_app_link || ""
            : app?.ios_app_link || "";
          const win = window.open(link, "_blank");
          if (win != null) {
            win.focus();
          }
        })
        .catch((error) => {});
    }
  };

  const history = useHistory();

  return (
    <>
      {/* <Banner/> */}
      {navtoggle ? "" : <NavBar />}

      {selected ? (
        <>
          <div className="web-apps">
            {/* <div
              className="each-app"
              style={{ background: "rgba(61, 67, 148, 0.03)" }}
              onClick={() => {
                window.open("https://aiprowallet.com", "_blank");
              }}
            >
              <img src={ai} alt="AiProWallet" />
              <div className="appHeading">AiProWallet</div>
              <div>AI Enabled Wallet</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(207, 26, 56, 0.02)" }}
              onClick={() => {
                window.open("https://viral.group", "_blank");
              }}
            >
              <img src={viral} alt="Viral" />
              <div className="appHeading">Viral</div>
              <div>Affiliate Marketing Ecosystem</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(68, 194, 244, 0.03)" }}
              onClick={() => {
                window.open("https://retired.app", "_blank");
              }}
            >
              <img src={retired} alt="Retired App" />
              <div className="appHeading">Retired App</div>
              <div>Ai Enabled Retirement Planner</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(13, 171, 209, 0.03)" }}
              onClick={() => {
                window.open("https://trade.stream", "_blank");
              }}
            >
              <img src={tradestream} alt="TradeStream" />
              <div className="appHeading">TradeStream</div>
              <div>Social Trading Terminal</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(48, 47, 47, 0.03)" }}
              onClick={() => {
                window.open("https://signals.app", "_blank");
              }}
            >
              <img src={signals} alt="Signals" />
              <div className="appHeading">Signals</div>
              <div>Social Network For Trade Ideas</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(26, 107, 180, 0.03)" }}
              onClick={() => {
                window.open("https://accountants.io", "_blank");
              }}
            >
              <img src={accountants} alt="Accountants" />
              <div className="appHeading">Accountants</div>
              <div>Streamline Your Accounting Practice</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(39, 43, 99, 0.03)" }}
              onClick={() => {
                window.open("https://web3today.io", "_blank");
              }}
            >
              <img src={web3} alt="Web3Today" />
              <div className="appHeading">Web3Today</div>
              <div>Crypto, AI, Blockchain News Platform</div>
            </div>
            <div
              className="each-app"
              style={{ background: "rgba(75, 91, 178, 0.03)" }}
              onClick={() => {
                window.open("https://investmentbots.com", "_blank");
              }}
            >
              <img src={investmentbot} alt="InvestmentBots" />
              <div className="appHeading">InvestmentBots</div>
              <div>Create Your Own Trading Bot</div>
            </div> */}
            <Dropdown />
          </div>
        </>
      ) : mobsize > 900 ? (
        <>
          {/* <SiteLayout> */}
          <div className="landing-wrapper">
            {isHamMenuOpen && (
              <div className="mobile-menu" style={{ right: "0" }}>
                <div className="menu-container">
                  <div
                    className="menuItm"
                    onClick={() => {
                      setSelectedMenu("Home");
                    }}
                    style={{
                      background:
                        selectedMenu === "Home" ? "rgba(0, 0, 0, 0.05)" : "",
                      fontWeight: selectedMenu === "Home" ? "600" : "500",
                    }}
                  >
                    Home
                  </div>

                  <div className="menuItm">Explorer</div>
                  <div className="menuItm">For Affiliates</div>
                  <div className="menuItm">For Influencers</div>
                  <div className="menuItm">For Brands</div>
                  <div className="menuItm">For Content Creators</div>
                </div>
                <div className="app-container">
                  <div className="appIcon">
                    <img src={affiliate} alt="affiliate" />
                  </div>
                  <div className="appLinks">
                    <div style={{ borderRight: "0.5px solid #e5e5e5" }}>
                      <img src={android} alt="android" />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <img src={iosImage} alt="ios" />
                    </div>
                  </div>
                </div>
                <div className="app-container">
                  <div className="appIcon">
                    <img src={connection} alt="connection" />
                  </div>
                  <div className="appLinks">
                    <div style={{ borderRight: "0.5px solid #e5e5e5" }}>
                      <img src={android} alt="android" />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <img src={iosImage} alt="ios" />
                    </div>
                  </div>
                </div>
                <div className="app-container">
                  <div className="appIcon">
                    <img src={engagement} alt="engagement" />
                  </div>
                  <div className="appLinks">
                    <div style={{ borderRight: "0.5px solid #e5e5e5" }}>
                      <img src={android} alt="android" />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <img src={iosImage} alt="ios" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="landing-container">
              <div className="details-container">
                <div className="header-text">
                  ENTER THE <img src={virula} />
                  <br />
                </div>
                <div className="header-text1">
                  EarnGPT
                  <br />
                </div>
                <div className="joinGrow">
                  POWERED BY <img src={networkChain} alt="networkChain" />
                </div>
                <LandingSearch
                  setnavtoggle={setnavtoggle}
                  setmobilelandingDetail={setmobilelandingDetail}
                  setEmailmobilelandingDetail={setEmailmobilelandingDetail}
                  settoggleTrueProduct={settoggleTrueProduct}
                  toggleTrueProduct={toggleTrueProduct}
                />
              </div>
              <div className="feature-img-container" />
            </div>
            <div className="landing-footer-wrapper">
              <img
                src={viralverse}
                alt="viralverse"
                className="enter"
                onClick={() => {
                  window.open("https://verse.viral.group", "_blank");
                }}
              />
            </div>
            <div
              className="mobFooter"
              onClick={() => {
                window.open("https://verse.viral.group", "_blank");
              }}
            >
              <img src={viralverse1} alt="viralverse1" />
            </div>
          </div>
          {/* </SiteLayout> */}
          <div className="HeaderMobileDashboard">
            <h1>Explorer</h1>
            <img src={networklogo} />
            <div className="SeacahMobileSectionDashboard">
              <div className="searchIndex">Search For</div>
              <div
                className="mobileSearch"
                onClick={() => settoggleTrueProduct(!toggleTrueProduct)}
              >
                <div className="dropDownMobile">
                  <img src={mobilelandingDetail?.icon} />
                  {mobilelandingDetail?.title}
                </div>
                <img src={arrowmobile} />
              </div>

              <div className="searchIndex">Search For</div>
              <div className="mobileSearch">
                <div className="dropDownMobile">
                  <img src={EmailmobilelandingDetail?.icon} />
                  {EmailmobilelandingDetail?.title}
                </div>
                <img src={arrowmobile} />
              </div>
              <input placeholder="Find An Affiliate By Email..|" />
              <div className="togledivSection">
                <div className="togglecomp">
                  <div className="toggle-icon-part">
                    <input
                      className="tgl1"
                      type="checkbox"
                      // defaultChecked={id==checkedName&&true}
                      //   checked={checkedName==id&&true}
                      // onClick={}
                      id={"id"}
                    />
                    <label className="tgl2" for={"imd"}></label>
                  </div>
                  {/* <p style={{color: JSON.parse(localStorage.getItem('light'))? "#5F6163" : '#E7E7E7', fontSize:'8px', textAlign:'center', marginTop:'3px'}}>{check === 'off' ? "With Balances" : "All Vaults"}</p> */}
                </div>

                <div className="searhFromMobile">Search</div>
              </div>
            </div>
          </div>
          <div className="tridthSection"></div>
          <div className="SecongLayout">
            <div className="golbalimg">
              <img src={global} />
            </div>
            <div className="rightSide">
              <h1>
                Discover why thousands of influencers, affiliates, and
                affiliates chose{" "}
                <span style={{ fontWeight: "700" }}>Viral</span> to build their
                business
              </h1>
              <div className="cardlayoutTop">
                <div className="cardsSecondLayout">
                  <h3>
                    {" "}
                    <CountUp end={landingpageData?.appscount} />{" "}
                  </h3>
                  <p>Marketplaces</p>
                </div>
                <div className="cardsSecondLayout">
                  <h3>
                    <CountUp
                      end={landingpageData?.usercount / 1000}
                      decimals={2}
                    />{" "}
                    K
                  </h3>
                  <p>Affiliates</p>
                </div>

                <div
                  className="cardsSecondLayout"
                  style={{ marginBottom: "0px" }}
                >
                  <h3>
                    <CountUp end={landingpageData?.commissions?.usersCount} />
                  </h3>
                  <p>Influencers</p>
                </div>

                <div
                  className="cardsSecondLayout"
                  style={{ marginBottom: "0px" }}
                >
                  <h3>
                    $
                    <CountUp
                      end={landingpageData?.commissions?.totalComPaid / 1000}
                      decimals={2}
                    />{" "}
                    K
                  </h3>
                  <p>Paid To Affiliates</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : mobsize <= 900 ? (
        <>
          <div className="landing-wrapper">
            {isHamMenuOpen ? (
              <div className="mobile-menu" style={{ right: "0" }}>
                <div className="menu-container">
                  <div
                    className="menuItm"
                    onClick={() => {
                      setmobenterverse(false);
                      setSelectedMenu("Home");
                      setIsHamMenuOpen(false);
                      history.push("/explorer");
                    }}
                    style={{
                      background:
                        selectedMenu === "Home" ? "rgba(0, 0, 0, 0.05)" : "",
                      fontWeight: selectedMenu === "Home" ? "600" : "500",
                    }}
                  >
                    Home
                  </div>
                  <div className="menuItm">Explorer</div>
                  <div className="menuItm">For Affiliates</div>
                  <div className="menuItm">For Influencers</div>
                  <div className="menuItm">For Brands</div>
                  <div className="menuItm">For Content Creators</div>
                </div>
                {/* <div className="app-container">
                  <div className="appIcon">
                    <img src={affiliate} alt="affiliate" />
                  </div>
                  <div className="appLinks">
                    <div style={{ borderRight: "0.5px solid #e5e5e5" }}>
                      <img src={android} alt="android" />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <img src={iosImage} alt="ios" />
                    </div>
                  </div>
                </div>
                <div className="app-container">
                  <div className="appIcon">
                    <img src={connection} alt="connection" />
                  </div>
                  <div className="appLinks">
                    <div style={{ borderRight: "0.5px solid #e5e5e5" }}>
                      <img src={android} alt="android" />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <img src={iosImage} alt="ios" />
                    </div>
                  </div>
                </div>
                <div className="app-container">
                  <div className="appIcon">
                    <img src={engagement} alt="engagement" />
                  </div>
                  <div className="appLinks">
                    <div style={{ borderRight: "0.5px solid #e5e5e5" }}>
                      <img src={android} alt="android" />
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <img src={iosImage} alt="ios" />
                    </div>
                  </div>
                </div> */}
                <div className="mob-button">Get Started</div>
              </div>
            ) : !mobenterverse ? (
              <>
                <div className="landing-container">
                  <div className="details-container">
                    <div className="header-text">
                      Introducing EarnGPT
                      <br />
                    </div>
                    <div className="header-text1">
                      EarnGPT
                      <br />
                    </div>
                    <div className="joinGrow">
                      POWERED&nbsp;BY&nbsp;
                      <img src={networkChain} alt="networkChain" />
                    </div>
                    <LandingSearch
                      setnavtoggle={setnavtoggle}
                      setmobilelandingDetail={setmobilelandingDetail}
                      setEmailmobilelandingDetail={setEmailmobilelandingDetail}
                      settoggleTrueProduct={settoggleTrueProduct}
                      toggleTrueProduct={toggleTrueProduct}
                    />
                  </div>
                  <div className="feature-img-container" />
                </div>
                <div className="landing-footer-wrapper">
                  <img
                    src={viralverse}
                    alt="viralverse"
                    className="enter"
                    onClick={() => {
                      window.open("https://verse.viral.group", "_blank");
                    }}
                  />
                </div>
                <div
                  className="mobFooter"
                  onClick={() => {
                    setmobenterverse(true);
                    history.push("/downloadapps");
                  }}
                >
                  <img src={viralverse1} alt="viralverse1" />
                </div>
                <div className="HeaderMobileDashboard">
                  <h1>Explorer</h1>
                  <img src={networklogo} />
                  <div className="SeacahMobileSectionDashboard">
                    <div className="searchIndex">Search For</div>
                    <div
                      className="mobileSearch"
                      onClick={() => settoggleTrueProduct(!toggleTrueProduct)}
                    >
                      <div className="dropDownMobile">
                        <img src={mobilelandingDetail?.icon} />
                        {mobilelandingDetail?.title}
                      </div>
                      <img src={arrowmobile} />
                    </div>

                    <div className="searchIndex">Search For</div>
                    <div className="mobileSearch">
                      <div className="dropDownMobile">
                        <img src={EmailmobilelandingDetail?.icon} />
                        {EmailmobilelandingDetail?.title}
                      </div>
                      <img src={arrowmobile} />
                    </div>
                    <input placeholder="Find An Affiliate By Email..|" />
                    <div className="togledivSection">
                      <div className="togglecomp">
                        <div className="toggle-icon-part">
                          <input
                            className="tgl1"
                            type="checkbox"
                            // defaultChecked={id==checkedName&&true}
                            //   checked={checkedName==id&&true}
                            // onClick={}
                            id={"id"}
                          />
                          <label className="tgl2" for={"imd"}></label>
                        </div>
                        {/* <p style={{color: JSON.parse(localStorage.getItem('light'))? "#5F6163" : '#E7E7E7', fontSize:'8px', textAlign:'center', marginTop:'3px'}}>{check === 'off' ? "With Balances" : "All Vaults"}</p> */}
                      </div>

                      <div className="searhFromMobile">Search</div>
                    </div>
                  </div>
                </div>
                <div className="tridthSection"></div>
                <div className="SecongLayout">
                  <div className="golbalimg">
                    <img src={global} />
                  </div>
                  <div className="rightSide">
                    <h1>
                      Discover why thousands of influencers, affiliates, and
                      affiliates chose{" "}
                      <span style={{ fontWeight: "700" }}>Viral</span> to build
                      their business
                    </h1>
                    <div className="cardlayoutTop">
                      <div className="cardsSecondLayout">
                        <h3>
                          {" "}
                          <CountUp end={landingpageData?.appscount} />{" "}
                        </h3>
                        <p>Marketplaces</p>
                      </div>
                      <div className="cardsSecondLayout">
                        <h3>
                          <CountUp
                            end={landingpageData?.usercount / 1000}
                            decimals={2}
                          />{" "}
                          K
                        </h3>
                        <p>Affiliates</p>
                      </div>

                      <div
                        className="cardsSecondLayout"
                        style={{ marginBottom: "0px" }}
                      >
                        <h3>
                          <CountUp
                            end={landingpageData?.commissions?.usersCount}
                          />
                        </h3>
                        <p>Influencers</p>
                      </div>

                      <div
                        className="cardsSecondLayout"
                        style={{ marginBottom: "0px" }}
                      >
                        <h3>
                          $
                          <CountUp
                            end={
                              landingpageData?.commissions?.totalComPaid / 1000
                            }
                            decimals={2}
                          />{" "}
                          K
                        </h3>
                        <p>Paid To Affiliates</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="all-box">
                <div className="each-box">
                  <div>
                    <img src={affliate} alt="" />
                  </div>
                  <div className="each-box-title">
                    Want To Be An Affiliate? Manage Your Business 24/7
                    <br />
                    With The Affiliate Specific Mobile App.
                  </div>
                  <div
                    className="store-box"
                    onClick={() =>
                      window.open(`${latestQuickCommit.ios_app_link}`, "_blank")
                    }
                  >
                    <img src={foriphone} alt="" />
                  </div>
                  <div
                    className="store-box"
                    onClick={() =>
                      window.open(
                        `${latestQuickCommit.android_app_link}`,
                        "_blank"
                      )
                    }
                  >
                    <img src={forandroid} alt="" />
                  </div>
                </div>
                <div
                  className="each-box"
                  style={{ borderTop: "7px solid #009EFC" }}
                >
                  <div>
                    <img src={connection} alt="" />
                  </div>
                  <div className="each-box-title">
                    Turn your followers into revenue by creating tokenized
                    <br />
                    paywalls for exclusive content.
                  </div>
                  <div
                    className="store-box"
                    style={{ cursor: "not-allowed", opacity: "0.3" }}
                  >
                    <img src={foriphone} alt="" />
                  </div>
                  <div
                    className="store-box"
                    style={{ cursor: "not-allowed", opacity: "0.3" }}
                  >
                    <img src={forandroid} alt="" />
                  </div>
                </div>
                <div
                  className="each-box"
                  style={{ borderTop: "7px solid #6669B0" }}
                >
                  <div>
                    <img src={engagement} alt="" />
                  </div>
                  <div className="each-box-title">
                    Convert your customers into affiliates by creating
                    <br />
                    CompPlans for all your products.
                  </div>
                  <div
                    className="store-box"
                    style={{ cursor: "not-allowed", opacity: "0.3" }}
                  >
                    <img src={foriphone} alt="" />
                  </div>
                  <div
                    className="store-box"
                    style={{ cursor: "not-allowed", opacity: "0.3" }}
                  >
                    <img src={forandroid} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
          }
        </>
      ) : (
        ""
      )}
    </>
  );
};


export default SiteLadingPage;


const TEXTS = [
  { text: "For Sales You Influence", color: "#5ABEA0" },
  { text: "For Products Your Endorse", color: "#F7931A" },
  { text: "For Brands You Promote", color: "#65A2D9" },
  { text: "For Introductions You Make", color: "#F63448" },
];
