import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../assets/animation/login-loading.json';
import { useWindowDimensions } from '../utils/CustomHooks';

const LoginLoadingAnimation = ({ size, style = {} }) => {
  const { height, width } = useWindowDimensions();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={style}>
      <Lottie
        options={defaultOptions}
        height={size || height * 0.5}
        width={size || height * 0.5}
      />
    </div>
  );
};

export default LoginLoadingAnimation;
