import React, { createContext, useState, useContext, useEffect } from "react";
import Axios from "axios";
import { BrokerAppContext } from "./BrokerAppContext";
import axios from "axios";

export const VaultContext = createContext();

function VaultContextProvider({ children }) {
  const { profileId } = useContext(BrokerAppContext);
  const [coinBalanceList, setCoinBalanceList] = useState([]);
  const [vaultTxns, setVaultTxns] = useState([]);
  const [coinSelected, setCoinSelected] = useState({});
  const [coinAddress, setCoinAddress] = useState({});
  const [loading, setLoading] = useState(true);
  const [mobenterverse, setmobenterverse] = useState(false);
  async function updateBalance() {
    setLoading(true);
    const resOne = await Axios.post(
      "https://comms.globalxchange.io/coin/vault/service/coins/get",
      {
        app_code: "ice",
        profile_id: profileId,
      }
    );
    const dataOne = resOne.data;
    setCoinBalanceList(dataOne.coins_data);
    const btcArray = dataOne.coins_data.filter(
      (coin) => coin.coinSymbol === "BTC"
    );
    if (!coinSelected) {
      setCoinSelected(btcArray[0]);
    }
    const resTwo = await Axios.post(
      "https://comms.globalxchange.io/coin/vault/service/balances/get",
      {
        app_code: "ice",
        profile_id: profileId,
      }
    );
    const dataTWO = resTwo.data;
    if (dataTWO.status) {
      setCoinAddress(dataTWO.vault.coinAddress);
    }
    const resThree = await Axios.post(
      "https://comms.globalxchange.io/coin/vault/service/txns/get",
      {
        app_code: "ice",
        profile_id: profileId,
      }
    );
    const dataThree = resThree.data;
    if (dataThree.status) setVaultTxns(dataThree.txns);
    setLoading(false);
  }
  useEffect(() => {
    if (profileId) {
      updateBalance();
    }
    // eslint-disable-next-line
  }, [profileId]);

  // For Vault Filter Conrolls

  const [menuTwo, setMenuTwo] = useState({
    key: "all",
    value: "All Directions",
  });

  const [dateSelected, setDateSelected] = useState(null);

  const [showNativeValue, setShowNativeValue] = useState(true);
  const [landingpageData, setLandingpageData] = useState([]);
  const [isHamMenuOpen, setIsHamMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Home");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    axios
      .get(
        "https://comms.globalxchange.io/coin/vault/service/commissions/total/values/get"
      )
      .then((response) => {
        let result = response?.data;
        setLandingpageData(result);
      });
  }, []);

  return (
    <VaultContext.Provider
      value={{
        coinBalanceList,
        coinSelected,
        setCoinSelected,
        vaultTxns,
        updateBalance,
        coinAddress,
        loading,
        menuTwo,
        setMenuTwo,
        dateSelected,
        setDateSelected,
        showNativeValue,
        setShowNativeValue,
        isHamMenuOpen,
        setIsHamMenuOpen,
        selectedMenu,
        setSelectedMenu,
        landingpageData,
        selected,
        setSelected,
        mobenterverse, setmobenterverse,
      }}
    >
      {children}
    </VaultContext.Provider>
  );
}

export default VaultContextProvider;
